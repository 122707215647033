import React, {
    memo
} from 'react';
import {
    Typography
} from '@mui/material';
import {
    makeStyles
} from '@mui/styles';

const useStyles = makeStyles(
    {
        root: {
            width: '100%',
        },
    }
);

// const areEqual = (
//     prevProps,
//     nextProps
// ) => true;

const Banner = memo(
    (
        props
    ) => {
        const classes = useStyles();

        return (
            <Typography
                className={classes.root}
                {...props}
            />
        );
    });

export default Banner;