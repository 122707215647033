import React from 'react'
import {
	Grid,
	Typography,
} from '@mui/material';
import {
	makeStyles
} from '@mui/styles';
import PropTypes from 'prop-types';
import {
	translate
} from '../../../Api';

const useStyles = makeStyles(
	theme => (
		{
			paper: {
				justifyContent: 'center',
				height: 'auto',
				padding: theme.spacing(1),
				width: 'auto',
				textAlign: 'center',
				margin: '10px auto',
				color: theme.palette.primary.dark,
			},
		}
	)
);

const Timer = ({
	duration,
	title,
}) => {
	const classes = useStyles();
	var days = Math.floor(duration / (1000 * 60 * 60 * 24));
	var hours = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	var minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
	var seconds = Math.floor((duration % (1000 * 60)) / 1000);

	return (
		<Grid
			container
			justifyContent='center'
			alignItems='center'
			className={classes.paper}
		>
			<Grid
				item
				xs={12}
			>
				<Typography
					align="center"
					color="inherit"
					variant="h5"
				>
					{title}
				</Typography>
				<Grid
					container
					direction="row"
					justifyContent="center"
					alignItems="center"
				>
					<Grid
						item
						xs={3}
						md={2}
					>
						<Typography
							align="center"
							color="inherit"
							variant="h6"
						>
							{translate(days.toString().padStart(2, '0'))}
						</Typography>
						<Typography
							align="center"
							color="inherit"
							variant="subtitle2"
						>
							দিন
						</Typography>
					</Grid>
					<Grid
						item
						xs={3}
						md={2}
					>
						<Typography
							align="center"
							color="inherit"
							variant="h6"
						>
							{translate(hours.toString().padStart(2, '0'))}
						</Typography>
						<Typography
							align="center"
							color="inherit"
							variant="subtitle2"
						>
							ঘন্টা
						</Typography>
					</Grid>
					<Grid
						item
						xs={3}
						md={2}
					>
						<Typography
							align="center"
							color="inherit"
							variant="h6"
						>
							{translate(minutes.toString().padStart(2, '0'))}
						</Typography>
						<Typography
							align="center"
							color="inherit"
							variant="subtitle2"
						>
							মিনিট
						</Typography>
					</Grid>
					<Grid
						item
						xs={3}
						md={2}
					>
						<Typography
							align="center"
							color="inherit"
							variant="h6"
						>
							{translate(seconds.toString().padStart(2, '0'))}
						</Typography>
						<Typography
							align="center"
							color="inherit"
							variant="subtitle2"
						>
							সেকেন্ড
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid >
	);
};

Timer.propTypes = {
	title: PropTypes.string.isRequired,
};

export default Timer;