import React, {
    memo
} from 'react';
import {
    Grid,
    useTheme
} from '@mui/material';
import {
    makeStyles
} from '@mui/styles';
import PropTypes from 'prop-types';
import Banner from './Banner';

const useStyles = makeStyles(
    theme => (
        {
            colors: {
                color: theme.palette.primary.dark,
                fontWeight: 900
            },
            playerofthematch: {
                width: 'auto',
                height: 'auto',
                color: theme.palette.secondary.dark,
                paddingRight: theme.spacing(1),
                margin: 0,
            },
        }
    )
);

const ManOfTheMatch = memo(
    (
        {
            name,
            country,
            matchType,
            day,
            session,
            requiredrunrate,
            runrate,
        }
    ) => {
        const classes = useStyles();
        const theme = useTheme();

        return (
            <>
                {
                    (
                        name
                        &&
                        <Grid
                            item
                            className={classes.playerofthematch}
                        >
                            <Banner
                                variant="body2"
                                color='inherit'
                                children="ম্যাচ সেরা খেলোয়াড়"
                            />

                            <Banner
                                variant="subtitle2"
                                className={classes.colors}
                                children={name}
                                sx={{
                                    color: theme.palette.primary.dark,
                                    fontWeight: 900
                                }}
                            />

                            <Banner
                                variant="subtitle2"
                                color='inherit'
                                children={country}
                            />
                        </Grid>
                    )
                    ||
                    <Grid
                        item
                        className={classes.playerofthematch}
                    >
                        {
                            // (
                            //     matchType === "TEST"
                            //     &&
                            //     <Banner
                            //         variant="subtitle2"
                            //         color='inherit'
                            //         children={
                            //             // (
                            //             //     (
                            //             //         (
                            //             //             day === '-১' || day === '০' || day === '-1'
                            //             //         )
                            //             //         &&
                            //             //         (
                            //             //             session === '-১' || session === '০' || session === '-1'
                            //             //         )
                            //             //     )
                            //             //     &&
                            //             //     `দিন ${day} সেশন ${session}`)
                            //             // || commenting for now
                            //             (
                            //                 (
                            //                     runrate !== '০'
                            //                     &&
                            //                     `বর্তমান রান রেট ${runrate}`
                            //                 )
                            //                 ||
                            //                 ''
                            //             )
                            //         }
                            //     />
                            // )
                            // ||
                            (
                                <>
                                    <Banner
                                        variant="subtitle2"
                                        color='inherit'
                                        children={
                                            (
                                                requiredrunrate !== '০'
                                                &&
                                                `কাঙ্খিত রান রেট ${requiredrunrate}`
                                            )
                                            ||
                                            ''
                                        }
                                    />
                                    <Banner
                                        variant="subtitle2"
                                        color='inherit'
                                        children={
                                            (
                                                runrate !== '০'
                                                &&
                                                `বর্তমান রান রেট ${runrate}`
                                            )
                                            ||
                                            ''
                                        }
                                    />
                                </>
                            )
                        }
                    </Grid>
                }
            </>
        );
    }
);

ManOfTheMatch.propTypes = {
    name: PropTypes.string.isRequired,
};

export default ManOfTheMatch;