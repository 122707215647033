import {
    getToken
} from '../reducers';
import {
    endPoints
} from '../../Api';
import {actions} from "../action-types/notice";

export const fetchNotices = (skip, limit) => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_FETCH_NOTICES,
            actions.SUCCESS_FETCH_NOTICES,
            actions.FAILURE_FETCH_NOTICES
        ],
        method: endPoints.FETCH_NOTICES,
        race: () => false,
        data: {
            skip,
            limit,
            // token: getToken(getState())
        }
    }
});

export const fetchNoticeById = id => ({
    RSAA: {
        types: [
            actions.REQUEST_FETCH_NOTICE_BY_ID,
            actions.SUCCESS_FETCH_NOTICE_BY_ID,
            actions.FAILURE_FETCH_NOTICE_BY_ID,
        ],
        method: endPoints.FETCH_NOTICE,
        race: () => false,
        data: {
            id,
        },
    },
});

export const createNotice = state => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_CREATE_NOTICE,
            actions.SUCCESS_CREATE_NOTICE,
            actions.FAILURE_CREATE_NOTICE
        ],
        method: endPoints.CREATE_NOTICE,
        race: () => false,
        data: {
            ...state,
            token: getToken(getState()),
            sequence: parseInt(state.sequence)
        }
    }
});

export const updateNotice = state => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_UPDATE_NOTICE,
            actions.SUCCESS_UPDATE_NOTICE,
            actions.FAILURE_UPDATE_NOTICE
        ],
        method: endPoints.UPDATE_NOTICE,
        race: () => false,
        data: {
            ...state,
            token: getToken(getState()),
            id: state.id,
            sequence: parseInt(state.sequence)
        }
    }
});

export const deleteNotice = id => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_DELETE_NOTICE,
            actions.SUCCESS_DELETE_NOTICE,
            actions.FAILURE_DELETE_NOTICE
        ],
        method: endPoints.DELETE_NOTICE,
        race: () => false,
        data: {
            id,
            token: getToken(getState()),
        }
    }
});