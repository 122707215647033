import React from 'react';
import {
    useLocation
} from 'react-router-dom';
// import MetaTags from 'react-meta-tags';
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Typography,
    useTheme
} from '@mui/material';
import {
    makeStyles
} from '@mui/styles';
import PropTypes from 'prop-types';
import {
    Button,
    Chip,
    DateAndTimeExtract,
    Link,
    LiveScoreInfo,
    Loading,
    RichText,
    SocialMediaShare
} from '.';

import {
    dfs
} from '../Api';
import {
    MonthByNumber as months
} from '../assets/data';

const useStyles = makeStyles(
    theme => (
        {
            card: {
                maxWidth: '100%',
                height: 'auto',
                backgroundColor: 'white',
                borderRadius: '8px',
                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                margin: '8% 20% 5% 20%',
                [theme.breakpoints.down('md')]: {
                    margin: theme.spacing(1)
                },
                '& .RichEditor-root': {
                    padding: 0,
                }
            },
            controls: {
                width: '100%',
                height: '60vh',
                margin: '20px auto',
                [theme.breakpoints.down('sm')]: {
                    height: '60vh',
                },
            },
            divider: {
                width: '70%',
                margin: '0 auto'
            },
            media: {
                height: '100%',
                width: '100%',
                margin: '0 auto'
                //padding: '56.25%', // 16:9
            },
            link: {
                textDecoration: 'none',
                color: theme.palette.primary.dark
            },
            livescore: {
                width: '70%',
                margin: '20px auto 30px',
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                },
                backgroundColor: 'white',
                borderRadius: '8px',
                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
            },
            root: {
                flexGrow: 1,
                color: '#1b5e20',
                margin: '100px auto',
                [theme.breakpoints.down('sm')]: {
                    margin: '80px auto',
                },
            },
            tags: {
                color: theme.palette.primary.dark,
                padding: '7px 0px',
                fontWeight: 600
            },
            loading: {
                height: '100vh',
                marginTop: 120
            }
        }
    )
);

//the only break used here is after photocaption and photocredit. don't remove it.

const Individual = ({
    admin,
    buttonText,
    data,
    id,
    isFetching,
    pathname,
    topic,
}) => {
    const classes = useStyles();
    const location = useLocation();
    const theme = useTheme();

    if (
        isFetching
        ||
        !data
    ) {
        return <Loading
            className={classes.loading}
        />;
    }

    if (
        typeof data === 'undefined'
        ||
        data === ''
        ||
        Object.keys(data) === []
    ) return "";

    const richText = data.content || data.story || data.summary;
    const richTextIntro = data.intro;
    const img = (data.images && data.images[0]) || data.image;
    const hashTags = (data.tags && data.tags) || [];
    const hashPerformers = (data.performers && data.performers) || [];
    const hashes = hashTags.concat(
        hashPerformers
    ) || [];

    let y = '', m = '', d = '';
    if (typeof data.date === 'string') {
        let [days, month, year] = data.date.split("-");
        d = dfs(days);
        m = months[month];
        y = dfs(year);
    }

    return (
        <div
            className={classes.root}
        >
            {/* <MetaTags>
                <title>{data.title}</title>
                <meta name="description" content="Some description." />
                <meta property="og:title" content={data.title} />
                <meta property="og:image" content={img} />
            </MetaTags> */}
            <Card
                className={classes.card}
                sx={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                    '& .MuiCardHeader-root': {
                        padding: theme.spacing(2, 2, 0, 2)
                    }
                }}
            >
                {
                    (
                        data.title
                        ||
                        data.date
                    )
                    &&
                    <CardHeader
                        title={
                            (
                                data.date && `${d} ${m}, ${y}`
                            )
                            ||
                            data.title
                        }
                    // subheader={data.published_date}
                    />
                }
                {
                    topic === 'blogs'
                    &&
                    <DateAndTimeExtract
                        // variant="body2"
                        color='textSecondary'
                        time={data.created_at}
                        sx={{
                            padding: theme.spacing(0, 0, 1, 2)
                        }}
                    />
                }
                <SocialMediaShare
                    pathname={`https://khelahobe.co${location.pathname}`}
                    title={data.title}
                    hashes={hashes}
                    style={{
                        padding: theme.spacing(0, 0, 2, 2)
                    }}
                />
                {
                    img
                    &&
                    <div
                        align='center'
                        className={classes.media}
                    >
                        <img
                            src={img}
                            alt={data.title}
                            className={classes.media}
                            style={{
                                height: '60%',
                                width: '60%',
                                margin: '0 auto',
                            }}
                        />
                    </div>
                }
                {
                    data.youtube_id
                    &&
                    <div
                        className={classes.controls}
                    >
                        <iframe
                            className={classes.media}
                            title={data.title}
                            src={`https://www.youtube.com/embed/${data.youtube_id}`}
                        >
                        </iframe>
                    </div>
                }
                <CardContent
                    sx={{
                        padding: theme.spacing(3),
                        [theme.breakpoints.down('sm')]: {
                            padding: theme.spacing(2),
                        },
                    }}
                >
                    {
                        data.performers
                        &&
                        data.performers[0] !== ''
                        &&
                        <Typography
                            variant="subtitle1"
                            className={classes.tags}
                        >
                            কী পারফর্মার:<Chip
                                data={data.performers}
                            />
                        </Typography>
                    }
                    {
                        data.tags
                        &&
                        data.tags[0] !== ''
                        &&
                        <Typography
                            variant="subtitle1"
                            className={classes.tags}
                        // noWrap
                        >
                            ট্যাগস:  <Chip
                                data={data.tags}
                                flag={topic === 'highlights' && true}
                            />
                        </Typography>
                    }
                    {
                        data.players
                        &&
                        <Typography
                            variant="subtitle1"
                            className={classes.tags}
                        // noWrap
                        >
                            <Chip
                                data={data.players}
                            />
                        </Typography>
                    }
                    {
                        data.photo_caption
                        &&
                        <Typography
                            variant="body2"
                            align="center"
                            color="inherit"
                            gutterBottom
                        >
                            {data.photo_caption}
                        </Typography>
                    }
                    {
                        data.teamone
                        &&
                        <Typography
                            variant="body2"
                            align="center"
                            color="inherit"
                        >
                            {data.teamone}
                        </Typography>
                    }
                    {
                        data.notice
                        &&
                        <Typography
                            variant="body2"
                            align="center"
                            color="inherit"
                        >
                            {data.notice}
                        </Typography>
                    }
                    {
                        data.teamtwo
                        &&
                        <Typography
                            variant="body2"
                            align="center"
                            color="inherit"
                        >
                            {data.teamtwo}
                        </Typography>
                    }
                    {
                        data.type
                        &&
                        <Typography
                            variant="body2"
                            align="center"
                            color="inherit"
                        >
                            ধরণ: {data.type}
                        </Typography>
                    }
                    {
                        data.bangla
                        &&
                        <Typography
                            variant="body2"
                            align="center"
                            color="inherit"
                        >
                            বাংলা: {data.bangla}
                        </Typography>
                    }
                    {
                        data.english
                        &&
                        <Typography
                            variant="body2"
                            align="center"
                            color="inherit"
                        >
                            ইংরেজি: {data.english}
                        </Typography>
                    }
                    {
                        (
                            data.photo_credit
                            ||
                            data.photocredit
                        )
                        &&
                        <Typography
                            variant="caption"
                            color="inherit"
                            sx={{
                                float: 'right',
                            }}
                            gutterBottom
                            title={data.credit_details && data.credit_details}
                        >
                            ছবি স্বত্ব: {data.photo_credit || data.photocredit}
                        </Typography>
                    }
                    <br />
                    {
                        data.description
                        &&
                        <Typography
                            variant="subtitle1"
                            color="textSecondary"
                        >
                            {data.description}
                        </Typography>
                    }
                    {
                        data.summary_text
                        &&
                        <Typography
                            variant="body2"
                            color="inherit"
                            gutterBottom
                        >
                            সার সংক্ষেপ: {data.summary_text}
                        </Typography>
                    }
                    {
                        data.original_text
                        &&
                        <Typography
                            variant="body2"
                            color="inherit"
                            gutterBottom
                        >
                            অরিজিনাল বক্তব্য: {data.original_text}
                        </Typography>

                    }
                    {
                        data.importance
                        &&
                        <Typography
                            variant="body2"
                            color="inherit"
                            gutterBottom
                        >
                            গুরুত্ব: {data.importance}
                        </Typography>

                    }
                    {
                        (
                            data.isReviewed === true
                            ||
                            data.isReviewed === false
                        )
                        &&
                        <Typography
                            variant="body2"
                            color="inherit"
                            gutterBottom
                        >
                            রিভিউড: {data.reviewed ? 'হ্যাঁ' : 'না'}
                        </Typography>
                    }
                    {
                        (
                            data.lastreviewed === true
                            ||
                            data.lastreviewed === false
                        )
                        &&
                        <Typography
                            variant="body2"
                            color="inherit"
                            gutterBottom
                        >
                            শেষ রিভিউড: {data.lastreviewed}
                        </Typography>
                    }
                    {

                        Array.isArray(data.related_news)
                        &&
                        data.related_news.map(
                            (
                                i, index
                            ) => (
                                <>
                                    <Typography
                                        variant="body2"
                                        color="inherit"
                                        gutterBottom
                                    >
                                        সম্পর্কিত সংবাদসমূহ:
                                    </Typography>
                                    <a
                                        href={[i]}
                                        key={index}
                                        className={classes.linked}
                                    >
                                        {[i]}
                                    </a>
                                </>
                            )
                        )
                    }
                    {
                        richTextIntro
                        &&
                        <>
                            <RichText
                                value={JSON.parse(richTextIntro)}
                                readOnly
                            />
                        </>
                    }
                    {
                        (
                            data.match_id
                            ||
                            data.scoreboard_url
                        )
                        &&
                        <div
                            align='center'
                            className={classes.livescore}
                        >
                            <LiveScoreInfo
                                slug={data.match_id || data.scoreboard_url}
                                flag={1}
                            />
                            <Button
                                to={`/scoreboard/${data.match_id}/summary` || `/scoreboard/${data.scoreboard_url}/summary`}
                                component={Link}
                                text='ম্যাচ বিস্তারিত'
                                variant='contained'
                                sx={{
                                    marginBottom: theme.spacing(2),
                                    // color: theme.palette.primary.dark,
                                    textAlign: 'center',
                                    // fontWeight: 600,
                                    backgroundColor: theme.palette.primary.dark,
                                    color: theme.palette.primary.light,
                                    boxShadow: '#33691e 0px 1px 4px',

                                }}
                            />
                        </div>
                    }
                    {
                        richText
                        &&
                        <RichText
                            value={JSON.parse(richText)}
                            readOnly
                        />
                    }
                    {
                        data.writer
                        &&
                        <Typography
                            variant="caption"
                            color="inherit"
                            style={{
                                float: 'right'
                            }}
                        >
                            লেখা স্বত্ব: {data.writer}
                        </Typography>
                    }
                    {
                        data.source && !data.url
                        &&
                        <Typography
                            variant="caption"
                            color="inherit"
                            style={{
                                float: 'right'
                            }}
                        >
                            প্রকাশক ও স্বত্ব: {data.source}
                        </Typography>
                    }
                    {
                        (
                            (
                                data.source_url
                                &&
                                data.source_name
                            )
                            ||
                            (
                                data.url
                                &&
                                data.source
                            )
                        )
                        &&
                        <div
                            align='right'
                        >
                            <a
                                href={data.source_url || data.url}
                                className={classes.link}
                            >
                                সোর্স: {data.source_name || data.source}
                            </a>
                        </div>
                    }
                </CardContent>
                {/* <Divider
                    className={classes.divider}
                /> */}
                <CardActions>
                    {
                        admin
                        &&
                        <Button
                            component={Link}
                            to={{
                                pathname: pathname,
                                state: id,
                            }}
                            sx={{
                                marginBottom: theme.spacing(2),
                                textAlign: 'center',
                                backgroundColor: theme.palette.primary.dark,
                                color: theme.palette.primary.light,
                                boxShadow: '#33691e 0px 1px 4px',
                                '&:hover': {
                                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                                    color: theme.palette.primary.main,
                                    fontWeight: 900,
                                    backgroundColor: theme.palette.primary.dark,
                                }
                            }}
                            text={buttonText}
                        />
                    }
                </CardActions>
            </Card>
        </div >
    );
}

Individual.propTypes = {
    data: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    pathname: PropTypes.string.isRequired,
};

export default Individual;