import React, {
	useState,
	useEffect
} from 'react';
import Timer from './Timer';

const Counter = ({
	start_time
}) => {
	const [
		state,
		setState
	] = useState({
		currentDate: Date.now(),
		nextDate: new Date(
			start_time.toString()
		),
	});

	const updateTime = () => {
		let nextDate = null,
			currentDate = Date.now(),
			date = new Date(
				start_time.toString(),
			);

		if (currentDate < date) {
			if (!nextDate || nextDate > date) {
				nextDate = date;
			}
		}

		return setState({
			currentDate,
			nextDate,
		});
	};

	useEffect(
		() => {
			updateTime();

			const interval = setInterval(
				() => updateTime(), 1000
			);
			return () => clearInterval(interval);
		},
		[

		]
	);

	const getRemainingTime = () => {
		let diff = state.nextDate - state.currentDate;
		return diff;
	};

	const duration = getRemainingTime();

	if (duration < 0) return <></>;

	return (
		<section>
			<Timer
				duration={duration}
			/>
		</section>
	);
};

export default Counter;