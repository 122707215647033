import React from 'react';
import {
    Link,
} from 'react-router-dom';
import {
    Button,
    IconButton,
    useTheme,
} from '@mui/material';
import {
    makeStyles
} from '@mui/styles';
import {
    KeyboardArrowLeft,
    KeyboardArrowRight,
    FirstPage,
    LastPage
} from '@mui/icons-material';
import PropTypes from 'prop-types';

const useStyles1 = makeStyles(
    theme => (
        {
            root: {
                flexShrink: 0,
                color: theme.palette.primary.dark,
                marginLeft: theme.spacing(1),
            },
        }
    )
);

const TablePaginationActions = ({
    count,
    page,
    rowsPerPage,
    link,
    onPageChange,
    slug,
    tag
}) => {
    const classes = useStyles1();
    const theme = useTheme();

    const handleFirstPageButtonClick = event => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = event => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onPageChange(
            event,
            parseInt(Math.max(0, Math.ceil(count / rowsPerPage) - 1)),
        );
    };

    let next = parseInt(page + 1);
    let last = parseInt(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    let prev = parseInt(page - 1);

    return (
        <div className={classes.root}>
            <IconButton
                component={link && Link}
                to={
                    slug === 'highlights-tags'
                        ?
                        (link && `${link}/0`)
                        :
                        (link && `${link}/0/${tag}`)
                }
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="First Page"
                sx={{
                    color: theme.palette.primary.dark
                }}
            >
                {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
            </IconButton>
            <Button
                component={link && Link}
                to={
                    slug === 'highlights-tags'
                        ?
                        (link && `${link}/${prev}`)
                        :
                        (link && `${link}/${prev}/${tag}`)
                }
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="Previous Page"
                sx={{
                    color: theme.palette.primary.dark
                }}
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}আগে
            </Button>
            . . .
            <Button
                onClick={handleNextButtonClick}
                component={link && Link}
                to={
                    slug === 'highlights-tags'
                        ?
                        (link && `${link}/${next}`)
                        :
                        (link && `${link}/${next}/${tag}`)
                }
                disabled={page === last}
                aria-label="Next Page"
                sx={{
                    color: theme.palette.primary.dark
                }}
            >
                পরে {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
            <IconButton
                onClick={handleLastPageButtonClick}
                component={link && Link}
                to={
                    slug === 'highlights-tags'
                        ?
                        (link && `${link}/${last}`)
                        :
                        (link && `${link}/${last}/${tag}`)
                }
                disabled={page === last}
                aria-label="Last Page"
                sx={{
                    color: theme.palette.primary.dark
                }}
            >
                {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
            </IconButton>
        </div>
    );
};

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

export default TablePaginationActions;