import React, {
    useEffect
} from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    useLocation
} from "react-router-dom";
import {
    Grid,
} from '@mui/material';
import {
    makeStyles,
} from '@mui/styles';
import AlsoSee from './AlsoSee';
import {
    Divider
} from '../../commonComponent';
import {
    getBlogs,
    getHighlights,
    getNews
} from '../../redux/reducers';
import {
    fetchHighlights
} from '../../redux/actions/Highlights';
import {
    fetchBlogs
} from '../../redux/actions/Blogs';
import {
    fetchNews
} from '../../redux/actions/News';
import {
    alsoSee
} from '../../assets/data';

const useStyles = makeStyles(
    theme => (
        {
            root: {
                width: '100%',
            },
            divider: {
                width: '80%',
                margin: '20px auto'
            }
        }
    )
);

const AlsoSeeSection = () => {
    const classes = useStyles();
    let location = useLocation().pathname;
    let l = location.split(/[//]/)[1];
    const dispatch = useDispatch();

    useEffect(
        () => {
            if (l !== 'blogs') {
                dispatch(
                    fetchBlogs(0, 10)
                );
            }
        },
        [
            dispatch,
            l
        ]
    );

    useEffect(
        () => {
            if (l !== 'highlights') {
                dispatch(
                    fetchHighlights(0, 10)
                );
            }
        },
        [
            dispatch,
            l
        ]
    );

    useEffect(
        () => {
            if (l !== 'news') {
                dispatch(
                    fetchNews(0, 10)
                );
            }
        },
        [
            dispatch,
            l
        ]
    );

    const blogs = useSelector(
        state => getBlogs(state)
    )
        ||
        [];
    const highlights = useSelector(
        state => getHighlights(state)
    )
        ||
        [];
    const news = useSelector(
        state => getNews(state)
    )
        ||
        [];

    alsoSee[0].obj = blogs;
    alsoSee[1].obj = highlights;
    alsoSee[2].obj = news;

    return (
        <Grid
            container
            className={classes.root}
        >
            <Divider
                className={classes.divider}
            />
            {
                alsoSee.map(
                    (
                        i, index
                    ) => (
                        <Grid
                            item
                            key={index}
                            xs={12}
                        >
                            <AlsoSee
                                title={i.title}
                                obj={i.obj}
                                link={i.link}
                                flag={i.flag}
                            />
                            <Divider
                                sx={{
                                    width: '80%',
                                    margin: '20px auto'
                                }}
                            />
                        </Grid>
                    )
                )
            }
        </Grid>
    );
};

export default AlsoSeeSection; 