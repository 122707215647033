export const actions = {
    REQUEST_FETCH_NOTICES: 'REQUEST_FETCH_NOTICES',
    SUCCESS_FETCH_NOTICES: 'SUCCESS_FETCH_NOTICES',
    FAILURE_FETCH_NOTICES: 'FAILURE_FETCH_NOTICES',

    REQUEST_FETCH_NOTICE_BY_ID: 'REQUEST_FETCH_NOTICE_BY_ID',
    SUCCESS_FETCH_NOTICE_BY_ID: 'SUCCESS_FETCH_NOTICE_BY_ID',
    FAILURE_FETCH_NOTICE_BY_ID: 'FAILURE_FETCH_NOTICE_BY_ID',

    REQUEST_CREATE_NOTICE: 'REQUEST_CREATE_NOTICE',
    SUCCESS_CREATE_NOTICE: 'SUCCESS_CREATE_NOTICE',
    FAILURE_CREATE_NOTICE: 'FAILURE_CREATE_NOTICE',

    REQUEST_UPDATE_NOTICE: 'REQUEST_UPDATE_NOTICE',
    SUCCESS_UPDATE_NOTICE: 'SUCCESS_UPDATE_NOTICE',
    FAILURE_UPDATE_NOTICE: 'FAILURE_UPDATE_NOTICE',

    REQUEST_DELETE_NOTICE: 'REQUEST_DELETE_NOTICE',
    SUCCESS_DELETE_NOTICE: 'SUCCESS_DELETE_NOTICE',
    FAILURE_DELETE_NOTICE: 'FAILURE_DELETE_NOTICE',
}