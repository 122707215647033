import React from 'react';
import {
    Grid,
    Typography,
} from '@material-ui/core';
import {
    makeStyles,
} from '@mui/styles';
import Layout from '../layout';
import {
    BannerForPage,
    Logo
} from '../../commonComponent';

const useStyles = makeStyles(
    theme => (
        {
            root: {
                margin: '90px auto',
                color: theme.palette.secondary.main,
                textAlign: 'center',
                [theme.breakpoints.down('sm')]: {
                    margin: '70px auto',
                },
            },
            img: {
                width: '90%',
                height: '90%',
                margin: '0 auto',
                padding: theme.spacing(1)
            },
            credit: {
                // marginRight: '5%',
                color: theme.palette.secondary.main,
            }
        }
    )
);

const NotFound = () => {
    const classes = useStyles();

    return (
        <Layout>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.root}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Logo />
                    <BannerForPage
                        // title='Sign In'
                        color='inherit'
                        variant='h5'
                        banner='দুঃখিত! আপনার কাঙ্ক্ষিত পেজটি পাওয়া যায়নি'
                    />
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/6/66/Cricketer_bowled.jpg"
                        alt="404notfound"
                        className={classes.img}
                    />
                    <div
                        align='center'
                    >
                        <Typography
                            align="right"
                            variant="caption"
                            className={classes.credit}
                        >
                            ছবি স্বত্ব: Lemonlolly
                        </Typography>
                    </div>

                </Grid>
            </Grid>
        </Layout>
    );
};

export default NotFound;