import React, {
	Suspense
} from 'react';
import {
	useSelector
} from 'react-redux';
import {
	Redirect,
	Route,
	Switch,
} from 'react-router-dom';

import {
	routes
} from '../../assets/data';
import Layout from '../layout';
import {
	Loading
} from '../../commonComponent';
import NotFound from '../notFound/index';
import {
	getAuth,
} from '../../redux/reducers';

const WrapperRoute = ({
	component: Component,
	authLevel,
	max,
	...rest
}) => {
	let login = (
		useSelector(
			state => getAuth(state)
		).login
		&& 1
	)
		||
		0;
	const admin = (
		useSelector(
			state => getAuth(state)
		).admin
		&&
		1
	)
		||
		0;
	login = login + admin;

	return (
		<Route
			{...rest}
			render={
				props => (
					(
						login >= authLevel
						&&
						login <= max
					)
						?
						(
							(
								rest.Component !== ''
								&&
								!rest.replace
							)
								?
								<Layout>
									<Component
										{...props}
									/>
								</Layout>
								:
								<Redirect
									path={rest.path}
									to={rest.to(props.match.params)}
								/>
						)
						:
						<Redirect
							to={{
								path: "/"
							}}
						/>
				)
			}
		/>
	);
};

const Routes = () => (
	<Suspense
		fallback={
			<Loading
				style={{
					height: '100vh'
				}}
			/>
		}
	>
		<Switch>
			{
				routes.map(
					(
						r, i
					) => (
						<WrapperRoute
							key={i}
							{...r}
						/>
					)
				)
			}
			<Route
				component={NotFound}
			/>
		</Switch>
	</Suspense>
);

export default Routes;