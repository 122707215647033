export const actions = { // it is maintained to use `s` for plural
    REQUEST_FETCH_HIGHLIGHTS: 'REQUEST_FETCH_HIGHLIGHTS',
    SUCCESS_FETCH_HIGHLIGHTS: 'SUCCESS_FETCH_HIGHLIGHTS',
    FAILURE_FETCH_HIGHLIGHTS: 'FAILURE_FETCH_HIGHLIGHTS',

    REQUEST_CREATE_HIGHTLIGHT: 'REQUEST_CREATE_HIGHTLIGHT',
    SUCCESS_CREATE_HIGHTLIGHT: 'SUCCESS_CREATE_HIGHTLIGHT',
    FAILURE_CREATE_HIGHTLIGHT: 'FAILURE_CREATE_HIGHTLIGHT',

    REQUEST_UPDATE_HIGHTLIGHT: 'REQUEST_UPDATE_HIGHTLIGHT',
    SUCCESS_UPDATE_HIGHTLIGHT: 'SUCCESS_UPDATE_HIGHTLIGHT',
    FAILURE_UPDATE_HIGHTLIGHT: 'FAILURE_UPDATE_HIGHTLIGHT',

    REQUEST_DELETE_HIGHTLIGHT: 'REQUEST_DELETE_HIGHTLIGHT',
    SUCCESS_DELETE_HIGHTLIGHT: 'SUCCESS_DELETE_HIGHTLIGHT',
    FAILURE_DELETE_HIGHTLIGHT: 'FAILURE_DELETE_HIGHTLIGHT',

    REQUEST_COUNT_HIGHLIGHTS: 'REQUEST_COUNT_HIGHLIGHTS',
    SUCCESS_COUNT_HIGHLIGHTS: 'SUCCESS_COUNT_HIGHLIGHTS',
    FAILURE_COUNT_HIGHLIGHTS: 'FAILURE_COUNT_HIGHLIGHTS',

    REQUEST_FETCH_HIGHLIGHT_BY_ID: 'REQUEST_FETCH_HIGHLIGHT_BY_ID',
    SUCCESS_FETCH_HIGHLIGHT_BY_ID: 'SUCCESS_FETCH_HIGHLIGHT_BY_ID',
    FAILURE_FETCH_HIGHLIGHT_BY_ID: 'FAILURE_FETCH_HIGHLIGHT_BY_ID',

    REQUEST_FETCH_HIGHLIGHT_BY_SLUG: 'REQUEST_FETCH_HIGHLIGHT_BY_SLUG',
    SUCCESS_FETCH_HIGHLIGHT_BY_SLUG: 'SUCCESS_FETCH_HIGHLIGHT_BY_SLUG',
    FAILURE_FETCH_HIGHLIGHT_BY_SLUG: 'FAILURE_FETCH_HIGHLIGHT_BY_SLUG',

    REQUEST_FETCH_HIGHLIGHTS_BY_TAGS: 'REQUEST_FETCH_HIGHLIGHTS_BY_TAGS',
    SUCCESS_FETCH_HIGHLIGHTS_BY_TAGS: 'SUCCESS_FETCH_HIGHLIGHTS_BY_TAGS',
    FAILURE_FETCH_HIGHLIGHTS_BY_TAGS: 'FAILURE_FETCH_HIGHLIGHTS_BY_TAGS',
}