import React, {
	useEffect,
	useMemo,
	memo
} from 'react';
import {
	useDispatch,
	useSelector
} from 'react-redux';
import {
	useLocation
} from 'react-router-dom';
import clsx from 'clsx';
import {
	Grid,
	Typography,
	useTheme
} from '@mui/material';
import {
	makeStyles
} from '@mui/styles';
import PropTypes from 'prop-types';
import TableForLiveScore from './TableForLiveScore.jsx';
import ManOfTheMatch from './ManOfTheMatch.jsx';
import DateOrMatchStatus from './DateOrMatchStatus.jsx';
import {
	dfs
} from '../../Api';
import {
	Loading
} from '../../commonComponent';
import Infos from './Infos.jsx';
import {
	getInningsIds,
	getMatch,
	getShortScore,
	getTeamsScore,
} from '../../redux/reducers';
import {
	fetchMatchWithInnings,
	fetchInnings,
	fetchMatch
} from '../../redux/actions/Scoreboard';

const useStyles = makeStyles(
	theme => (
		{
			root: {
				width: '100%',
				height: 'auto',
				margin: '0px auto',
				color: theme.palette.secondary.dark,
				textDecoration: 'none',
				backgroundColor: 'white',
				padding: theme.spacing(1),
				borderRadius: '8px'
			},
			result: {
				textAlign: 'left',
				[theme.breakpoints.down('sm')]: {
					textAlign: 'center',
				},
			},
			colors: {
				color: theme.palette.primary.dark,
				fontWeight: 900
			},
		}
	)
);

const isEmpty = obj => !Object.keys(obj).reduce((prev, cur) => prev || obj.hasOwnProperty(cur), false);

const areEqual = (prevProps, nextProps) => prevProps.slug === nextProps.slug;

const LiveScoreInfo = memo(
	(
		{
			slug,
			flag,
			disabled
		}
	) => {
		const classes = useStyles();
		const theme = useTheme();
		const dispatch = useDispatch();
		const location = useLocation();

		const dataWithMemo = dfs(useSelector(
			state => getMatch(
				state,
				slug
			)
		))
			||
			'';
		const data = useMemo(
			() => dataWithMemo,
			[
				dataWithMemo
			]
		);
		const shortScoreCard = dfs(useSelector(
			state => getShortScore(
				state,
				slug
			)
		));
		// const shortScoreCard = useMemo(() => shortScoreCardWithMemo, [shortScoreCardWithMemo]);
		const teamscore = dfs(useSelector(
			state => getTeamsScore(
				state,
				slug
			)
		));
		// const teamscore = useMemo(() => teamscoreWithMemo, [teamscoreWithMemo]);
		const inningsIdsForMemo = useSelector(
			state => getInningsIds(
				state,
				slug
			)
		);
		const inningsIds = useMemo(
			() => inningsIdsForMemo,
			[
				inningsIdsForMemo
			]
		);
		let pathForScorePage = location.pathname.split(/[//]/)[1];

		useEffect(
			() => {
				dispatch(
					fetchMatchWithInnings(slug)
				);

				if (
					flag === 0
					||
					(
						data.matchState
						&&
						data.matchState === 'POST'
					)
					||
					(
						location.pathname === '/live-scoreboard'
					)
					||
					(
						data.matchStatus
						&&
						data.matchStatus === 'স্টাম্পস'
					)
				) return;
				const interval = setInterval(
					() => dispatch(
						fetchMatchWithInnings(slug)
					),
					10000
				);
				return () => clearInterval(interval);
			},
			[
				data.matchState,
				data.matchStatus,
				dispatch,
				location.pathname,
				slug,
				flag
			]
		);

		useEffect(
			() => {
				if (
					flag === 0
					||
					(
						data.matchState
						&&
						data.matchState === 'POST'
					)
					||
					(
						data.matchStatus
						&&
						data.matchStatus === 'স্টাম্পস'
					)
				) return;
				else if (
					location.pathname === '/live-scoreboard'
				) {
					const interval = setInterval(
						() => {
							dispatch(
								fetchMatch(slug)
							);
						},
						60000 * 5
					);

					return () => clearInterval(interval);
				}
			},
			[
				data.matchState,
				data.matchStatus,
				dispatch,
				location.pathname,
				slug,
				flag
			]
		);

		useEffect(
			() => {
				if (
					flag === 0
					||
					(
						data.matchState
						&&
						data.matchState === 'POST'
					)
					||
					(
						data.matchStatus
						&&
						data.matchStatus === 'স্টাম্পস'
					)
					||
					(
						data.matchStatus
						&&
						data.matchStatus === 'সরাসরি কাভারেজ নেই'
					)
					// ||
					// (inningsIds !== [] || inningsIds !== undefined)
				) return;
				else if (
					location.pathname === '/live-scoreboard'
				) {

					const interval = setInterval(
						() => {
							(
								inningsIds !== []
								||
								inningsIds !== undefined
								||
								inningsIds !== null
							)
								&&
								dispatch(
									fetchInnings(
										inningsIds[inningsIds.length - 1]
									)
								)
						},
						30000
					);

					return () => clearInterval(interval);
				}
			},
			[
				data.matchState,
				data.matchStatus,
				dispatch,
				inningsIds,
				location.pathname,
				slug,
				flag
			]
		);

		if (
			!data
			||
			isEmpty(data)
		) {
			return <Loading />;
		}
		let team1 = '',
			team2 = '',
			test1 = 110,
			test2 = 110,
			test3 = 110,
			test4 = 110;

		if (
			data.matchType === 'T20'
			||
			data.matchType === 'ODI'
			||
			data.matchType === 'HUNDRED_BALL'
		) {
			if (
				teamscore[0]
				&&
				shortScoreCard.teams[0].toLowerCase() === teamscore[0].team.toLowerCase()
			) {
				team1 = shortScoreCard.teams[0].toLowerCase();
				team2 = shortScoreCard.teams[1].toLowerCase();
			}
			else {
				team1 = shortScoreCard.teams[1].toLowerCase();
				team2 = shortScoreCard.teams[0].toLowerCase();
			}
		}

		if (
			data.matchType === 'TEST' //only test
		) {
			if (!teamscore) { //when no teamscore is there
				team1 = shortScoreCard.teams[0].toLowerCase();
				team2 = shortScoreCard.teams[1].toLowerCase();
			}
			if (
				teamscore[0] //when only 1 or 2 teamscore is there. if we get only one then we can take the 2nd index team too
				&&
				shortScoreCard.teams[0].toLowerCase() === teamscore[0].team.toLowerCase()
			) {
				team1 = shortScoreCard.teams[0].toLowerCase();
				team2 = shortScoreCard.teams[1].toLowerCase();
				test1 = 0;
				test2 = 1;
			}
			else {
				team1 = shortScoreCard.teams[1].toLowerCase();
				team2 = shortScoreCard.teams[0].toLowerCase();
				test1 = 1;
				test2 = 0;
			}
			if (
				teamscore[2]
				&&
				teamscore.length > 2 //when at least 3 teamscores are there we can always get the 4th one
				&&
				teamscore[0].team.toLowerCase() === teamscore[2].team.toLowerCase()
			) {
				test3 = 2;
				test4 = 3;
			}
			else {
				test3 = 3;
				test4 = 2;
			}
		}

		const l = `${shortScoreCard.remainingball} বলে ${shortScoreCard.remainingrun} রান দরকার`;
		let overs = data.matchType === "ODI" ? "৫০" : (data.matchType === "T20" ? "২০" : "");

		if (!data) return '';
		return (
			<Grid
				container
				// component={(location.pathname === '/live-scoreboard' || location.pathname === '/tournament') ? Link : Grid}
				// to={location.pathname === '/live-scoreboard' ? `/scoreboard/${slug}/summary` : ''}
				disabled={disabled}
				className={classes.root}
				title={`${shortScoreCard.teams[0]} বনাম ${shortScoreCard.teams[1]}`}
			>
				{
					pathForScorePage === 'scoreboard'
					&&
					<Infos
						t1={team1}
						t2={team2}
						start_time={data.start_time}
						stadium={data.stadium}
						days={data.days}
						series={data.series}
					/>
				}
				<DateOrMatchStatus
					matchStatus={data.matchStatus}
					start_time={data.start_time}
					days={data.days}
					series={data.series}
					stadium={data.stadium}
				/>
				<ManOfTheMatch
					name={data.manOfTheMatch.name}
					country={data.manOfTheMatch.country}
					matchType={data.matchType}
					day={shortScoreCard.day}
					session={shortScoreCard.session}
					requiredrunrate={shortScoreCard.requiredrunrate}
					runrate={shortScoreCard.runrate}
				/>
				<Grid
					item
					xs={12}
				>
					{
						(
							data.matchType === "HUNDRED_BALL" ||
							data.matchType === "ODI" ||
							data.matchType === "T20"
						)
						&&
						(
							<TableForLiveScore
								total={(teamscore[0] && teamscore[0].total) || ''}
								out={(teamscore[0] && teamscore[0].out) || ''}
								over={(teamscore[0] && dfs(teamscore[0].over)) || '০'}
								team={team1}
								flag='0'
								winning={data.winning_team}
								overs={overs}
								running={(team1 === shortScoreCard.name && shortScoreCard.running_innings) || false}
							/>
						)
					}
					{
						(
							data.matchType === "HUNDRED_BALL" ||
							data.matchType === "ODI" ||
							data.matchType === "T20"
						)
						&&
						(
							<TableForLiveScore
								total={(teamscore[1] && teamscore[1].total) || ''}
								out={(teamscore[1] && teamscore[1].out) || ''}
								over={(teamscore[1] && dfs(teamscore[1].over)) || '০'}
								team={team2}
								flag='0'
								winning={data.winning_team}
								overs={overs}
								running={(team2 === shortScoreCard.name && shortScoreCard.running_innings) || false}
							/>
						)
					}
					{
						data.matchType === "TEST"
						&&
						(
							<TableForLiveScore
								total1={(teamscore[test1] && teamscore[test1].total) || ''}
								total2={(teamscore[test3] && teamscore[test3].total) || ''}
								out1={(teamscore[test1] && teamscore[test1].out) || ''}
								out2={(teamscore[test3] && teamscore[test3].out) || ''}
								over1={(teamscore[test1] && dfs(teamscore[test1].over)) || '০'}
								over2={(teamscore[test3] && dfs(teamscore[test3].over)) || '০'}
								team={team1}
								flag='1'
								winning={data.winning_team}
								overs={overs}
								running={(team1 === shortScoreCard.name && shortScoreCard.running_innings) || false}
							/>
						)
					}
					{
						data.matchType === "TEST"
						&&
						(
							<TableForLiveScore
								total1={(teamscore[test2] && teamscore[test2].total) || ''}
								total2={(teamscore[test4] && teamscore[test4].total) || ''}
								out1={(teamscore[test2] && teamscore[test2].out) || ''}
								out2={(teamscore[test4] && teamscore[test4].out) || ''}
								over1={(teamscore[test2] && dfs(teamscore[test2].over)) || '০'}
								over2={(teamscore[test4] && dfs(teamscore[test4].over)) || '০'}
								team={team2}
								flag='1'
								winning={data.winning_team}
								overs={overs}
								running={(team2 === shortScoreCard.name && shortScoreCard.running_innings) || false}
							/>
						)
					}
					<br />
					{
						(
							(data.matchType === "TEST" && data.result === '') &&
							(
								(shortScoreCard.first && ' ') ||
								(shortScoreCard.towin && `${shortScoreCard.name} এর জয়ের জন্য ${shortScoreCard.diff} রান দরকার`) ||
								(shortScoreCard.leadby && `${shortScoreCard.name} ${shortScoreCard.diff} রানে এগিয়ে`) ||
								(shortScoreCard.diff !== '০' && `${shortScoreCard.name} ${shortScoreCard.diff} রানে পিছিয়ে`)
							)
						)
						||
						(
							<Typography
								variant="subtitle2"
								color='inherit'
								className={
									clsx(
										classes.result,
										{
											[classes.colors]: data.result !== ''
										}
									)
								}
								sx={{
									color: data.result !== '' && theme.palette.primary.dark,
									fontWeight: data.result !== '' && 900
								}}
							>
								{(data.result ? data.result : ((teamscore.length === 0 && data.scoreboards === '') ? data.toss : l))}
							</Typography>
						)
					}
				</Grid>
			</Grid>
		);
	}, areEqual
);

LiveScoreInfo.propTypes = {
	slug: PropTypes.string.isRequired,
};

export default LiveScoreInfo;