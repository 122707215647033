import React from 'react';
import {
    Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
    makeStyles,
} from '@mui/styles';
import {
    dfs
} from '../Api';
import {
    Months
} from '../assets/data';

const useStyles = makeStyles(
    theme => (
        {
            root: {
                width: '100%',
            },
        }
    )
);

const DateAndTimeForMatchLivescore = ({
    start_time,
    day,
    color,
    // className,
    ...props
}) => {
    const classes = useStyles();
    let today = new Date();
    let [days, month, year] = day[0].split(" ");
    const yyyy = dfs(today.getFullYear());
    let mm = today.getMonth(); // Months start at 0!
    let dd = dfs(today.getDate());

    let todayOrNotToday = (dd === days && yyyy === year && Months[mm] === Months[month]) ? ('আজ ') : '';
    let ffff = new Date(start_time);
    let h = '', test = '', st = '';
    let blabla = day[0].split(" ");

    if (blabla[2]) {
        for (let i in blabla) {
            if (Months[blabla[i]]) {
                test += Months[blabla[i]] + ' ';
            }
            else {
                test += blabla[i] + ' ';
            }
        }
    }
    else {

    }

    if (
        (
            ffff.getHours() >= 0 && ffff.getHours() < 3
        )
        ||
        (
            ffff.getHours() >= 20 && ffff.getHours() <= 24
        )
    ) {
        h = 'রাত';
    }
    else if (ffff.getHours() === 0) {
        h = 'রাত';
    }
    else if (ffff.getHours() < 7 && ffff.getHours() >= 3) {
        h = 'ভোর';
    }
    else if (ffff.getHours() > 7 && ffff.getHours() < 11) {
        h = 'সকাল';
    }
    else if (ffff.getHours() >= 11 && ffff.getHours() <= 12) {
        h = 'বেলা';
    }
    else if (ffff.getHours() >= 12 && ffff.getHours() <= 15) {
        h = 'দুপুর';
    }
    else if (ffff.getHours() > 15 && ffff.getHours() < 18) {
        h = 'বিকাল';
    }
    else if (ffff.getHours() >= 18 && ffff.getHours() <= 20) {
        h = 'সন্ধ্যা';
    }

    h += ' ' + (
        dfs(
            ffff.getHours() > 12
                ?
                (
                    ffff.getHours() - 12
                )
                :
                (
                    (
                        ffff.getHours() === 0
                        &&
                        '12'
                    )
                    ||
                    ffff.getHours()
                )
        )
    ) + '.' + dfs(
        (
            ffff.getMinutes() > 10
                ?
                ffff.getMinutes()
                :
                (
                    '0' + ffff.getMinutes()
                )
        )
    ) + 'টায়';

    test = test.slice(0, -1);

    st = (
        test !== '' &&
        `${todayOrNotToday} ${test}, ${h}`
    )
        ||
        (
            `${todayOrNotToday} ${Months[month]} ${year}, ${h}`
        );

    return (
        <Typography
            className={classes.root}
            children={st}
            {...props}
        />
    );
};

DateAndTimeForMatchLivescore.propTypes = {
    day: PropTypes.array.isRequired,
};

export default DateAndTimeForMatchLivescore;
