import React, {
	useEffect,
	Fragment
} from 'react';
import {
	NavLink,
} from 'react-router-dom';
import {
	useDispatch,
	useSelector
} from 'react-redux';
import {
	AppBar,
	Fade,
	MenuItem,
	Paper,
	Popper,
	Toolbar,
	Typography,
	useTheme
} from '@mui/material';
import {
	makeStyles,
} from '@mui/styles';
import {
	ArrowDropDown,
	// Search,
} from '@mui/icons-material';
import PopupState, {
	bindHover,
	bindPopper,
	bindToggle,
} from 'material-ui-popup-state';

import menus from './Menus';
import ProfileActions from '../profileActions';
import Notification from '../notification';
import {
	Button,
	CustomLink,
	Link,
	Logo,
} from '../../commonComponent';
import {
	fetchLocalMatches,
	fetchInternationalMatches,
	fetchUpcoming
} from '../../redux/actions/LiveScore';
import {
	fetchNotices
} from '../../redux/actions/Notice';
import {
	getIsLoggedIn,
	getLiveScoresHome,
	getActiveNotice,
} from '../../redux/reducers';;

const useStyles = makeStyles(
	theme => (
		{
			root: {
				width: '100%',
				color: theme.palette.primary.main,
			},
			grow: {
				flexGrow: 1,
			},
			appbar1: {
				//Background color for appbar
				// boxShadow: 'none',
				background: 'transparent',
				backdropFilter: 'blur(1px)',
				// backgroundColor: 'rgb( 0, 102, 0, 0.90)',
				backgroundColor: theme.palette.primary.dark,
				opacity: '0.9',
				backgroundImage: `radial-gradient(at 97% 49%, hsla(133,43%,24%,1) 0px, transparent 50%),
				radial-gradient(at 2% 67%, hsla(107,40%,26%,1) 0px, transparent 50%),
				radial-gradient(at 35% 8%, hsla(108,51%,25%,1) 0px, transparent 50%),
				radial-gradient(at 51% 6%, hsla(107,32%,19%,1) 0px, transparent 50%),
				radial-gradient(at 23% 40%, hsla(105,47%,18%,1) 0px, transparent 50%),
				radial-gradient(at 55% 35%, hsla(113,69%,14%,1) 0px, transparent 50%)`,
				// boxShadow: 'rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px'
			},
			search: {
				position: 'relative',
				borderRadius: theme.shape.borderRadius,
				backgroundColor: theme.palette.common.white,
				'&:hover': {
					backgroundColor: theme.palette.common.white,
				},
				marginLeft: 0,
				width: 'auto',
				height: '38px',
				[theme.breakpoints.down('md')]: {
					marginLeft: theme.spacing(1),
					width: '20%',
				},
				color: theme.palette.primary.main,
			},
			searchIcon: {
				width: '45px',
				height: '100%',
				position: 'absolute',
				pointerEvents: 'none',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				color: theme.palette.primary.main,
			},
			inputRoot: {
				color: 'inherit',
				width: '100%',
			},
			paper: {
				backgroundColor: theme.palette.common.white
			},
			button: {
				color: theme.palette.primary.main,
				fontWeight: '900',
				margin: theme.spacing(1),
				textDecoration: 'none',
				'&:hover': {
					// transform: 'scale(1.2)',
					backgroundColor: theme.palette.common.white,
				},
				transition: 'transform .5s ease-in-out',
			},
			button1: {
				color: theme.palette.primary.main,
				fontSize: '20px',
				display: 'flex',
				paddingTop: theme.spacing(4),
				height: '100%',
				width: '20%',
				alignItems: 'center',
				textDecoration: 'none'
			},
			buttonLog: {
				fontSize: '18px',
				fontWeight: 700
			},
			menu: {
				height: 'auto',
				minWidth: '150px',
				color: theme.palette.primary.dark,
				fontSize: '14px',
				// fontWeight: 900,
				padding: theme.spacing(1)
			},
			inputInput: {
				paddingTop: theme.spacing(1),
				paddingRight: theme.spacing(1),
				paddingBottom: theme.spacing(1),
				paddingLeft: theme.spacing(5),
				transition: theme.transitions.create('width'),
				width: '100%',
				[theme.breakpoints.up('sm')]: {
					width: 320,
					'&:focus': {
						width: 700,
					},
				},
			},
			toolbar1: {
				// minHeight: '40px',
				// maxHeight: '40px',
				margin: '0 auto'
			},
			toolbar: {
				// minHeight: '60px',
				maxHeight: '60px',
			},
			typologo: {
				fontWeight: 900,
				paddingBottom: theme.spacing(2),
				paddingLeft: theme.spacing(0.5)
			}
		}
	)
);

const Appbar = () => {
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	// const theme = useTheme();

	const livescores = useSelector(
		state => getLiveScoresHome(state)
	);
	const loggedIn = useSelector(
		state => getIsLoggedIn(state)
	);
	const notice = useSelector(
		state => getActiveNotice(state)
	);

	useEffect(
		() => {
			dispatch(
				fetchLocalMatches()
			);
		},
		[
			dispatch
		]
	);

	useEffect(
		() => {
			dispatch(
				fetchInternationalMatches()
			);
		},
		[
			dispatch
		]
	);

	useEffect(
		() => {
			dispatch(
				fetchUpcoming()
			);
		},
		[
			dispatch
		]
	);

	useEffect(
		() => {
			dispatch(
				fetchNotices(0, 0)
			);
		},
		[
			dispatch
		]
	);
	// if (isFetching) return null;

	return (
		<div className={classes.root}>
			<AppBar
				position="fixed"
				className={classes.appbar1}
				sx={{
					background: 'transparent',
					backdropFilter: 'blur(1px)',
					// backgroundColor: 'rgb( 0, 102, 0, 0.90)',
					backgroundColor: theme.palette.primary.dark,
					opacity: '0.9',
					'& .MuiToolbar-root': {
						minHeight: '50px',
						maxHeight: '50px'
					},
					'& .MuiButton-root': {
						fontWeight: 'bold',
						borderRadius: 0
					},
					'& .MuiButtonBase-root': {
						padding: '11px 8px'
					}
				}}
			>
				<Toolbar className={classes.toolbar}>
					{/* <div className={classes.search}> */}
					<Link
						to='/'
						className={classes.button1}
						title='খেলা হবে!'
					>
						<Logo
							height='70px'
							width='60px'
						/>
						<Typography
							variant='h6'
							align='center'
							noWrap
							sx={{
								fontWeight: 900,
								paddingBottom: theme.spacing(2),
								paddingLeft: theme.spacing(0.5)
							}}
						>
							খেলা হবে!
						</Typography>
						{/* খেলা হবে! */}
					</Link>
					{/* </div> */}
					<div className={classes.grow} />
					{/* <div className={classes.search}>
						<div className={classes.searchIcon}>
							<Search />
						</div>
						<InputBase
							placeholder="অনুসন্ধান"
							title='অনুসন্ধান করুন'
							classes={{
								root: classes.inputRoot,
								input: classes.inputInput,
							}}
						/>

					</div> */}
					{
						(
							loggedIn
							&&
							<ProfileActions />
						)
						||
						<CustomLink
							to='/login'
							text='লগ ইন'
							className={classes.buttonLog}
						/>
					}
				</Toolbar>
				<Toolbar
					className={classes.toolbar1}
					sx={{
						minHeight: '40px',
						maxHeight: '40px'
					}}
				>
					<PopupState
						variant="popper"
						popupId="demo-popup-popper"
					>
						{
							popupState => (
								<Fragment>
									<Button
										color="primary"
										className={classes.button}
										{...bindToggle(popupState)}
										{...bindHover(popupState)}
										// title='স্কোরবোর্ড'
										text='স্কোরবোর্ড'
										variant='text'
										endIcon={
											<ArrowDropDown />
										}
									/>
									<Popper
										onMouseLeave={popupState.close}
										{...bindPopper(popupState)}
										transition
										elevation={2}
										onClick={popupState.close}
										placement='bottom-start'
										style={{
											zIndex: 200
										}}
									>
										{
											(
												{
													TransitionProps
												}
											) => (
												<Fade
													{...TransitionProps}
													timeout={350}
												>
													<Paper
														id="fade-menu"
														className={classes.paper}
														sx={{
															paddingTop: theme.spacing(1),
														}}
													>
														{
															livescores.map(
																(
																	option, index
																) => (
																	<MenuItem
																		className={classes.menu}
																		key={index}
																		to={option.slug[0] && `/scoreboard/${option.slug[0]}`}
																		component={Link}
																		sx={{
																			"&:hover": {
																				backgroundColor: theme.palette.primary.light,
																				color: theme.palette.primary.dark
																			}
																		}}
																	>
																		{option.teamone} বনাম {option.teamtwo}
																	</MenuItem>
																)
															)
														}
													</Paper>
												</Fade>
											)
										}
									</Popper>
								</Fragment>
							)
						}
					</PopupState>
					{
						menus.data.map(
							(
								menu, index
							) => (
								(
									menu.children
									&&
									(
										<PopupState
											key={index}
											variant="popper"
											popupId="demo-popup-popper"
										>
											{
												popupState => (
													<Fragment>
														<Button
															color="primary"
															className={classes.button}
															{...bindToggle(popupState)}
															{...bindHover(popupState)}
															// title={menu.name}
															text={menu.name}
															variant='text'
															endIcon={
																<ArrowDropDown />
															}
														/>
														<Popper
															onMouseLeave={popupState.close}
															{...bindPopper(popupState)}
															transition
															elevation={2}
															onClick={popupState.close}
															placement='bottom-start'
															style={{
																zIndex: 200
															}}
														>
															{
																(
																	{
																		TransitionProps
																	}
																) => (
																	<Fade
																		{...TransitionProps}
																		timeout={350}
																	>
																		<Paper
																			id="fade-menu"
																			className={classes.paper}
																			sx={{
																				paddingTop: theme.spacing(1),
																			}}
																		>
																			{
																				menu.children.map(
																					option => (
																						<MenuItem
																							// containerElement={<Link to={option.url} />}
																							component={Link}
																							key={option.name}
																							to={option.url}
																							// title={option.name}
																							className={classes.menu}
																							sx={{
																								"&:hover": {
																									backgroundColor: theme.palette.primary.light,
																									color: theme.palette.primary.dark
																								}
																							}}
																						>
																							{option.name}
																						</MenuItem>
																					)
																				)
																			}
																		</Paper>
																	</Fade>
																)
															}
														</Popper>
													</Fragment>
												)
											}
										</PopupState>
									)
								)
								||
								<Button
									component={NavLink}
									to={menu.url}
									color="primary"
									className={classes.button}
									// title={menu.name}
									text={menu.name}
									key={index}
									variant='text'
									activeStyle={{
										borderBottom: 'solid',
										// backgroundColor: fade(theme.palette.common.white, 0.15),
										// transform: 'scale(1.4)',
										// backgroundColor: fade('white', 0.15),
										// color: "red"
									}}
								>
									{menu.name}
								</Button>
							)
						)
					}
				</Toolbar>
			</AppBar>
			{
				notice.active === true
				&&
				<Notification
					severity='warning'
					message={notice.notice}
				/>
			}
		</div>
	);
}

export default Appbar;