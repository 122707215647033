const tableCellScorecardBowling = [
    {
        name: 'বোলার',
        space: 2,
        align: 'left'
    },
    {
        name: 'ওভার',
        space: 1,
        align: 'center'
    },
    {
        name: 'মেইডেন',
        space: 1,
        align: 'center'
    },
    {
        name: 'রান',
        space: 1,
        align: 'center'
    },
    {
        name: 'উইকেট',
        space: 1,
        align: 'center'
    },
    {
        name: 'ওয়াইড',
        space: 1,
        align: 'center'
    },
    {
        name: 'নোবল',
        space: 1,
        align: 'center'
    },
    {
        name: 'ইকোনমি',
        space: 1,
        align: 'center'
    }
];

export default tableCellScorecardBowling;