import React from 'react';
import ReactDOM from 'react-dom';
import {
    Provider,
} from 'react-redux';
import {
    ToastContainer
} from 'react-toastify';
import App from "./components/App";
import store from './redux/store';
import {
    generateApiUrls
} from './Api';

/*
https://khelahobe.co api prefix should be used while testing or running the app for the production backend.
http://localhost:9090 should be used when the front-end is tested against a local environment. 
comment out the one not used
*/
const cloudServer = 'https://khelahobe.co/';
// const localSrver = 'http://localhost:9090/';

generateApiUrls(cloudServer);

ReactDOM.render(
    <Provider store={store} >
        <ToastContainer
            autoClose={2000}
            position="top-center"
        />
        <App
            store={store}
        />
    </Provider >,
    document.getElementById('root')
);
