export const actions = {
    REQUEST_LOGIN: 'REQUEST_LOGIN',
    SUCCESS_LOGIN: 'SUCCESS_LOGIN',
    FAILURE_LOGIN: 'FAILURE_LOGIN',

    REQUEST_LOGOUT: 'REQUEST_LOGOUT',
    SUCCESS_LOGOUT: 'SUCCESS_LOGOUT',
    FAILURE_LOGOUT: 'FAILURE_LOGOUT',

    REQUEST_FETCH_ID: 'REQUEST_FETCH_ID',
    SUCCESS_FETCH_ID: 'SUCCESS_FETCH_ID',
    FAILURE_FETCH_ID: 'FAILURE_FETCH_ID',

    REQUEST_FETCH_PROFILE: 'REQUEST_FETCH_PROFILE',
    SUCCESS_FETCH_PROFILE: 'SUCCESS_FETCH_PROFILE',
    FAILURE_FETCH_PROFILE: 'FAILURE_FETCH_PROFILE',
};