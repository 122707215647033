import React from 'react';
import {
    Divider,
    useTheme
} from '@mui/material';

const Dividers = ({
    ...props
}) => {
    // const classes = useStyles();
    const theme = useTheme();

    return (
        <Divider
            sx={{
                width: '60%',
                margin: '10px auto',
                height: '1px',
                [theme.breakpoints.down('md')]: {
                    width: '80%',
                },
            }}
            {...props}
        />
    );
};

export default Dividers;