import React from 'react';
import {
    Link,
} from 'react-router-dom';
import {
    Chip,
    useTheme
} from '@mui/material';
import {
    makeStyles
} from '@mui/styles';

const useStyles = makeStyles(
    theme => (
        {
            root: {
                display: 'inline',
                // justifyContent: 'center',
                // flexWrap: 'wrap',
                listStyle: 'none',
                padding: theme.spacing(0.5),
                margin: '8px 0',
                width: '100%'
            },
            chip: {
                margin: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px ${theme.spacing(1.25)}px ${theme.spacing(0.5)}px`,
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.primary.light,
                '& .MuiChip-label': {
                    paddingTop: 5
                }
            },
        }
    )
);

const Chips = ({
    data,
    flag //make the chip clickable for 1 and not otherwise
}) => {
    const classes = useStyles();
    const theme = useTheme();

    if (data[0] === '') return null;

    return (
        <div
            className={classes.root}
        >
            {
                data.map(
                    (i, index) => {
                        const j = decodeURI(i);

                        return (
                            <Chip
                                key={index}
                                label={i}
                                className={classes.chip}
                                sx={{
                                    margin: `${theme.spacing(0.5)} ${theme.spacing(0.5)} ${theme.spacing(1.25)} ${theme.spacing(0.5)}`,
                                    backgroundColor: theme.palette.primary.dark,
                                    color: theme.palette.primary.light,
                                    '& .MuiChip-label': {
                                        // paddingTop: 1
                                    }
                                }}
                                clickable={flag ? true : false}
                                component={flag ? Link : ''}
                                to={`/table/highlights-tags/0/${j}`}
                            />
                        )
                    }
                )
            }
        </div>
    );
};

export default Chips;