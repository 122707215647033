export const actions = {
    REQUEST_FETCH_STATIC_CONTENTS: 'REQUEST_FETCH_STATIC_CONTENTS',
    SUCCESS_FETCH_STATIC_CONTENTS: 'SUCCESS_FETCH_STATIC_CONTENTS',
    FAILURE_FETCH_STATIC_CONTENTS: 'FAILURE_FETCH_STATIC_CONTENTS',

    REQUEST_CREATE_STATIC_CONTENT: 'REQUEST_CREATE_STATIC_CONTENT',
    SUCCESS_CREATE_STATIC_CONTENT: 'SUCCESS_CREATE_STATIC_CONTENT',
    FAILURE_CREATE_STATIC_CONTENT: 'FAILURE_CREATE_STATIC_CONTENT',

    REQUEST_UPDATE_STATIC_CONTENT: 'REQUEST_UPDATE_STATIC_CONTENT',
    SUCCESS_UPDATE_STATIC_CONTENT: 'SUCCESS_UPDATE_STATIC_CONTENT',
    FAILURE_UPDATE_STATIC_CONTENT: 'FAILURE_UPDATE_STATIC_CONTENT',

    REQUEST_DELETE_STATIC_CONTENT: 'REQUEST_DELETE_STATIC_CONTENT',
    SUCCESS_DELETE_STATIC_CONTENT: 'SUCCESS_DELETE_STATIC_CONTENT',
    FAILURE_DELETE_STATIC_CONTENT: 'FAILURE_DELETE_STATIC_CONTENT',

    REQUEST_FETCH_STATIC_CONTENT_BY_ID: 'REQUEST_FETCH_STATIC_CONTENT_BY_ID',
    SUCCESS_FETCH_STATIC_CONTENT_BY_ID: 'SUCCESS_FETCH_STATIC_CONTENT_BY_ID',
    FAILURE_FETCH_STATIC_CONTENT_BY_ID: 'FAILURE_FETCH_STATIC_CONTENT_BY_ID',

    REQUEST_FETCH_STATIC_CONTENT_BY_SLUG: 'REQUEST_FETCH_STATIC_CONTENT_BY_SLUG',
    SUCCESS_FETCH_STATIC_CONTENT_BY_SLUG: 'SUCCESS_FETCH_STATIC_CONTENT_BY_SLUG',
    FAILURE_FETCH_STATIC_CONTENT_BY_SLUG: 'FAILURE_FETCH_STATIC_CONTENT_BY_SLUG',
}