export const actions = {
    REQUEST_FETCH_NEWS_REQUESTS: 'REQUEST_FETCH_NEWS_REQUESTS',
    SUCCESS_FETCH_NEWS_REQUESTS: 'SUCCESS_FETCH_NEWS_REQUESTS',
    FAILURE_FETCH_NEWS_REQUESTS: 'FAILURE_FETCH_NEWS_REQUESTS',

    REQUEST_CREATE_NEWS_REQUEST: 'REQUEST_CREATE_NEWS_REQUEST',
    SUCCESS_CREATE_NEWS_REQUEST: 'SUCCESS_CREATE_NEWS_REQUEST',
    FAILURE_CREATE_NEWS_REQUEST: 'FAILURE_CREATE_NEWS_REQUEST',

    REQUEST_UPDATE_NEWS_REQUEST: 'REQUEST_UPDATE_NEWS_REQUEST',
    SUCCESS_UPDATE_NEWS_REQUEST: 'SUCCESS_UPDATE_NEWS_REQUEST',
    FAILURE_UPDATE_NEWS_REQUEST: 'FAILURE_UPDATE_NEWS_REQUEST',

    REQUEST_DELETE_NEWS_REQUEST: 'REQUEST_DELETE_NEWS_REQUEST',
    SUCCESS_DELETE_NEWS_REQUEST: 'SUCCESS_DELETE_NEWS_REQUEST',
    FAILURE_DELETE_NEWS_REQUEST: 'FAILURE_DELETE_NEWS_REQUEST',

    REQUEST_COUNT_NEWS_REQUESTS: 'REQUEST_COUNT_NEWS_REQUESTS',
    SUCCESS_COUNT_NEWS_REQUESTS: 'SUCCESS_COUNT_NEWS_REQUESTS',
    FAILURE_COUNT_NEWS_REQUESTS: 'FAILURE_COUNT_NEWS_REQUESTS',

    REQUEST_FETCH_NEWS_REQUEST_BY_ID: 'REQUEST_FETCH_NEWS_REQUEST_BY_ID',
    SUCCESS_FETCH_NEWS_REQUEST_BY_ID: 'SUCCESS_FETCH_NEWS_REQUEST_BY_ID',
    FAILURE_FETCH_NEWS_REQUEST_BY_ID: 'FAILURE_FETCH_NEWS_REQUEST_BY_ID',

    REQUEST_FETCH_NEWS_REQUESTS_BY_SLUG: 'REQUEST_FETCH_NEWS_REQUESTS_BY_SLUG',
    SUCCESS_FETCH_NEWS_REQUESTS_BY_SLUG: 'SUCCESS_FETCH_NEWS_REQUESTS_BY_SLUG',
    FAILURE_FETCH_NEWS_REQUESTS_BY_SLUG: 'FAILURE_FETCH_NEWS_REQUESTS_BY_SLUG',
}