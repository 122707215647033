import {
    Brightness1,
    EmojiEvents,
    WatchLater
} from '@mui/icons-material';

const arr = [
    {
        id: 0,
        name: 'সরাসরি',
        path: 'live',
        icon: Brightness1
    },
    {
        id: 1,
        name: 'আসন্ন',
        path: 'upcoming',
        icon: EmojiEvents
    },
    {
        id: 2,
        name: 'সমাপ্ত',
        path: 'finished',
        icon: WatchLater
    },
];

export default arr;