export const actions = {
	REQUEST_FETCH_BLOGS: 'REQUEST_FETCH_BLOGS',
	SUCCESS_FETCH_BLOGS: 'SUCCESS_FETCH_BLOGS',
	FAILURE_FETCH_BLOGS: 'FAILURE_FETCH_BLOGS',

	REQUEST_FETCH_BLOG_BY_ID: 'REQUEST_FETCH_BLOG_BY_ID',
	SUCCESS_FETCH_BLOG_BY_ID: 'SUCCESS_FETCH_BLOG_BY_ID',
	FAILURE_FETCH_BLOG_BY_ID: 'FAILURE_FETCH_BLOG_BY_ID',

	REQUEST_FETCH_BLOG_BY_SLUG: 'REQUEST_FETCH_BLOG_BY_SLUG',
	SUCCESS_FETCH_BLOG_BY_SLUG: 'SUCCESS_FETCH_BLOG_BY_SLUG',
	FAILURE_FETCH_BLOG_BY_SLUG: 'FAILURE_FETCH_BLOG_BY_SLUG',

	REQUEST_CREATE_BLOG: 'REQUEST_CREATE_BLOG',
	SUCCESS_CREATE_BLOG: 'SUCCESS_CREATE_BLOG',
	FAILURE_CREATE_BLOG: 'FAILURE_CREATE_BLOG',

	REQUEST_UPDATE_BLOG: 'REQUEST_UPDATE_BLOG',
	SUCCESS_UPDATE_BLOG: 'SUCCESS_UPDATE_BLOG',
	FAILURE_UPDATE_BLOG: 'FAILURE_UPDATE_BLOG',

	REQUEST_DELETE_BLOG: 'REQUEST_DELETE_BLOG',
	SUCCESS_DELETE_BLOG: 'SUCCESS_DELETE_BLOG',
	FAILURE_DELETE_BLOG: 'FAILURE_DELETE_BLOG',

	REQUEST_COUNT_BLOG: 'REQUEST_COUNT_BLOG',
	SUCCESS_COUNT_BLOG: 'SUCCESS_COUNT_BLOG',
	FAILURE_COUNT_BLOG: 'FAILURE_COUNT_BLOG',
}