import {
    getIsFetchingNewsRequest,
    getToken
} from '../reducers';
import {
    endPoints
} from '../../Api';
import {actions} from "../action-types/newsrequest";

export const fetchNewsRequests = (skip, limit) => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_FETCH_NEWS_REQUESTS,
            actions.SUCCESS_FETCH_NEWS_REQUESTS,
            actions.FAILURE_FETCH_NEWS_REQUESTS
        ],
        data: {
            skip,
            limit
        },
        method: endPoints.FETCH_NEWS_REQUESTS,
        race: getIsFetchingNewsRequest,
    }
});

export const createNewsRequest = state => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_CREATE_NEWS_REQUEST,
            actions.SUCCESS_CREATE_NEWS_REQUEST,
            actions.FAILURE_CREATE_NEWS_REQUEST
        ],
        method: endPoints.CREATE_NEWS_REQUEST,
        race: getIsFetchingNewsRequest,
        data: {
            ...state,
            token: getToken(getState()),
            tags: state.tags.split(','),
            related_news: state.related_news.split(','),
        }
    }
});

export const updateNewsRequest = state => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_UPDATE_NEWS_REQUEST,
            actions.SUCCESS_UPDATE_NEWS_REQUEST,
            actions.FAILURE_UPDATE_NEWS_REQUEST
        ],
        method: endPoints.UPDATE_NEWS_REQUEST,
        race: getIsFetchingNewsRequest,
        data: {
            ...state,
            token: getToken(getState()),
            tags: state.tags.split(','),
            related_news: state.related_news.split(','),
            id: state.id
        }
    }
});

export const deleteNewsRequest = id => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_DELETE_NEWS_REQUEST,
            actions.SUCCESS_DELETE_NEWS_REQUEST,
            actions.FAILURE_DELETE_NEWS_REQUEST
        ],
        method: endPoints.DELETE_NEWS_REQUEST,
        race: getIsFetchingNewsRequest,
        data: {
            id,
            token: getToken(getState()),
        }
    }
});

export const fetchNewsRequestById = id => ({
    RSAA: {
        types: [
            actions.REQUEST_FETCH_NEWS_REQUEST_BY_ID,
            actions.SUCCESS_FETCH_NEWS_REQUEST_BY_ID,
            actions.FAILURE_FETCH_NEWS_REQUEST_BY_ID,
        ],
        method: endPoints.FETCH_NEWS_REQUEST_BY_ID,
        race: () => false,
        data: {
            id,
        },
    },
});

export const fetchNewsRequestBySlug = slug => ({
    RSAA: {
        types: [
            actions.REQUEST_FETCH_NEWS_REQUEST_BY_SLUG,
            actions.SUCCESS_FETCH_NEWS_REQUEST_BY_SLUG,
            actions.FAILURE_FETCH_NEWS_REQUEST_BY_SLUG,
        ],
        method: endPoints.FETCH_NEWS_REQUEST_BY_SLUG,
        race: () => false,
        data: {
            slug,
        },
    },
});

export const countNewsRequest = () => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_COUNT_NEWS_REQUESTS,
            actions.SUCCESS_COUNT_NEWS_REQUESTS,
            actions.FAILURE_COUNT_NEWS_REQUESTS,
        ],
        method: endPoints.COUNT_NEWS_REQUESTS,
        race: () => false,
    },
});