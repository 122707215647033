import React, {
	memo
} from 'react';
import {
	Grid,
} from '@mui/material';
import {
	makeStyles,
	useTheme
} from '@mui/styles';
import PropTypes from 'prop-types';
import Banner from './Banner';
import {
	DateAndTimeForMatchLivescore,
} from '../../commonComponent';

const useStyles = makeStyles(
	theme => (
		{
			matchstatus: {
				color: theme.palette.primary.dark,
				fontWeight: 900
			}
		}
	)
);

// const areEqual = (
// 	prevProps,
// 	nextProps
// ) => prevProps.matchStatus === nextProps.matchStatus;

const DateOrMatchStatus = memo(
	(
		{
			matchStatus,
			start_time,
			days,
			series,
			stadium,
			...props
		}
	) => {
		const classes = useStyles();
		const theme = useTheme();

		return (
			<Grid
				item
				container
				xs
				direction="column"
			>
				{
					(matchStatus !== 'RESULT' && matchStatus !== '{{MATCH_START_TIME}}' && matchStatus !== '{{match_start_time}}')
					&&
					<Grid
						item
						xs
					>
						<Banner
							children={matchStatus}
							sx={{
								color: theme.palette.primary.dark,
								fontWeight: 900
							}}
							{...props}
						/>
						<DateAndTimeForMatchLivescore
							start_time={start_time}
							day={days}
							{...props}
						/>
					</Grid>
				}
				{
					(matchStatus === 'RESULT' || matchStatus === '{{MATCH_START_TIME}}' || matchStatus === '{{match_start_time}}')
					&&
					<Grid
						item
						xs
					>
						<DateAndTimeForMatchLivescore
							start_time={start_time}
							day={days}
							className={classes.matchstatus}
							sx={{
								color: theme.palette.primary.dark,
								fontWeight: 900
							}}
							{...props}
						/>
						<Banner
							children={
								series
									?
									`${series}, ${stadium}`
									:
									`${stadium}`
							}
							{...props}
						/>
					</Grid>
				}
			</Grid>
		);
	},
);

DateOrMatchStatus.propTypes = {
	start_time: PropTypes.string.isRequired,
	matchStatus: PropTypes.string.isRequired
};

export default DateOrMatchStatus;