import React from 'react';
import {
    Link
} from 'react-router-dom';
import {
    Button,
    // Fade,
} from '@mui/material';
import {
    makeStyles
} from '@mui/styles';

const useStyles = makeStyles(
    theme => (
        {
            button: {
                color: theme.palette.primary.main,
                fontWeight: '700',
                margin: theme.spacing(0.5),
                textDecoration: 'none',
                '&:hover': {
                    transform: 'scale(1.2)',
                    // backgroundColor: Fade(theme.palette.common.white, 0.15),
                },
                transition: 'transform .5s ease-in-out',
            },
            link: {
                textDecoration: 'none',
            }
        }
    )
);

const CustomLink = ({
    to,
    text,
    title
}) => {
    const classes = useStyles();

    return (
        <Link
            to={to}
            title={title}
            className={classes.link}
        >
            <Button
                color="primary"
                className={classes.button}
            >
                {text}
            </Button>
        </Link>
    );
}

export default CustomLink;