const disciplines = [
    {
        Id: 0,
        Name: 'ব্যাটিং',
        Path: 'batting',
    },
    {
        Id: 1,
        Name: 'বোলিং',
        Path: 'bowling',
    },
    {
        Id: 2,
        Name: 'অলরাউন্ডার',
        Path: 'all-rounder',
    },
    {
        Id: 3,
        Name: 'টিম',
        Path: 'team',
    },
];

export default disciplines;