import React from 'react';
import ClampLines from 'react-clamp-lines';
import {
    Link
} from '../../commonComponent';
import Carousel from '@brainhubeu/react-carousel';
import clsx from 'clsx';
import {
    IconButton,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import {
    makeStyles
} from '@mui/styles';
import {
    KeyboardArrowLeft,
    KeyboardArrowRight,
} from '@mui/icons-material';
import '@brainhubeu/react-carousel/lib/style.css';
import Icon from '../../assets/KhelahobeLogo.png';

const useStyles = makeStyles(
    theme => (
        {
            gridList: {
                height: 'auto',
                width: '100%',
                margin: '0 auto',
                color: theme.palette.primary.dark
            },
            container: {
                width: '100%',
                height: 'auto',
                // margin: '0 auto',
                margin: theme.spacing(2),
                padding: theme.spacing(2),
                textDecoration: 'none',
                backgroundColor: 'white',
                borderRadius: '8px',
                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                color: theme.palette.secondary.dark,
                [theme.breakpoints.down('xs')]: {
                    padding: 0
                },
                '&:hover': {
                    // transform: 'scale(1.03)',
                    boxShadow: '0px 5px 2px -3px #33691e'
                },
                WebkitTransition: 'box-shadow 0.5s fade-in',
                MozTransition: 'box-shadow 0.5s fade-in',
                MsTransition: 'box-shadow 0.5s fade-in',
                OTransition: 'box-shadow 0.5s fade-in',
                transition: 'box-shadow 0.5s fade-in',
            },
            grid3: {
                display: 'block',
                objectFit: 'cover',
                padding: 0,
                filter: 'brightness(100%)',
                // transition: 'transform 1s ease-in-out',
                marginBottom: 8,
            },
            carousel: {
                margin: '8px 8px 60px 8px',
                padding: '8px 8px 8px 8px',
                backgroundColor: theme.palette.primary.dark,
                [theme.breakpoints.down('sm')]: {
                    padding: '2px 2px 2px 2px'
                },
            },
            linkimage: {
                width: '100%',
                color: theme.palette.primary.dark,
                textDecoration: 'none',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundOrigin: 'center',
                backgroundPosition: 'center',
                minHeight: '200px',
                maxHeight: '400px',
                height: 'auto',
            }
        }
    )
);

const AlsoSee = ({
    obj,
    flag,
    title,
    link,
    ...props
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const xs = useMediaQuery((theme) => theme.breakpoints.only('xs'));
    const sm = useMediaQuery((theme) => theme.breakpoints.only('sm'));
    const md = useMediaQuery((theme) => theme.breakpoints.only('md'));
    const lg = useMediaQuery((theme) => theme.breakpoints.only('lg'));

    // const colN = col[props.width];

    return (
        <div className={classes.gridList}>
            <Typography
                variant='h6'
                align='center'
                sx={{
                    fontWeight: 900
                }}
            >
                {title}
            </Typography>
            <br />
            <Carousel
                slidesPerPage={xs ? 1 : (sm ? 2 : (md ? 3 : (lg ? 4 : 5)))}
                arrowLeft={
                    <IconButton
                        className={classes.carousel}
                        sx={{
                            fontSize: '3em',
                            backgroundColor: 'white',
                            color: theme.palette.primary.dark,
                            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                            // opacity: 1,
                            marginLeft: theme.spacing(1),
                            '&:hover': {
                                backgroundColor: theme.palette.primary.dark,
                                color: 'white',
                            },
                            // WebkitTransition: 'opacity 0.5s fade-in',
                            // MozTransition: 'opacity 0.5s fade-in',
                            // MsTransition: 'opacity 0.5s fade-in',
                            // OTransition: 'opacity 0.5s fade-in',
                            // transition: 'opacity 0.5s fade-in',
                        }}
                    >
                        <KeyboardArrowLeft
                        />
                    </IconButton>
                }
                arrowRight={
                    <IconButton
                        className={classes.carousel}
                        sx={{
                            fontSize: '3em',
                            backgroundColor: 'white',
                            color: theme.palette.primary.dark,
                            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                            marginRight: theme.spacing(1),
                            '&:hover': {
                                backgroundColor: theme.palette.primary.dark,
                                color: 'white',
                            },
                            // WebkitTransition: 'opacity 0.5s fade-in',
                            // MozTransition: 'opacity 0.5s fade-in',
                            // MsTransition: 'opacity 0.5s fade-in',
                            // OTransition: 'opacity 0.5s fade-in',
                            // transition: 'opacity 0.5s fade-in',
                        }}
                    >
                        <KeyboardArrowRight />
                    </IconButton>
                }
                infinite
                addArrowClickHandler
            >
                {
                    obj.map(
                        (tile, index) => (
                            <Link
                                to={`${link}/${tile.slug || tile.blog_slug}`}
                                key={index}
                                title={tile.title}
                                className={classes.container}
                                style={{
                                    backgroundColor: 'white',
                                    // '&:hover': {
                                    //     // transform: 'scale(1.03)',
                                    //     boxShadow: '0px 5px 2px -3px #33691e'
                                    // },
                                    // WebkitTransition: 'box-shadow 0.5s fade-in',
                                    // MozTransition: 'box-shadow 0.5s fade-in',
                                    // MsTransition: 'box-shadow 0.5s fade-in',
                                    // OTransition: 'box-shadow 0.5s fade-in',
                                    // transition: 'box-shadow 0.5s fade-in',
                                }}
                            >
                                <div
                                    align='center'
                                    style={{
                                        backgroundImage: flag ? `url(https://img.youtube.com/vi/${tile.youtube_id}/0.jpg)` : '',
                                    }}
                                    className={
                                        clsx(
                                            {
                                                [classes.linkimage]: flag,
                                            }
                                        )
                                    }
                                >
                                    {
                                        !flag
                                        &&
                                        <img
                                            src={tile.images[0] || Icon}
                                            className={classes.grid3}
                                            alt="featured"
                                            width='80%'
                                            height='250'
                                        />
                                    }

                                </div>
                                <ClampLines
                                    text={tile.title}
                                    id={tile.id}
                                    lines={2}
                                    ellipsis='...'
                                    buttons={false}
                                // className={classes.clampline}
                                />
                                {/* <Typography
                                    gutterBottom
                                >
                                    {tile.title}
                                </Typography> */}
                            </Link>
                        )
                    )
                }
            </Carousel>
        </div>
    );
};

export default AlsoSee; 