import {
    getToken
} from '../reducers';
import {
    endPoints
} from '../../Api';
import {actions} from "../action-types/notification";

export const giveActions = () => {
    return actions ; 
}

export const sendToken = token => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_SEND_TOKEN,
            actions.SUCCESS_SEND_TOKEN,
            actions.FAILURE_SEND_TOKEN
        ],
        method: endPoints.SEND_TOKEN,
        race: () => false,
        data: {
            device_token: token,
        }
    }
});

export const fetchTokens = (skip, limit) => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_FETCH_TOKENS,
            actions.SUCCESS_FETCH_TOKENS,
            actions.FAILURE_FETCH_TOKENS
        ],
        method: endPoints.FETCH_TOKENS,
        race: () => false,
        data: {
            skip,
            limit,
            token: getToken(getState())
        }
    }
});

export const fetchTokenById = id => ({
    RSAA: {
        types: [
            actions.REQUEST_FETCH_Token_BY_ID,
            actions.SUCCESS_FETCH_Token_BY_ID,
            actions.FAILURE_FETCH_Token_BY_ID,
        ],
        method: endPoints.FETCH_Token_BY_ID,
        race: () => false,
        data: {
            id,
        },
    },
});

export const createNotification = state => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_CREATE_NOTIFICATION,
            actions.SUCCESS_CREATE_NOTIFICATION,
            actions.FAILURE_CREATE_NOTIFICATION
        ],
        method: endPoints.CREATE_NOTIFICATION,
        race: () => false,
        data: {
            "registration_ids": state.registration_ids,
            "notification": {
                "title": state.title,
                "description": state.description,
                "mutable_content": true,
                "sound": "tri-tone",
                "icon": "https://i.ibb.co/DYR88pq/Khelahobe-Logo.png"
            }
        }
    }
});

export const updateToken = state => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_UPDATE_TOKEN,
            actions.SUCCESS_UPDATE_TOKEN,
            actions.FAILURE_UPDATE_TOKEN
        ],
        method: endPoints.UPDATE_TOKEN,
        race: () => false,
        data: {
            ...state,
            token: getToken(getState()),
            id: state.id,
        }
    }
});

export const deleteToken = id => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_DELETE_TOKEN,
            actions.SUCCESS_DELETE_TOKEN,
            actions.FAILURE_DELETE_TOKEN
        ],
        method: endPoints.DELETE_TOKEN,
        race: () => false,
        data: {
            id,
            token: getToken(getState()),
        }
    }
});