import React from 'react';
import {
    makeStyles
} from '@mui/styles';
import clsx from 'clsx';

import Unidentified from '../assets/Unidentified.png';
import {
    flags
} from '../assets/data';

const useStyles = makeStyles(
    theme => (
        {
            bold: {
                marginBottom: theme.spacing(1),
            },
            img: {
                //position: 'absolute',
                verticalAlign: 'middle',
                paddingRight: theme.spacing(1),
                height: '30px',
                width: '45px'
            },
            imgdpl: {
                verticalAlign: 'middle',
                paddingRight: theme.spacing(1),
                height: '30px',
                width: '40px'
            },
            imgdpl2: {
                verticalAlign: 'middle',
                paddingRight: theme.spacing(1),
                height: '30px',
                width: '40px'
            },
            left: {
                textAlign: 'left',
                float: 'left',
                marginLeft: 2,
                height: '33px',
                fontSize: '1.2rem',
                verticalAlign: 'middle',
                paddingTop: 3,
                color: theme.palette.primary.dark
            },
            right: {
                textAlign: 'right',
                float: 'right',
                marginRight: 2,
                height: '33px',
                fontSize: '1.2rem',
                verticalAlign: 'middle',
                paddingTop: 3,
                color: theme.palette.primary.dark
            }
        }
    )
);

const Flag = ({
    country,
    team,
    ff,
    ...props
}) => {
    const classes = useStyles();

    return (
        <>
            {
                (flags[country]
                    &&
                    <img
                        alt={country}
                        src={flags[country] && flags[country].src}
                        className={(flags[country].className === 'img' && classes.img) || (flags[country].className === 'imgdpl' && classes.imgdpl) || classes.imgdpl2}
                        {...props}
                    />)
                ||
                <img
                    alt={country}
                    src={Unidentified}
                    className={classes.imgdpl2}
                    {...props}
                />
            }
            {
                (ff === 'left' || ff === 'right')
                &&
                <>
                    <br />
                    <br />
                </>
            }
            {
                team
                &&
                <b
                    className={
                        clsx(
                            classes.bold,
                            {
                                [classes.left]: ff === 'left',
                            },
                            {
                                [classes.right]: ff === 'right',
                            }
                        )
                    }
                >
                    {country}
                </b>
            }
        </>
    );
}

export default Flag;