const defaultRegisterState = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    phone: '',
    address: '',
    password1: '',
    password2: '',
    dateofbirth: new Date(),
    gender: 'female',
    showPassword1: false,
    showPassword2: false,
    valid: {
        firstName: true,
        dateofbirth: true,
        lastName: true,
        userName: true,
        email: true,
        phone: true,
        address: true,
        gender: true,
        password1: true,
        password2: true,
    },
    required: {
        firstName: true,
        lastName: true,
        userName: true,
        email: true,
        phone: true,
        address: false,
        gender: true,
        password1: true,
        password2: true,
    }
};

export default defaultRegisterState;