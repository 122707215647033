export const actions = {
    REQUEST_FETCH_DICTIONARY: 'REQUEST_FETCH_DICTIONARY',
    SUCCESS_FETCH_DICTIONARY: 'SUCCESS_FETCH_DICTIONARY',
    FAILURE_FETCH_DICTIONARY: 'FAILURE_FETCH_DICTIONARY',

    REQUEST_FETCH_DICTIONARY_BY_ID: 'REQUEST_FETCH_DICTIONARY_BY_ID',
    SUCCESS_FETCH_DICTIONARY_BY_ID: 'SUCCESS_FETCH_DICTIONARY_BY_ID',
    FAILURE_FETCH_DICTIONARY_BY_ID: 'FAILURE_FETCH_DICTIONARY_BY_ID',

    REQUEST_FETCH_DICTIONARY_BY_SLUG: 'REQUEST_FETCH_DICTIONARY_BY_SLUG',
    SUCCESS_FETCH_DICTIONARY_BY_SLUG: 'SUCCESS_FETCH_DICTIONARY_BY_SLUG',
    FAILURE_FETCH_DICTIONARY_BY_SLUG: 'FAILURE_FETCH_DICTIONARY_BY_SLUG',

    REQUEST_CREATE_DICTIONARY: 'REQUEST_CREATE_DICTIONARY',
    SUCCESS_CREATE_DICTIONARY: 'SUCCESS_CREATE_DICTIONARY',
    FAILURE_CREATE_DICTIONARY: 'FAILURE_CREATE_DICTIONARY',

    REQUEST_UPDATE_DICTIONARY: 'REQUEST_UPDATE_DICTIONARY',
    SUCCESS_UPDATE_DICTIONARY: 'SUCCESS_UPDATE_DICTIONARY',
    FAILURE_UPDATE_DICTIONARY: 'FAILURE_UPDATE_DICTIONARY',

    REQUEST_DELETE_DICTIONARY: 'REQUEST_DELETE_DICTIONARY',
    SUCCESS_DELETE_DICTIONARY: 'SUCCESS_DELETE_DICTIONARY',
    FAILURE_DELETE_DICTIONARY: 'FAILURE_DELETE_DICTIONARY',

    REQUEST_COUNT_DICTIONARY: 'REQUEST_COUNT_DICTIONARY',
    SUCCESS_COUNT_DICTIONARY: 'SUCCESS_COUNT_DICTIONARY',
    FAILURE_COUNT_DICTIONARY: 'FAILURE_COUNT_DICTIONARY',
}