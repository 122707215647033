import React, {
    useEffect,
    useState,
} from 'react';
import ClampLines from 'react-clamp-lines';
import {
    convertFromRaw,
    convertToRaw,
    EditorState,
} from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const RichEditorExample = props => {

    const [editorState, setEditorState] = useState(
        props.value && Object.keys(props.value).length
            ?
            EditorState.createWithContent(
                convertFromRaw(props.value)
            )
            :
            EditorState.createEmpty());

    useEffect(
        () => {
            if (props.readOnly && props.value && Object.keys(props.value).length) {
                setEditorState(EditorState.createWithContent(
                    convertFromRaw(props.value)
                ));
            }
        },
        [
            props.readOnly,
            props.value
        ]
    )

    const onChange = editorState => {
        if (props.onChange) props.onChange(convertToRaw(editorState.getCurrentContent()));
        setEditorState(editorState);
    };

    return (
        <div>
            {
                props.flag
                    ?
                    <ClampLines
                        text={editorState.getCurrentContent().getPlainText('\u0001')}
                        id='really-unique-id'
                        lines={2}
                        ellipsis='...'
                        buttons={false}
                    />
                    :
                    <div
                        style={{
                            backgroundColor: 'white',
                            padding: 8
                        }}
                    >
                        <Editor
                            toolbarHidden={props.readOnly ? true : false}
                            editorState={editorState}
                            onEditorStateChange={onChange}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            toolbarClassName="toolbar-class"
                            toolbar={
                                {
                                    options: !props.readOnly ? ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'] : [],
                                    inline: { inDropdown: true },
                                    list: { inDropdown: true },
                                    textAlign: { inDropdown: true },
                                    link: { inDropdown: true },
                                    history: { inDropdown: true },
                                }
                            }
                        />
                    </div>
            }

        </div>
    );
};

export default RichEditorExample;