import React from 'react';
import {
    Typography,
    useTheme
} from '@mui/material';
import {
    makeStyles
} from '@mui/styles';
import styled from 'styled-components'
import { keyframes } from "styled-components";

const useStyles = makeStyles(
    theme => (
        {
            root: {
                width: '100%',
                height: '100px',
                color: theme.palette.primary.dark,
                verticalAlign: 'middle',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                // alignItems: 'center',
            },
        }
    )
);

const anime = keyframes`
0%{background-position:0% 40%}
    50%{background-position:100% 61%}
    100%{background-position:0% 40%}
`

const Div = styled.div`
background:  linear-gradient(226deg, #6fae58, #e4f2eb);
    background-size: 100% 100%;
    bottom: 0;
	left: -50%;
	margin-left: auto;
	margin-right: auto;
	overflow-x: hidden;
	right: -50%;
	top: 0;
    animation: ${anime} 2s ease infinite alternate-reverse;
    -webkit-animation: ${anime} 2s ease infinite alternate-reverse;
    -moz-animation: ${anime} 2s ease infinite alternate-reverse;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
`

const BannerForPage = ({
    banner,
    flag, //for divider
    icon, //for icon, must pass the icon
    secondary1,
    secondary2,
    tournament,
    ...props
}) => {
    const classes = useStyles();
    const theme = useTheme();
    //     var spin = keyframes`
    //     0%{background-position:0% 50%}
    //     50%{background-position:100% 50%}
    //     100%{background-position:0% 50%}
    // `;

    //     var styles = {
    //         background: 'linear-gradient(270deg, #448171, #bae4d9)',
    //         backgroundSize: '100% 100%',

    //         // WebkitAnimation: `${spin} 60s ease infinite`,
    //         // MozAnimation: `${spin} 60s ease infinite`,
    //         animation: `${spin} 60s ease infinite`,
    //     };

    return (
        <Div
            // style={styles}
            className={classes.root}
            style={{
                height: tournament && '160px'
            }}
        >
            {/* <Div /> */}
            <Typography
                variant='h4'
                // align='center'
                // gutterBottom
                {...props}
                style={{
                    padding: '8px 8px 8px 16px',
                    // paddingLeft: 8
                }}
            >
                {banner}
                {
                    icon
                    &&
                    <img
                        src={icon}
                        alt={banner}
                        width='20'
                        height='20'
                        style={{
                            margin: '0px 8px'
                        }}
                    />
                }
            </Typography>
            <Typography
                variant='h6'
                style={{
                    padding: '0px 0px 0px 16px',
                    color: theme.palette.secondary.dark
                    // paddingLeft: 8
                }}
                {...props}
            >
                {secondary1}
            </Typography>
            <Typography
                variant='h6'
                style={{
                    padding: '0px 0px 0px 16px',
                    color: theme.palette.secondary.dark
                    // paddingLeft: 8
                }}
                {...props}
            >
                {secondary2}
            </Typography>
        </Div>
    );
}

export default BannerForPage;