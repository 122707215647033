export const actions = {
    REQUEST_FETCH_COUNTERS: 'REQUEST_FETCH_COUNTERS',
    SUCCESS_FETCH_COUNTERS: 'SUCCESS_FETCH_COUNTERS',
    FAILURE_FETCH_COUNTERS: 'FAILURE_FETCH_COUNTERS',

    REQUEST_FETCH_COUNTER_BY_ID: 'REQUEST_FETCH_COUNTER_BY_ID',
    SUCCESS_FETCH_COUNTER_BY_ID: 'SUCCESS_FETCH_COUNTER_BY_ID',
    FAILURE_FETCH_COUNTER_BY_ID: 'FAILURE_FETCH_COUNTER_BY_ID',

    REQUEST_CREATE_COUNTER: 'REQUEST_CREATE_COUNTER',
    SUCCESS_CREATE_COUNTER: 'SUCCESS_CREATE_COUNTER',
    FAILURE_CREATE_COUNTER: 'FAILURE_CREATE_COUNTER',

    REQUEST_UPDATE_COUNTER: 'REQUEST_UPDATE_COUNTER',
    SUCCESS_UPDATE_COUNTER: 'SUCCESS_UPDATE_COUNTER',
    FAILURE_UPDATE_COUNTER: 'FAILURE_UPDATE_COUNTER',

    REQUEST_DELETE_COUNTER: 'REQUEST_DELETE_COUNTER',
    SUCCESS_DELETE_COUNTER: 'SUCCESS_DELETE_COUNTER',
    FAILURE_DELETE_COUNTER: 'FAILURE_DELETE_COUNTER',
}