const alsoSee = [
    {
        title: 'খেলা হবে! ব্লগ',
        obj: [],
        link: '/individualbyslug/blogs',
        flag: 0
    },
    {
        title: 'খেলা হবে! হাইলাইটস',
        obj: [],
        link: '/individualbyslug/highlights',
        flag: 1
    },
    {
        title: 'খেলা হবে! সংবাদ',
        obj: [],
        link: '/individualbyslug/news',
        flag: 0
    }
];

export default alsoSee;