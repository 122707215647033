import {
    createBlog,
    deleteBlog,
    updateBlog,
} from '../../redux/actions/Blogs';
import {
    createCounter,
    deleteCounter,
    updateCounter,
} from '../../redux/actions/Counter';
import {
    createDictionary,
    deleteDictionary,
    updateDictionary,
} from '../../redux/actions/Dictionary';
import {
    createHighlight,
    deleteHighlight,
    updateHighlight,
} from '../../redux/actions/Highlights';
import {
    createNews,
    deleteNews,
    updateNews,
} from '../../redux/actions/News';
import {
    createNewsRequest,
    deleteNewsRequest,
    updateNewsRequest,
} from '../../redux/actions/NewsRequest';
import {
    createNotice,
    deleteNotice,
    updateNotice,
} from '../../redux/actions/Notice';
import {
    createNotification,
    deleteToken,
    updateToken,
} from '../../redux/actions/Notification';
import {
    createOnThisDay,
    deleteOnThisDay,
    updateOnThisDay,
} from '../../redux/actions/OnThisDay';
import {
    createStaticContent,
    deleteStaticContent,
    updateStaticContent,
} from '../../redux/actions/StaticContent';
import {
    createTranslation,
    deleteTranslation,
    updateTranslation
} from '../../redux/actions/Translation';
import {
    getCount,
    getCounterById,
    getBlog,
    getTranslationById,
    getHighlightsCount,
    getNewsById,
    getNoticeById,
    getTranslationCount,
    getNewsCount,
    getHighlight,
    getStaticContentById,
    getOnThisDayById,
    getNewsRequestById,
    getNewsRequestCount,
    getTokenById,
    getDictionaryById,
    getCountDictionary
} from '../../redux/reducers';

const entry = {
    'blog': {
        title: 'খেলা হবে! ব্লগ এন্ট্রি',
        deleteText: 'delete blog',
        data: [
            {
                label: 'সিকোয়েন্স',
                keyval: 'sequence',
                required: true,
            },
            {
                label: 'শিরোনাম',
                keyval: 'title',
                required: true,
            },
            {
                label: 'লেখক',
                keyval: 'writer',
                required: true,
            },
            {
                label: 'ছবি',
                keyval: 'images',
                required: true,
            },
            {
                label: 'ছবি কৃতজ্ঞতা',
                keyval: 'photo_credit',
                required: true,
            },
            {
                label: 'ছবি কৃতজ্ঞতা বিস্তারিত',
                keyval: 'credit_details',
                required: true,
            },
            {
                label: 'ছবি ক্যাপশন',
                keyval: 'photo_caption',
                required: true,
            },
            {
                label: 'প্রকাশের তারিখ',
                keyval: 'published_date',
                required: true,
            },
            {
                label: 'স্লাগ',
                keyval: 'blog_slug',
                required: true,
            },
            {
                label: 'সিরিজ আইডি',
                keyval: 'series_id',
                required: false,
            },
            {
                label: 'ম্যাচ আইডি',
                keyval: 'match_id',
                required: false,
            }
        ],
        emptyState: {
            title: '',
            intro: '{}',
            content: '{}',
            writer: '',
            photo_credit: '',
            photo_caption: '',
            images: [],
            blog_slug: '',
            series_id: '',
            match_id: '',
            published_date: ''
        },
        richText: [
            {
                label: 'ভূমিকা',
                keyval: 'intro',
                required: true,
            },
            {
                label: 'বিষয়বস্তু',
                keyval: 'content',
                required: true,
            },
        ],
        create: createBlog,
        delete: deleteBlog,
        individual: getBlog,
        update: updateBlog,
        count: getCount
    },
    'dictionary': {
        title: 'খেলা হবে! ক্রিকেট অভিধান এন্ট্রি',
        deleteText: 'delete dictionary',
        data: [
            {
                label: 'সিকোয়েন্স',
                keyval: 'sequence',
                required: true,
            },
            {
                label: 'শিরোনাম',
                keyval: 'title',
                required: true,
            },
            {
                label: 'বর্ণনা',
                keyval: 'description',
                required: true,
            },
            {
                label: 'সোর্স',
                keyval: 'source',
                required: true,
            },
            {
                label: 'ছবি ক্যাপশন',
                keyval: 'caption',
                required: true,
            },
            {
                label: 'ছবি',
                keyval: 'image',
                required: true,
            },
            {
                label: 'লাস্ট রিভিউড',
                keyval: 'lastreviewed',
                required: true,
            },
            {
                label: 'স্লাগ',
                keyval: 'slug',
                required: true,
            },
        ],
        emptyState: {
            title: '',
            description: '',
            caption: '',
            source: '',
            image: '',
            slug: '',
            lastreviewed: ''
        },
        // richText: [
        //     {
        //         label: 'বর্ণনা',
        //         keyval: 'description',
        //         required: true,
        //     },
        // ],
        create: createDictionary,
        delete: deleteDictionary,
        individual: getDictionaryById,
        update: updateDictionary,
        count: getCountDictionary
    },
    'highlight': {
        title: 'খেলা হবে! হাইলাইট এন্ট্রি',
        deleteText: 'delete highlight',
        data: [
            {
                label: 'সিকোয়েন্স',
                keyval: 'sequence',
                required: true,
            },
            {
                label: 'শিরোনাম',
                keyval: 'title',
                required: true,
            },
            {
                label: 'স্কোরবোর্ড লিংক',
                keyval: 'scoreboard_url',
                required: true,
            },
            {
                label: 'বর্ণনা',
                keyval: 'description',
                required: true,
            },
            {
                label: 'ইউটিউব আইডি',
                keyval: 'youtube_id',
                required: true,
            },
            {
                label: 'প্রকাশক ও স্বত্ব',
                keyval: 'source',
                required: true,
            },
            {
                label: 'স্লাগ',
                keyval: 'slug',
                required: true,
            },
            {
                label: 'ম্যাচ তারিখ',
                keyval: 'mdate',
                required: true,
            },
            {
                label: 'ট্যাগস',
                keyval: 'tags',
                required: true,
            },
            {
                label: 'পারফর্মার',
                keyval: 'performers',
                required: true,
            }
        ],
        emptyState: {
            squence: 0,
            title: '',
            description: '',
            youtube_id: '',
            scoreboard_url: '',
            source: '',
            slug: '',
            mdate: '',
            performers: [],
            tags: [],
        },
        create: createHighlight,
        delete: deleteHighlight,
        individual: getHighlight,
        update: updateHighlight,
        count: getHighlightsCount
    },
    'news': {
        title: 'খেলা হবে! সংবাদ এন্ট্রি',
        deleteText: 'delete news',
        data: [
            {
                label: 'সিকোয়েন্স',
                keyval: 'sequence',
                required: true,
            },
            {
                label: 'টাইটেল',
                keyval: 'title',
                required: true,
            },
            {
                label: 'সোর্স লিঙ্ক',
                keyval: 'source_url',
                required: true,
            },
            {
                label: 'স্লাগ',
                keyval: 'slug',
                required: true,
            },
            {
                label: 'ট্যাগস',
                keyval: 'tags',
                required: true,
            },
            {
                label: 'ছবি',
                keyval: 'images',
                required: true,
            },
        ],
        emptyState: {
            sequence: 0,
            title: '',
            source_name: '',
            source_url: '',
            summary: '{}',
            tags: [],
            images: [],
        },
        menu: [
            {
                label: 'সোর্স নাম',
                keyval: 'source_name',
                required: true,
                menus: [
                    'বিডিনিউজ২৪.কম',
                    'The Daily Star',
                    'Cricbuzz',
                    'Espncricinfo',
                    'প্রথম আলো',
                    'Cricfrenzy',
                    'প্যাভিলিয়ন.কম.বিডি'
                ]
            }
        ],
        richText: [
            {
                label: 'সার সংক্ষেপ',
                keyval: 'summary',
                required: true,
            },
        ],
        create: createNews,
        delete: deleteNews,
        individual: getNewsById,
        update: updateNews,
        count: getNewsCount
    },
    'news-request': {
        title: 'খেলা হবে! সংবাদ আবেদন এন্ট্রি',
        deleteText: 'delete news request',
        data: [
            {
                label: 'টাইটেল',
                keyval: 'title',
                required: true,
            },
            {
                label: 'সোর্স নাম',
                keyval: 'source',
                required: true,
            },
            {
                label: 'সোর্স লিঙ্ক',
                keyval: 'url',
                required: true,
            },
            {
                label: 'সার সংক্ষেপ',
                keyval: 'summary',
                required: true,
            },
            {
                label: 'অরিজিনাল বক্তব্য',
                keyval: 'original_text',
                required: true,
            },
            {
                label: 'প্রকাশের তারিখ',
                keyval: 'published_date',
                required: true,
            },
            {
                label: 'সম্পর্কিত সংবাদসমূহ',
                keyval: 'related_news',
                required: true,
            },
            {
                label: 'ট্যাগস',
                keyval: 'tags',
                required: true,
            },
        ],
        checked: [
            {
                label: 'রিভিউড?',
                keyval: 'is_reviewed',
                required: true,
            },
        ],
        emptyState: {
            title: '',
            source: '',
            url: '',
            summary: '',
            importance: 0,
            is_reviewed: false,
            original_text: '',
            related_news: [],
            published_date: '',
            tags: []
        },
        radio: [
            {
                label: 'গুরুত্ব',
                keyval: 'importance',
                required: true,
            },
        ],
        create: createNewsRequest,
        delete: deleteNewsRequest,
        individual: getNewsRequestById,
        update: updateNewsRequest,
        count: getNewsRequestCount
    },
    'notification': {
        title: 'খেলা হবে! নোটিফিকেশন এন্ট্রি',
        deleteText: 'delete token',
        data: [
            {
                label: 'টাইটেল',
                keyval: 'title',
                required: true,
            },
            {
                label: 'বিবরণ',
                keyval: 'description',
                required: true,
            },
        ],
        emptyState: {
            title: '',
            description: '',
        },
        create: createNotification,
        delete: deleteToken,
        individual: getTokenById,
        update: updateToken,
        count: () => false
    },
    'on-this-day': {
        title: 'খেলা হবে! এই দিনে এন্ট্রি',
        deleteText: 'delete on this day',
        data: [
            {
                label: 'তারিখ',
                keyval: 'date',
                required: true,
            },
            {
                label: 'খেলোয়াড়গণ',
                keyval: 'players',
                required: true,
            },
            {
                label: 'ছবি',
                keyval: 'image',
                required: true,
            },
            {
                label: 'ছবি কৃতজ্ঞতা',
                keyval: 'photocredit',
                required: true,
            },
            {
                label: 'স্লাগ',
                keyval: 'slug',
                required: true,
            },
            {
                label: 'তথ্যসূত্র',
                keyval: 'source',
                required: true,
            },
            {
                label: 'শেষ রিভিউড',
                keyval: 'lastreviewed',
                required: true,
            },
        ],
        emptyState: {
            slug: '',
            story: '{}',
            date: '',
            players: [],
            image: '',
            photocredit: '',
            lastreviewed: '',
            source: ''
        },
        richText: [
            {
                label: 'ঘটনা',
                keyval: 'story',
                required: true,
            },
        ],
        create: createOnThisDay,
        delete: deleteOnThisDay,
        individual: getOnThisDayById,
        update: updateOnThisDay,
        count: () => false
    },
    'counter': {
        title: 'খেলা হবে! কাউন্টডাউন এন্ট্রি',
        deleteText: 'delete counter',
        data: [
            {
                label: 'দল এক',
                keyval: 'teamone',
                required: true,
            },
            {
                label: 'দল দুই',
                keyval: 'teamtwo',
                required: true,
            },
            {
                label: 'বর্ণনা',
                keyval: 'description',
                required: true,
            },
            {
                label: 'সিকোয়েন্স',
                keyval: 'sequence',
                required: true,
            },
        ],
        date: [
            {
                label: 'তারিখ',
                keyval: 'date',
                required: true,
            },
        ],
        emptyState: {
            teamone: '',
            teamtwo: '',
            date: '',
            description: '',
            sequence: 0,
        },
        create: createCounter,
        delete: deleteCounter,
        individual: getCounterById,
        update: updateCounter,
        count: () => false
    },
    'notice': {
        title: 'খেলা হবে! নোটিশ এন্ট্রি',
        deleteText: 'delete notice',
        data: [
            {
                label: 'নোটিশ',
                keyval: 'notice',
                required: true,
            },
            {
                label: 'সিকোয়েন্স',
                keyval: 'sequence',
                required: true,
            },
        ],
        checked: [
            {
                label: 'সক্রিয়',
                keyval: 'active',
                required: true,
            },
        ],
        emptyState: {
            notice: '',
            active: true,
            sequence: 0,
        },
        create: createNotice,
        delete: deleteNotice,
        individual: getNoticeById,
        update: updateNotice,
        count: () => false
    },
    'static-content': {
        title: 'খেলা হবে! স্ট্যাটিক কন্টেন্ট এন্ট্রি',
        deleteText: 'delete static content',
        data: [
            {
                label: 'টাইটেল',
                keyval: 'title',
                required: true,
            },
            {
                label: 'স্লাগ',
                keyval: 'content_slug',
                required: true,
            }
        ],
        emptyState: {
            title: '',
            content_slug: '',
            static_content: '{}',
        },
        richText: [
            {
                label: 'স্ট্যাটিক কন্টেন্ট',
                keyval: 'static_content',
                required: true,
            },
        ],
        create: createStaticContent,
        delete: deleteStaticContent,
        individual: getStaticContentById,
        update: updateStaticContent,
        count: () => false
    },
    'translation': {
        title: 'খেলা হবে! অনুবাদ এন্ট্রি',
        deleteText: 'delete translation',
        data: [
            {
                label: 'ইংরেজি',
                keyval: 'english',
                required: true,
            },
            {
                label: 'বাংলা',
                keyval: 'bangla',
                required: true,
            }
        ],
        menu: [
            {
                label: 'ধরন',
                keyval: 'type',
                required: true,
                menus: [
                    'word',
                    'phrase',
                ]
            }
        ],
        emptyState: {
            type: '',
            bangla: '',
            english: '',
        },
        create: createTranslation,
        delete: deleteTranslation,
        individual: getTranslationById,
        update: updateTranslation,
        count: getTranslationCount
    }
};

export default entry;