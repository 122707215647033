import React from 'react';
import {
    Typography,
} from '@mui/material';
import {
    makeStyles,
} from '@mui/styles';
import {
    dfs
} from '../Api';
import {
    Months
} from '../assets/data';

const useStyles = makeStyles(
    theme => (
        {
            root: {
                width: '100%',
            },
        }
    )
);

const DateAndTimeExtract = (
    {
        icon,
        time,
        title,
        ...props
    }
) => {
    const classes = useStyles();


    let date = new Date(time);
    let h = '';
    const yyyy = dfs(date.getFullYear());
    let mm = Months[date.getMonth()]; // Months start at 0!
    let dd = dfs(date.getDate());
    let writtenDate = mm + " " + dd + ", " + yyyy;

    if (
        (
            date.getHours() >= 0 && date.getHours() < 3
        )
        ||
        (
            date.getHours() >= 20 && date.getHours() <= 24
        )
    ) {
        h = 'রাত';
    }
    else if (date.getHours() === 0) {
        h = 'রাত';
    }
    else if (date.getHours() < 7 && date.getHours() >= 3) {
        h = 'ভোর';
    }
    else if (date.getHours() > 7 && date.getHours() < 11) {
        h = 'সকাল';
    }
    else if (date.getHours() >= 11 && date.getHours() <= 12) {
        h = 'বেলা';
    }
    else if (date.getHours() >= 12 && date.getHours() <= 15) {
        h = 'দুপুর';
    }
    else if (date.getHours() > 15 && date.getHours() < 18) {
        h = 'বিকাল';
    }
    else if (date.getHours() >= 18 && date.getHours() <= 20) {
        h = 'সন্ধ্যা';
    }

    h += ' ' + (
        dfs(
            date.getHours() > 12
                ?
                (
                    date.getHours() - 12
                )
                :
                (
                    (
                        date.getHours() === 0
                        &&
                        '12'
                    )
                    ||
                    date.getHours()
                )
        )
    ) + '.' + dfs(
        (
            date.getMinutes() > 10
                ?
                date.getMinutes()
                :
                (
                    '0' + date.getMinutes()
                )
        )
    ) + 'টায়';

    writtenDate = writtenDate + " " + h + " ";

    return (
        <Typography
            className={classes.root}
            {...props}
        >
            {title && title}{writtenDate}
        </Typography>
    );
};

export default DateAndTimeExtract;
