import { lazy } from 'react';
const CommentaryEntry = lazy(() => import('../../components/commentaryEntry'));
const Entry = lazy(() => import('../../components/entryById'));
const ForgetPassword = lazy(() => import('../../components/forgetPassword'));
const Home = lazy(() => import('../../components/home'));
const Individual = lazy(() => import('../../components/individual'));
const LiveScore = lazy(() => import('../../components/liveScore'));
const Login = lazy(() => import('../../components/loginRegister'));
const Ranking = lazy(() => import('../../components/ranking'));
const Register = lazy(() => import('../../components/loginRegister/Register'));
const ScoreboardPage = lazy(() => import('../../components/scoreboard'));
// const Tournament = lazy(() => import('../../components/tournament'));
const StaticContents = lazy(() => import('../../components/staticContent'));
const ResetPassword = lazy(() => import('../../components/resetPassword'));
const Table = lazy(() => import('../../components/table'));
const UserProfile = lazy(() => import('../../components/userProfile'));
const Tournament = lazy(() => import('../../components/tournament'));
const TournamentAllMatches = lazy(() => import('../../components/tournamentMatchesList'));
const PlayerProfile = lazy(() => import('../../components/playerProfile'));
// import BPL from '../../components/BPL';
// import BPLProfile from '../../components/BPLTeam/index.jsx';
// import Player from '../../components/PlayerProfile/index';
//import CricketRecord from '../../components/Record';
//import RecordTable from '../../components/Record/RecordTable/finalRecordTable';
// import HallOfFame from '../../components/hallOfFame/index';
// import PlayerProfile from '../../components/PlayerProfile/index';
// import WorldCup from '../../components/worldcup2019';

const NONE = 0;
const PUBLIC = 1;
const ADMIN = 2;

const routes = [
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/',
        exact: true,
        component: Home
    },
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/table/:slug/:pages',
        exact: true,
        component: Table
    },
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/forget-password',
        component: ForgetPassword
    },
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/reset-password',
        component: ResetPassword
    },
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/table/:slug/:pages/:tag',
        exact: true,
        component: Table
    },
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/individualbyid/:topic/:id',
        exact: true,
        component: Individual
    },
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/individualbyslug/:topic/:slug',
        exact: true,
        component: Individual
    },
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/tournament/:slug',
        exact: true,
        component: Tournament
    },
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/tournament/allmatches/:slug',
        exact: true,
        component: TournamentAllMatches
    },
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/footer/:slug',
        exact: true,
        component: StaticContents
    },
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/series/:slug',
        exact: true,
        component: Tournament
    },
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/live-scoreboard/:type',
        exact: true,
        component: LiveScore
    },
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/scoreboard/:slug',
        exact: true,
        component: '',
        to: ({ slug }) => `${slug}/summary`,
        replace: true
    },
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/scoreboard/:slug/:type',
        exact: true,
        component: ScoreboardPage
    },
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/ranking/:sex',
        exact: true,
        component: '',
        to: ({ sex }) => `${sex}/batting/odi`,
        replace: true
    },
    {
        authLevel: NONE,
        max: ADMIN,
        exact: true,
        path: '/ranking/:sex/:discipline/:category',
        component: Ranking,
    },
    // {
    //     authLevel: PUBLIC,
    //     max: ADMIN,
    //     path: '/login',
    //     exact: true,
    //     component: '',
    //     to: () => '/',
    //     replace: true
    // },
    {
        authLevel: NONE,
        max: NONE,
        path: '/login',
        exact: true,
        component: Login
    },
    {
        authLevel: PUBLIC,
        max: ADMIN,
        path: '/userprofile',
        exact: true,
        component: UserProfile
    },
    {
        authLevel: NONE,
        max: NONE,
        path: '/register',
        exact: true,
        component: Register
    },
    {
        authLevel: ADMIN,
        max: ADMIN,
        path: '/commentary-entry/:eventid/:slug',
        exact: true,
        component: CommentaryEntry
    },
    {
        authLevel: ADMIN,
        max: ADMIN,
        path: '/entry/:slug',
        exact: true,
        component: Entry
    },
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/playerprofile/:slug',
        exact: true,
        component: PlayerProfile
    },
];

export default routes;
