import {
    getIsFetchingNews,
    getToken
} from '../reducers';
import {
    endPoints
} from '../../Api';
import {actions} from "../action-types/news";

export const fetchNews = () => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_FETCH_NEWS,
            actions.SUCCESS_FETCH_NEWS,
            actions.FAILURE_FETCH_NEWS
        ],
        method: endPoints.FETCH_NEWS,
        race: () => false,
    }
});

export const fetchNewsById = id => ({
    RSAA: {
        types: [
            actions.REQUEST_FETCH_NEWS_BY_ID,
            actions.SUCCESS_FETCH_NEWS_BY_ID,
            actions.FAILURE_FETCH_NEWS_BY_ID,
        ],
        method: endPoints.FETCH_NEWS_BY_ID,
        race: () => false,
        data: {
            id,
        },
    },
});

export const fetchNewsBySlug = slug => ({
    RSAA: {
        types: [
            actions.REQUEST_FETCH_NEWS_BY_SLUG,
            actions.SUCCESS_FETCH_NEWS_BY_SLUG,
            actions.FAILURE_FETCH_NEWS_BY_SLUG,
        ],
        method: endPoints.FETCH_NEWS_BY_SLUG,
        race: () => false,
        data: {
            slug,
        },
    },
});

export const createNews = state => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_CREATE_NEWS,
            actions.SUCCESS_CREATE_NEWS,
            actions.FAILURE_CREATE_NEWS
        ],
        method: endPoints.CREATE_NEWS,
        race: getIsFetchingNews,
        data: {
            ...state,
            images: [state.images],
            sequence: parseInt(state.sequence),
            tags: state.tags.split(','),
            token: getToken(getState())
        }
    }
});

export const updateNews = state => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_UPDATE_NEWS,
            actions.SUCCESS_UPDATE_NEWS,
            actions.FAILURE_UPDATE_NEWS
        ],
        method: endPoints.UPDATE_NEWS,
        race: getIsFetchingNews,
        data: {
            ...state,
            images: [state.images],
            sequence: parseInt(state.sequence),
            tags: state.tags.split(','),
            token: getToken(getState()),
            id: state.id
        }
    }
});

export const deleteNews = id => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_DELETE_NEWS,
            actions.SUCCESS_DELETE_NEWS,
            actions.FAILURE_DELETE_NEWS
        ],
        method: endPoints.DELETE_NEWS,
        race: getIsFetchingNews,
        data: {
            id,
            token: getToken(getState()),
        }
    }
});

export const countNews = () => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_COUNT_NEWS,
            actions.SUCCESS_COUNT_NEWS,
            actions.FAILURE_COUNT_NEWS,
        ],
        method: endPoints.COUNT_NEWS,
        race: () => false,
    },
});