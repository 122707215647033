import React from 'react';
import clsx from 'clsx';
import {
    Chip,
    Grid,
} from '@mui/material';
import {
    makeStyles,
} from '@mui/styles';
import PropTypes from 'prop-types';
import {
    dfs
} from '../Api';

const useStyles = makeStyles(
    theme => (
        {
            avatar1: {
                padding: theme.spacing(1),
                verticalAlign: 'baseline',
                [theme.breakpoints.down('md')]: {
                    padding: 0,
                    margin: 0,
                },
                '& .MuiChip-root': {
                    borderRadius: '9px',
                    width: 'auto',
                    minWidth: 'fit-content'
                    // textOverflow: 'clip'
                },
                '& .MuiChip-label': {
                    fontWeight: 600,
                    overflow: 'visible'
                }
            },
            six: {
                '& .MuiChip-root': {
                    backgroundColor: theme.palette.primary.dark,
                    color: 'white'
                }
            },
            out: {
                '& .MuiChip-root': {
                    backgroundColor: '#bf360c',
                    color: 'white'
                }
            },
            four: {
                '& .MuiChip-root': {
                    backgroundColor: '#a5d6a7',
                    color: theme.palette.primary.dark
                }
            },
            noball: {
                '& .MuiChip-root': {
                    backgroundColor: '#0d47a1',
                    color: '#e3f2fd'
                }
            },
            freehit: {
                '& .MuiChip-root': {
                    backgroundColor: '#827717',
                    color: '#f9fbe7'
                }
            },
            bye: {
                '& .MuiChip-root': {
                    backgroundColor: '#311b92',
                    color: '#ede7f6'
                }
            },
            legbye: {
                '& .MuiChip-root': {
                    backgroundColor: '#3e2723',
                    color: '#efebe9'
                }
            },
            wide: {
                '& .MuiChip-root': {
                    backgroundColor: '#1a237e',
                    color: '#e8eaf6'
                }
            },
            penalty: {
                '& .MuiChip-root': {
                    backgroundColor: '#e65100',
                    color: '#fff3e0'
                }
            },
            zero: {
                '& .MuiChip-root': {
                    color: '#004d80',
                    backgroundColor: '#e6f5ff',
                }
            },
            one: {
                '& .MuiChip-root': {
                    color: '#004d80',
                    backgroundColor: '#ccebff',
                }
            },
            two: {
                '& .MuiChip-root': {
                    color: '#004d80',
                    backgroundColor: '#b3e0ff',
                }
            },
            three: {
                '& .MuiChip-root': {
                    color: '#004d80',
                    backgroundColor: '#99d6ff',
                }
            },
            five: {
                '& .MuiChip-root': {
                    color: '#004d80',
                    backgroundColor: '#80ccff',
                }
            },
        }
    )
);

const Commentary = ({
    four,
    six,
    wide,
    freehit,
    noball,
    penalty,
    bye,
    out,
    legbye,
    run,
}) => {
    const classes = useStyles();

    return (
        <>
            {
                four
                &&
                <Grid
                    item
                    xs={2}
                    md={1}
                    className={
                        clsx(
                            classes.avatar1,
                            {
                                [classes.four]: four,
                            }
                        )
                    }
                >
                    <Chip label={`চার`} />
                </Grid>
            }
            {
                six
                &&
                <Grid
                    item
                    xs={2}
                    md={1}
                    className={
                        clsx(
                            classes.avatar1,
                            {
                                [classes.six]: six,
                            }
                        )
                    }
                >
                    <Chip label={`ছয়`} />
                </Grid>
            }
            {
                wide
                &&
                <Grid
                    item
                    xs={2}
                    md={1}
                    className={
                        clsx(
                            classes.avatar1,
                            {
                                [classes.wide]: wide,
                            }
                        )
                    }
                >
                    <Chip label={`ওয়াইড`} />
                </Grid>
            }
            {
                freehit
                &&
                <Grid
                    item
                    xs={2}
                    md={1}
                    className={
                        clsx(
                            classes.avatar1,
                            {
                                [classes.freehit]: freehit,
                            }
                        )
                    }
                >
                    <Chip label={`ফ্রিহিট`} />
                </Grid>
            }
            {
                noball
                &&
                <Grid
                    item
                    xs={2}
                    md={1}
                    className={
                        clsx(
                            classes.avatar1,
                            {
                                [classes.noball]: noball,
                            }
                        )
                    }
                >
                    <Chip label={`নোবল`} />
                </Grid>
            }
            {
                penalty
                &&
                <Grid
                    item
                    xs={2}
                    md={1}
                    className={
                        clsx(
                            classes.avatar1,
                            {
                                [classes.penalty]: penalty,
                            }
                        )
                    }
                >
                    <Chip label={`পেনাল্টি`} />
                </Grid>
            }
            {
                bye
                &&
                <Grid
                    item
                    xs={2}
                    md={1}
                    className={
                        clsx(
                            classes.avatar1,
                            {
                                [classes.bye]: bye,
                            }
                        )
                    }
                >
                    <Chip label={`বাই`} />
                </Grid>
            }
            {
                out
                &&
                <Grid
                    item
                    xs={2}
                    md={1}
                    className={
                        clsx(
                            classes.avatar1,
                            {
                                [classes.out]: out,
                            }
                        )
                    }
                >
                    <Chip label={`আউট`} />
                </Grid>
            }
            {
                legbye
                &&
                <Grid
                    item
                    xs={2}
                    md={1}
                    className={
                        clsx(classes.avatar1, classes.legbye)
                    }
                >
                    <Chip label={`লেগবাই`} />
                </Grid>
            }
            {
                ((!six && !four && !out) || (out && run !== 0))
                &&
                <Grid
                    item
                    xs={2}
                    md={1}
                    className={
                        clsx(
                            classes.avatar1,
                            {
                                [classes.zero]: run === 0,
                                [classes.one]: run === 1,
                                [classes.two]: run === 2,
                                [classes.three]: run === 3,
                                [classes.five]: run === 5,
                            }
                        )
                    }
                >
                    <Chip label={dfs(run)} />
                </Grid>
            }
        </>
    )
};

Commentary.propTypes = {
    out: PropTypes.bool.isRequired,
    six: PropTypes.bool.isRequired,
    four: PropTypes.bool.isRequired,
    freehit: PropTypes.bool.isRequired,
    penalty: PropTypes.bool.isRequired,
    noball: PropTypes.bool.isRequired,
    wide: PropTypes.bool.isRequired,
};

export default Commentary;
