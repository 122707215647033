import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const fakeRankDB = {
    player: {
        key: [
            'country',
            'fullName',
            'goneUp',
            'position',
            'ratingPoint',
        ],
    },
    team: {
        key: [
            {
                label: 'position',
                text: 'অবস্থান',
            },
            {
                label: 'country',
                text: 'দল',
            },
            {
                label: 'weightedmatch',
                text: 'ম্যাচ',
            },
            {
                label: 'ratingPoint',
                text: 'র‌্যাংকিং',
            },
            {
                label: 'point',
                text: 'পয়েন্ট',
            },
        ],
    },
};

export const translate = s => [...s].map(c => numberMapping.hasOwnProperty(c) ? numberMapping[c] : c).join('');

const exceptions = [
    'start_time',
    'batting_order',
    'bowling_order',
    'scoreboards',
    'ID',
    'id',
    'left',
    'right',
    'eventid',
    'comment',
    'short_text',
    'matchType',
    'matchnumber',
    'sequence',
    'created_at',
    'updated_at',
    'deleted_at',
    'seriesid',
    'over',
    'ball',
    'run',
    'day',
    'session',
    'slug'
];

export const dfs = obj => {
    if (obj === null || obj === undefined) return obj;
    if (typeof obj === 'string') return translate(obj);
    if (typeof obj === 'number') return translate((Math.round(obj * 100) / 100).toString(10));
    if (Array.isArray(obj)) return obj.map(e => dfs(e));
    if (typeof obj === 'object') return Object.getOwnPropertyNames(obj).map(
        k => exceptions.indexOf(k) === -1 ? [k, dfs(obj[k])] : [k, obj[k]]
    ).reduce(
        (prev, cur) => {
            prev[cur[0]] = cur[1];
            return prev;
        }, {});
    return obj;
};

const processWicketText = ({
    typeofout,
    peopleinvolved,
    ...rest
}) => ({
    ...rest,
    wicketText: ((t, p) => {
        if (rest.notout) return '';
        if (peopleinvolved[0] === '' && peopleinvolved[1] === '') return '';
        if (t === 'bowled') return `বোল্ড ${p[1] || ''}`;
        if (t === 'run out') return `রান আউট ${p[0] || p[1] || ''}`;
        if (t === 'leg before wicket' || t === 'lbw') return `এলবিডব্লিউ ${p[1] || ''}`;
        if (t === 'hit wicket') return `হিট আউট ${p[0] || p[1] || ''}`;
        if (t === 'আহত অবসর' || t === 'retired not out') return 'আহত অবসর';
        let w = 'কট';
        if (t === 'stumped') w = 'স্টাম্পড';
        if (p[0] === p[1]) return `${w} ও বোল্ড ${p[0] || ''}`;
        return `${w} ${p[0] || ''} বোল্ড ${p[1] || ''}`;
    })(typeofout, peopleinvolved),
})

const processBatsmen = ({
    balls,
    fours,
    minutes,
    name,
    notout,
    peopleinvolved,
    runs,
    sixes,
    strikerate,
    typeofout,
    inningsid,
}) => ({
    name: name || '',
    inningsid: inningsid || '',
    runs: runs || '0',
    balls: balls || '0',
    fours: fours || '0',
    sixes: sixes || '0',
    notout: notout,
    typeofout: typeofout || '',
    peopleinvolved: peopleinvolved || [],
    sr: strikerate || '0.00',
    minutes: minutes || '',
});

const processBowler = ({
    dots,
    economy,
    fourshit,
    maidens,
    name,
    noballs,
    overbowled,
    runsgiven,
    sixeshit,
    wicketstaken,
    wides,
}) => ({
    name: name || '',
    over: overbowled || '0',
    maidens: maidens || '0',
    runs: runsgiven || '0',
    wides: wides || '0',
    noballs: noballs || '0',
    wickets: wicketstaken || '0',
    fours: fourshit || '0',
    sixes: sixeshit || '0',
    dots: dots || '0',
    economy: economy || '0',
});

const processPartnership = ({
    active,
    ball,
    first,
    firstcontribute,
    runs,
    second,
    secondconntribute: secondcontribute,
}) => ({
    firstBatsman: first || '',
    secondBatsman: second || '',
    firstContribution: firstcontribute || '0',
    secondContribution: secondcontribute || '0',
    runs: runs || '0',
    ball: ball || '0',
    active: active || false,
    left: Math.min((Math.pow(firstcontribute || 0, 1.0 / 2) / 158.113883008) * 1000, 100),
    right: Math.min((Math.pow(secondcontribute || 0, 1.0 / 2) / 158.113883008) * 1000, 100),
});

const processInnings = ({
    battinginnings: {
        batting,
    },
    bowlinginnings: {
        bowlinginnings: bowling,
    },
    bestbatsman,
    bestbowler,
    currentbatsman,
    currentbowler,
    day,
    didnotbat,
    diff,
    endofover,
    extras,
    first,
    fow,
    inningstotal,
    leadby,
    name,
    out,
    over,
    partnership,
    remainingball,
    remainingrun,
    requiredrunrate,
    result,
    runrate,
    scoreslevel,
    session,
    test,
    towin,
    trailby,
    running_innings
}) => ({
    day: day || 0,
    diff: diff || 0,
    endofover: endofover || '',
    first: first || false,
    leadby: leadby || false,
    scoreslevel: scoreslevel || false,
    session: session || 0,
    test: test || false,
    towin: towin || false,
    trailby: trailby || false,
    name: name || '',
    inningstotal: inningstotal,
    result: result || '',
    batting: Array.isArray(batting) ? batting.map(b => processWicketText(processBatsmen(b))) : [],
    bowling: bowling ? bowling.map(b => processBowler(b)) : [],
    didnotbat: didnotbat || [],
    extras: typeof extras === 'object' ? {
        lb: extras.legbyes || 0,
        b: extras.byes || 0,
        nb: extras.noballs || 0,
        w: extras.wides || 0,
        total: extras.total || 0,
    } : {},
    fow: fow ? fow.map(e => ({
        name: e.name,
        over: e.over,
        run: e.run,
        wicket: e.wicketNumber,
    })) : [],
    out: out,
    over,
    remainingball: remainingball || 0,
    remainingrun: remainingrun || 0,
    requiredrunrate: requiredrunrate || 0,
    runrate: runrate || 0,
    running_innings,
    bestbatsman: Array.isArray(bestbatsman) ? bestbatsman.map(b => processBatsmen(b)) : [],
    bestbowler: Array.isArray(bestbowler) ? bestbowler.map(b => processBowler(b)) : [],
    currentbatsman: Array.isArray(currentbatsman) ? currentbatsman.map(b => processBatsmen(b)) : [],
    currentbowler: Array.isArray(currentbowler) ? currentbowler.map(b => processBowler(b)) : [],
    partnerships: Array.isArray(partnership) ? partnership.map(p => processPartnership(p)) : [],
});

const processMatch = ({
    days,
    matchnotes,
    matchnumber,
    matchstate,
    matchstatus,
    matchtype,
    playermatch,
    reserve,
    result,
    scoreboards,
    season,
    series,
    stadium,
    team1players,
    team2players,
    teamone,
    teamtwo,
    toss,
    tvumpires,
    umpires,
    seriesid,
    eventid,
    winning_team,
    start_time,
    team1captain,
    team2captain,
    // scoreboards
}) => ({
    days: days || '',
    matchNotes: matchnotes || [],
    matchnumber: matchnumber || 0,
    matchStatus: matchstatus || '',
    matchState: matchstate || '',
    matchType: matchtype,
    manOfTheMatch: playermatch || '',
    reserve: reserve || [],
    result: result || '',
    scoreboards: scoreboards || '',
    season: season || '',
    series: series || '',
    stadium: stadium || '',
    teams: [
        {
            name: teamone || '',
            players: team1players || [],
            captain: team1captain
        },
        {
            name: teamtwo || '',
            players: team2players || [],
            captain: team2captain
        },
    ],
    toss: toss || '',
    tvUmpires: tvumpires || [],
    umpires: umpires || [],
    seriesid: seriesid || '',
    eventid: eventid || '',
    winning_team: winning_team || '',
    start_time,
    // scoreboards: scoreboards || []
});

const processPlayerRecord = response => {
    const {
        data: {
            data: {
                batting,
                bowling,
            },
        },
    } = response;

    const parse = (k, t, data) => {
        const q = data[k][t];
        if (typeof q === 'number') {
            return q < 0 ? '-' : q;
        }
        if (/^-1/.test(q)) {
            return '-';
        }
        if (/[*/]/.test(q)) return q;
        return Math.round(Number(q) * 100) / 100;
    }

    const nBatting = {
        key: ['', ...Object.keys(batting[Object.keys(batting)[0]]).map(k => BackendMapping.batting[k])],
        data: [
            ...Object.keys(batting).map(
                k => [BackendMapping.matchTypes[k], ...Object.keys(batting[k]).map(
                    t => parse(k, t, batting)
                ),]),
        ],
    };

    const nBowling = {
        key: ['', ...Object.keys(bowling[Object.keys(bowling)[0]]).map(k => BackendMapping.bowling[k])],
        data: [
            ...Object.keys(bowling).map(
                k => [BackendMapping.matchTypes[k], ...Object.keys(bowling[k]).map(
                    t => parse(k, t, bowling)
                ),]),
        ],
    };

    return {
        batting: {
            ...nBatting,
        },
        bowling: {
            ...nBowling,
        },
    };
};

const processTeamRank = response => {
    const {
        key
    } = fakeRankDB.team;

    return response.map(e => key.reduce((prev, cur) => ({
        ...prev,
        [cur.label]: e[cur.label],
    }), {}));
};

const processPlayerRank = response => {
    const { data } = response;
    return data.map(e => fakeRankDB.player.key.reduce(
        (prev, cur) => ({
            ...prev,
            [cur]: e[cur],
        }), {})
    );
};

const fetchInnings = ({ slug, inningsNo }) => axiosGet(apiUrls.FETCH_INNINGS, {}, slug, inningsNo).then(
    response => processInnings(response.data),
    err => Promise.reject(() => ({
        message: err,
    }))
);

const fetchMatch = ({ slug }) => axiosGet(apiUrls.FETCH_MATCH, {}, slug).then(
    response => processMatch(response.data),
    err => Promise.reject(() => ({
        message: err,
    }))
);

const fetchMatchWithInnings = ({ slug }) => axiosGet(apiUrls.FETCH_MATCH_WITH_INNINGS, {}, slug).then(
    response => dfs(processMatch(response.data)),
    err => Promise.reject(() => ({
        message: err,
    }))
);

const fetchBDMatch = () => axiosGet(apiUrls.FETCH_BD_MATCH, {}).then(
    response => response.data,
    err => null
    //why is error null here? 
);

const fetchPlayerTableData = ({ slug }) => axiosGet(apiUrls.FETCH_PLAYER_TABLE_DATA, {}, slug).then(
    response => dfs(processPlayerRecord(response)),
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const fetchLiveScores = () => axiosGet(apiUrls.FETCH_LIVESCORES, {}).then(
    response => response, //different from other, check out the reducer for better understanding
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const fetchLiveScoresTest = () => axiosGet(apiUrls.FETCH_LIVESCORES_TEST, {}).then(
    response => response, //different from other, check out the reducer for better understanding
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const fetchLocalMatches = () => axiosGet(apiUrls.FETCH_LOCAL_MATCHES, {}).then(
    response => response, //different from other, check out the reducer for better understanding
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const fetchInternationalMatches = () => axiosGet(apiUrls.FETCH_INTERNATIONAL_MATCHES, {}).then(
    response => response, //different from other, check out the reducer for better understanding
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const fetchUpcoming = () => axiosGet(apiUrls.FETCH_UPCOMING, {}).then(
    response => response, //different from other, check out the reducer for better understanding
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const fetchFininshed = () => axiosGet(apiUrls.FETCH_FINISHED, {}).then(
    response => response, //different from other, check out the reducer for better understanding
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const fetchLive = () => axiosGet(apiUrls.FETCH_LIVE, {}).then(
    response => response, //different from other, check out the reducer for better understanding
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const fetchLiveScoresRecentFinished = ({ skip, limit }) => axiosGet(apiUrls.FETCH_LIVESCORES_RECENT_FINISHED, {}, skip, limit).then(
    response => response, //different from other, check out the reducer for better understanding
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const processLogin = ({
    token
}) => ({
    token: token || null
});

const processID = ({
    user_id
}) => ({
    id: user_id
});

const processProfile = ({
    user_name,
    name,
    email,
    phone,
    address,
    date_of_birth,
    isadmin
}) => ({
    userName: user_name,
    name,
    email,
    phone,
    address,
    date_of_birth,
    isadmin
});

const processCounter = ({
    teamone,
    teamtwo,
    date,
    sequence,
    description,
    id
}) => ({
    teamone,
    teamtwo,
    date,
    sequence,
    description,
    id
});

const processNotice = ({
    notice,
    active,
    id
}) => ({
    notice,
    active,
    id
});

const processCommentaryById = ({
    id,
    sequence,
    eventid,
    seriesid,
    over,
    ball,
    comment,
    short_text,
    run,
    isball,
    missed_shot,
    bowler,
    batsman,
    line,
    length,
    shot,
    ball_type,
    impact,
    speed,
    six,
    four,
    out,
    noball,
    wide,
    open,
    batting_order,
    bowling_order
}) => ({
    id,
    sequence,
    eventid,
    seriesid,
    over,
    ball,
    comment,
    short_text,
    run,
    isball,
    missed_shot,
    bowler,
    batsman,
    line,
    length,
    shot,
    ball_type,
    impact,
    speed,
    six,
    four,
    out,
    noball,
    wide,
    open,
    batting_order,
    bowling_order
});

const processNewsById = ({
    title,
    source_name,
    source_url,
    summary,
    tags,
    images,
    sequence,
    slug,
    id
}) => ({
    title,
    source_name,
    source_url,
    summary,
    tags,
    images,
    sequence,
    slug,
    id
});

const processNewsRequestById = ({
    id,
    title,
    url,
    source,
    summary,
    tags,
    importance,
    is_reviewed,
    original_text,
    related_news,
    published_date,
    slug
}) => ({
    id,
    title,
    url,
    source,
    summary_text: summary,
    tags,
    importance,
    is_reviewed,
    original_text,
    related_news,
    published_date,
    slug
});

const processBlogById = ({
    created_at,
    id,
    title,
    intro,
    content,
    writer,
    photo_credit,
    images,
    published_date,
    photo_caption,
    blog_slug,
    series_id,
    match_id,
    sequence,
}) => ({
    id,
    title,
    intro,
    content,
    writer,
    photo_credit,
    images,
    published_date,
    photo_caption,
    series_id,
    match_id,
    sequence,
    slug: blog_slug,
    created_at
});

const processDictionaryById = ({
    id,
    title,
    description,
    photocredit,
    image,
    caption,
    slug,
    lastreviewed,
    source,
    sequence,
}) => ({
    id,
    title,
    description,
    photocredit,
    image,
    caption,
    slug,
    lastreviewed,
    source,
    sequence,
});

const processStaticContentById = ({
    title,
    static_content,
    content_slug,
    id
}) => ({
    title,
    static_content,
    content_slug,
    id
});

const processTranslationById = ({
    type,
    bangla,
    english,
    id
}) => ({
    type,
    bangla,
    english,
    id
});

const processHighlightById = ({
    id,
    title,
    description,
    youtube_id,
    source,
    slug,
    mdate,
    scoreboard_url,
    tags,
    performers,
}) => ({
    id,
    title,
    description,
    youtube_id,
    source,
    slug,
    mdate,
    scoreboard_url,
    tags,
    performers,
});

const processTokenById = ({
    device_token
}) => ({
    device_token
});

const processHighlightBySlug = ({
    id,
    title,
    description,
    youtube_id,
    source,
    slug,
    mdate,
    scoreboard_url,
    tags,
    performers,
}) => ({
    id,
    title,
    description,
    youtube_id,
    source,
    slug,
    mdate,
    scoreboard_url,
    tags,
    performers,
});

const processCounters = ({
    all_counter
}) => all_counter;

const processNotices = ({
    all_notice
}) => all_notice;

const processBlogs = ({
    all_blog
}) => all_blog;

const processDictionary = ({
    dictionary_all
}) => dictionary_all;

const processTokens = ({
    all_token
}) => all_token;

const processOnThisDay = ({
    onthisday_all
}) => onthisday_all;

const processOnThisDayById = ({
    story,
    date,
    image,
    players,
    photocredit,
    id,
    slug,
    source,
    lastreviewed
}) => ({
    story,
    date,
    image,
    players,
    photocredit,
    id,
    slug,
    source,
    lastreviewed
});

const processTournamentById = ({
    completed_match,
    full_date,
    id,
    slug,
    total_match,
    tournament_highest_innings_score,
    tournament_margininnings_defeat,
    tournament_mostrun,
    tournament_mostrun_innings,
    tournament_mostscored_match,
    tournament_mostwicket,
    tournament_mostwicket_innings,
    tournament_results,
    tournament_teams,
    tournament_title,
    tournament_winpercentage,
    tournament_schedule,
    tournament_groups,
    updated_at
}) => ({
    completed_match: completed_match || 0,
    full_date,
    id,
    slug,
    total_match: total_match || '',
    tournament_highest_innings_score: tournament_highest_innings_score || {},
    tournament_margininnings_defeat: tournament_margininnings_defeat || {},
    tournament_mostrun: tournament_mostrun || {},
    tournament_mostrun_innings: tournament_mostrun_innings || {},
    tournament_mostscored_match: tournament_mostscored_match || {},
    tournament_mostwicket: tournament_mostwicket || {},
    tournament_mostwicket_innings: tournament_mostwicket_innings || {},
    tournament_results: tournament_results || [],
    tournament_teams: tournament_teams || [],
    tournament_title,
    tournament_winpercentage: tournament_winpercentage || {},
    tournament_schedule: tournament_schedule || [],
    tournament_groups: tournament_groups || [],
    updated_at
});

const processAllOnThisDay = ({
    onthisday_all
}) => onthisday_all;

const processHighlights = ({
    all_videos
}) => all_videos;

const processHighlightsByTags = ({
    all_videos
}) => all_videos;

const processNews = ({
    all_news
}) => all_news;

const processTranslation = ({
    all_translation
}) => all_translation;

const processNewsRequests = ({
    all_news
}) => all_news;

const processStaticContents = ({
    all_static_content
}) => all_static_content;

const processCommentaries = ({
    all_commentaries
}) => all_commentaries;

const processCountBlog = ({
    count
}) => count;

const processCountDictionary = ({
    count
}) => count;

const processCountHighlights = ({
    count
}) => count;

const processCountTranslation = ({
    count
}) => count;

const processCountNews = ({
    count
}) => count;

const processCountNewsRequests = ({
    count
}) => count;

const login = data => axiosPost(apiUrls.LOGIN, data).then(
    response => processLogin(response.data)
).catch(error => Promise.reject(error || 'something bad happened'));

const fetchID = ({ token }) => axiosGet(apiUrls.FETCH_ID, {
    Authorization: token,
}, token).then(
    response => processID(response.data)
).catch(error => Promise.reject(error || 'something bad happened'));

const register = data => axiosPost(apiUrls.REGISTER, data, {})
    .then(
        response => response.data
    )
    .catch(
        error => error || 'something bad happened'
    );

const fetchBlogs = ({
    skip, limit
}) => axiosGet(apiUrls.FETCH_BLOGS, {}, skip, limit).then(
    response => processBlogs(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }))
    }
);

const fetchDictionary = ({
    skip, limit
}) => axiosGet(apiUrls.FETCH_DICTIONARY, {}, skip, limit).then(
    response => processDictionary(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }))
    }
);

const fetchTokens = ({
    skip, limit, token
}) => axiosGet(apiUrls.FETCH_TOKENS, {
    Authorization: token,
}, skip, limit).then(
    response => processTokens(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }))
    }
);

const fetchCounters = ({
    skip, limit
}) => axiosGet(apiUrls.FETCH_COUNTERS, {}, skip, limit).then(
    response => processCounters(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }))
    }
);

const fetchNotices = ({
    skip, limit
}) => axiosGet(apiUrls.FETCH_NOTICES, {}, skip, limit).then(
    response => processNotices(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }))
    }
);

const fetchNewsRequests = ({
    skip, limit
}) => axiosGet(apiUrls.FETCH_NEWS_REQUESTS, {}, skip, limit).then(
    response => processNewsRequests(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }))
    }
);

const fetchNews = () => axiosGet(apiUrls.FETCH_NEWS, {}).then(
    response => processNews(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }))
    }
);

const fetchTranslation = ({ skip, limit }) => axiosGet(apiUrls.FETCH_TRANSLATION, {}, skip, limit).then(
    response => processTranslation(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }))
    }
);

const fetchOnThisDay = ({ onthisday }) => axiosGet(apiUrls.FETCH_ON_THIS_DAY, {}, onthisday).then(
    response => processOnThisDay(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }))
    }
);

const fetchOnThisDayById = ({ id }) => axiosGet(apiUrls.FETCH_ON_THIS_DAY_BY_ID, {}, id).then(
    response => processOnThisDayById(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }))
    }
);

const fetchTournamentById = ({ id }) => axiosGet(apiUrls.FETCH_TOURNAMENT_BY_ID, {}, id).then(
    response => processTournamentById(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }))
    }
);

const fetchTournamentBySlug = ({ slug }) => axiosGet(apiUrls.FETCH_TOURNAMENT_BY_SLUG, {}, slug).then(
    response => processTournamentById(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }))
    }
);

const fetchOnThisDayBySlug = ({ slug }) => axiosGet(apiUrls.FETCH_ON_THIS_DAY_BY_SLUG, {}, slug).then(
    response => processOnThisDayById(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }))
    }
);

const fetchAllOnThisDay = () => axiosGet(apiUrls.FETCH_ALL_ON_THIS_DAY, {}).then(
    response => processAllOnThisDay(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }))
    }
);

const fetchStaticContents = () => axiosGet(apiUrls.FETCH_STATIC_CONTENTS, {}).then(
    response => processStaticContents(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }))
    }
);

const fetchHighlights = ({
    skip, limit
}) => axiosGet(apiUrls.FETCH_HIGHLIGHTS, {}, skip, limit).then(
    response => processHighlights(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }))
    }
);

const fetchHighlightsByTags = data => axiosPost(apiUrls.FETCH_HIGHLIGHTS_BY_TAGS, data).then(
    response => processHighlightsByTags(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }))
    }
);

const fetchCommentaries = ({
    skip, limit, ...data
}) => axiosPost(apiUrls.FETCH_COMMENTARIES, data, {}, skip, limit).then(
    response => dfs(processCommentaries(response.data)),
    err => {
        return Promise.reject(() => ({
            message: err,
        }))
    }
);

const updateBlog = ({ token, id, ...data }) => axiosPost(apiUrls.UPDATE_BLOG, data, {
    Authorization: token
}, id)
    .then(
        response => alert('blog updated successfully')
    )
    .catch(
        error => alert('Something went wrong. Please try again later')
    );

const updateDictionary = ({ token, id, ...data }) => axiosPost(apiUrls.UPDATE_DICTIONARY, data, {
    Authorization: token
}, id)
    .then(
        response => alert('dictionary updated successfully')
    )
    .catch(
        error => alert('Something went wrong. Please try again later')
    );

const updateCounter = ({ token, id, ...data }) => axiosPost(apiUrls.UPDATE_COUNTER, data, {
    Authorization: token
}, id)
    .then(
        response => alert('counter updated successfully')
    )
    .catch(
        error => alert('Something went wrong. Please try again later')
    );

const updateNotice = ({ token, id, ...data }) => axiosPost(apiUrls.UPDATE_NOTICE, data, {
    Authorization: token
}, id)
    .then(
        response => alert('notice updated successfully')
    )
    .catch(
        error => alert('Something went wrong. Please try again later')
    );

const updateNews = ({ token, id, ...data }) => axiosPost(apiUrls.UPDATE_NEWS, data, {
    Authorization: token
}, id)
    .then(
        response => alert('news updated successfully')
    )
    .catch(
        error => alert('Something went wrong. Please try again later')
    );

const updateNewsRequest = ({ token, id, ...data }) => axiosPost(apiUrls.UPDATE_NEWS_REQUEST, data, {
    Authorization: token
}, id)
    .then(
        response => alert('news request updated successfully')
    )
    .catch(
        error => alert('Something went wrong. Please try again later')
    );

const updateStaticContent = ({ token, id, ...data }) => axiosPost(apiUrls.UPDATE_STATIC_CONTENT, data, {
    Authorization: token
}, id)
    .then(
        response => alert('static content updated successfully')
    )
    .catch(
        error => alert('Something went wrong. Please try again later')
    );

const updateCommentary = ({ token, id, ...data }) => axiosPost(apiUrls.UPDATE_COMMENTARY, data, {
    Authorization: token
}, id)
    .then(
        response => response
    )
    .catch(
        error => alert('Something went wrong. Please try again later')
    );

const createBlog = ({ token, ...data }) => axiosPost(apiUrls.CREATE_BLOG, data, {
    Authorization: token
})
    .then(
        response => alert('blog created successfully')
    )
    .catch(
        error => alert('Something went wrong. Please try again later')
    );

const createDictionary = ({ token, ...data }) => axiosPost(apiUrls.CREATE_DICTIONARY, data, {
    Authorization: token
})
    .then(
        response => alert('dictionary created successfully')
    )
    .catch(
        error => alert('Something went wrong. Please try again later')
    );

const createNotification = ({ token, ...data }) => axiosPost(apiUrls.CREATE_NOTIFICATION, data, {
})
    .then(
        response => response
    )
    .catch(
        error => error
    );

const createCounter = ({ token, ...data }) => axiosPost(apiUrls.CREATE_COUNTER, data, {
    Authorization: token
})
    .then(
        response => alert('counter created successfully')
    )
    .catch(
        error => alert('Something went wrong. Please try again later')
    );

const createNotice = ({ token, ...data }) => axiosPost(apiUrls.CREATE_NOTICE, data, {
    Authorization: token
})
    .then(
        response => alert('notice created successfully')
    )
    .catch(
        error => alert('Something went wrong. Please try again later')
    );

const createHighlight = ({ token, ...data }) => axiosPost(apiUrls.CREATE_HIGHLIGHT, data, {
    Authorization: token
}).then(
    response => alert('highlight created successfully')
).catch(
    error => alert('Something went wrong. Please try again later')
);

const sendToken = ({ ...data }) => axiosPost(apiUrls.SEND_TOKEN, data).then(
    response => response
).catch(
    error => error
);

const createNews = ({ token, ...data }) => axiosPost(apiUrls.CREATE_NEWS, data, {
    Authorization: token
}).then(
    response => alert('news created successfully')
).catch(
    error => alert('Something went wrong. Please try again later')
);

const createTranslation = ({ token, ...data }) => axiosPost(apiUrls.CREATE_TRANSLATION, data, {
    Authorization: token
}).then(
    response => toast.success("Translation Successfully Added!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 4000,
      })   
).catch(
    error => alert('Something went wrong. Please try again later')
);

const createOnThisDay = ({ token, ...data }) => axiosPost(apiUrls.CREATE_ON_THIS_DAY, data, {
    Authorization: token
}).then(
    response => alert('On This Day created successfully')
).catch(
    error => alert('Something went wrong. Please try again later')
);

const createNewsRequest = ({ token, ...data }) => axiosPost(apiUrls.CREATE_NEWS_REQUEST, data, {
    Authorization: token
}).then(
    response => alert('News request created successfully')
).catch(
    error => alert('Something went wrong. Please try again later')
);

const createCommentary = ({ token, ...data }) => axiosPost(apiUrls.CREATE_COMMENTARY, data, {
    Authorization: token
}).then(
    response => response
).catch(
    error => error
);

const createStaticContent = ({ token, ...data }) => axiosPost(apiUrls.CREATE_STATIC_CONTENT, data, {
    Authorization: token
}).then(
    response => alert('static content created successfully')
).catch(
    error => alert('Something went wrong. Please try again later')
);

const deleteBlog = ({ token, id }) => axiosPost(apiUrls.DELETE_BLOG, id, {
    Authorization: token
}, id).then(
    response => toast.success("Blog Successfully deleted!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      })
).catch(
    error => alert('Something went wrong. Please try again later')
);

const deleteDictionary = ({ token, id }) => axiosPost(apiUrls.DELETE_DICTIONARY, id, {
    Authorization: token
}, id).then(
    response => alert('dictionary deleted successfully')
).catch(
    error => alert('Something went wrong. Please try again later')
);

const deleteCounter = ({ token, id }) => axiosPost(apiUrls.DELETE_COUNTER, id, {
    Authorization: token
}, id).then(
    response => alert('counter deleted successfully')
).catch(
    error => alert('Something went wrong. Please try again later')
);

const deleteNotice = ({ token, id }) => axiosPost(apiUrls.DELETE_NOTICE, id, {
    Authorization: token
}, id).then(
    response => alert('notice deleted successfully')
).catch(
    error => alert('Something went wrong. Please try again later')
);

const deleteNews = ({ token, id }) => axiosPost(apiUrls.DELETE_NEWS, id, {
    Authorization: token
}, id).then(
    response => alert('news deleted successfully')
).catch(
    error => alert('Something went wrong. Please try again later')
);

const deleteCommentary = ({ token, id }) => axiosPost(apiUrls.DELETE_COMMENTARY, id, {
    Authorization: token
}, id).then(
    response => response
).catch(
    error => alert('Something went wrong. Please try again later')
);

const deleteOnThisDay = ({ token, id }) => axiosPost(apiUrls.DELETE_ON_THIS_DAY, id, {
    Authorization: token
}, id).then(
    response => alert('On this day deleted successfully')
).catch(
    error => alert('Something went wrong. Please try again later')
);

const deleteNewsRequest = ({ token, id }) => axiosPost(apiUrls.DELETE_NEWS_REQUEST, id, {
    Authorization: token
}, id).then(
    response => alert('news request deleted successfully')
).catch(
    error => alert('Something went wrong. Please try again later')
);

const deleteStaticContent = ({ token, id }) => axiosPost(apiUrls.DELETE_STATIC_CONTENT, id, {
    Authorization: token
}, id).then(
    response => alert('static content deleted successfully')
).catch(
    error => alert('Something went wrong. Please try again later')
);

const deleteHighlight = ({ token, id }) => axiosPost(apiUrls.DELETE_HIGHLIGHT, id, {
    Authorization: token
}, id).then(
    response => alert('highlight deleted successfully')
).catch(
    error => alert('Something went wrong. Please try again later')
);

const deleteToken = ({ token, id }) => axiosPost(apiUrls.DELETE_TOKEN, id, {
    Authorization: token
}, id).then(
    response => alert('highlight deleted successfully')
).catch(
    error => alert('Something went wrong. Please try again later')
);

const deleteTranslation = ({ token, id }) => axiosPost(apiUrls.DELETE_TRANSLATION, id, {
    Authorization: token
}, id).then(
    response => alert('translation deleted successfully')
).catch(
    error => alert('Something went wrong. Please try again later')
);

const updateHighlight = ({ token, id, ...data }) => axiosPost(apiUrls.UPDATE_HIGHLIGHT, data, {
    Authorization: token
}, id)
    .then(
        response => alert('highlight updated successfully')
    )
    .catch(
        error => alert('Something went wrong. Please try again later')
    );

const updateToken = ({ token, id, ...data }) => axiosPost(apiUrls.UPDATE_TOKEN, data, {
    Authorization: token
}, id)
    .then(
        response => alert('Token updated successfully')
    )
    .catch(
        error => alert('Something went wrong. Please try again later')
    );

const updateTranslation = ({ token, id, ...data }) => axiosPost(apiUrls.UPDATE_TRANSLATION, data, {
    Authorization: token
}, id)
    .then(
        response => alert('Translation updated successfully')
    )
    .catch(
        error => alert('Something went wrong. Please try again later')
    );

const updateOnThisDay = ({ token, id, ...data }) => axiosPost(apiUrls.UPDATE_ON_THIS_DAY, data, {
    Authorization: token
}, id)
    .then(
        response => alert('on this day updated successfully')
    )
    .catch(
        error => alert('Something went wrong. Please try again later')
    );

const resetPassword = ({ id, ...data }) => axiosPost(apiUrls.RESET_PASSWORD, data, {}, id)
    .then(
        response => response.data
    )
    .catch(
        error => error || 'something bad happened'
    );

const forgetPassword = data => axiosPost(apiUrls.FORGET_PASSWORD, data, {})
    .then(
        response => response.data
    )
    .catch(
        error => error || 'something bad happened'
    );

const fetchProfile = ({ id, token }) => axiosGet(apiUrls.FETCH_PROFILE, {
    Authorization: token,
}, id).then(
    response => processProfile(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const fetchCommentaryById = ({ id }) => axiosGet(apiUrls.FETCH_COMMENTARY_BY_ID, {}, id).then(
    response => processCommentaryById(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const fetchCounter = ({ id }) => axiosGet(apiUrls.FETCH_COUNTER, {}, id).then(
    response => processCounter(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const fetchNotice = ({ id }) => axiosGet(apiUrls.FETCH_NOTICE, {}, id).then(
    response => processNotice(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const fetchHighlightById = ({ id }) => axiosGet(apiUrls.FETCH_HIGHLIGHT_BY_ID, {}, id).then(
    response => processHighlightById(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const fetchTokenById = ({ id }) => axiosGet(apiUrls.FETCH_TOKEN_BY_ID, {}, id).then(
    response => processTokenById(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const fetchTranslationById = ({ id }) => axiosGet(apiUrls.FETCH_TRANSLATION_BY_ID, {}, id).then(
    response => processTranslationById(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const fetchBlogById = ({ id }) => axiosGet(apiUrls.FETCH_BLOG_BY_ID, {}, id).then(
    response => processBlogById(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const fetchDictionaryById = ({ id }) => axiosGet(apiUrls.FETCH_DICTIONARY_BY_ID, {}, id).then(
    response => processDictionaryById(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const fetchBlogBySlug = ({ slug }) => axiosGet(apiUrls.FETCH_BLOG_BY_SLUG, {}, slug).then(
    response => processBlogById(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const fetchDictionaryBySlug = ({ slug }) => axiosGet(apiUrls.FETCH_DICTIONARY_BY_SLUG, {}, slug).then(
    response => processDictionaryById(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const fetchNewsById = ({ id }) => axiosGet(apiUrls.FETCH_NEWS_BY_ID, {}, id).then(
    response => processNewsById(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const fetchNewsBySlug = ({ slug }) => axiosGet(apiUrls.FETCH_NEWS_BY_SLUG, {}, slug).then(
    response => processNewsById(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const fetchNewsRequestById = ({ id }) => axiosGet(apiUrls.FETCH_NEWS_REQUEST_BY_ID, {}, id).then(
    response => processNewsRequestById(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const fetchNewsRequestBySlug = ({ slug }) => axiosGet(apiUrls.FETCH_NEWS_REQUEST_BY_SLUG, {}, slug).then(
    response => processNewsRequestById(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const fetchStaticContentById = ({ id }) => axiosGet(apiUrls.FETCH_STATIC_CONTENT_BY_ID, {}, id).then(
    response => processStaticContentById(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const fetchHighlightBySlug = ({ slug }) => axiosGet(apiUrls.FETCH_HIGHLIGHT_BY_SLUG, {}, slug).then(
    response => processHighlightBySlug(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const countBlog = () => axiosGet(apiUrls.COUNT_BLOG, {}).then(
    response => processCountBlog(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const countDictionary = () => axiosGet(apiUrls.COUNT_DICTIONARY, {}).then(
    response => processCountDictionary(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const countHighlights = () => axiosGet(apiUrls.COUNT_HIGHLIGHTS, {}).then(
    response => processCountHighlights(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const countTranslation = () => axiosGet(apiUrls.COUNT_TRANSLATION, {}).then(
    response => processCountTranslation(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const countNews = () => axiosGet(apiUrls.COUNT_NEWS, {}).then(
    response => processCountNews(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const countNewsRequests = () => axiosGet(apiUrls.COUNT_NEWS_REQUESTS, {}).then(
    response => processCountNewsRequests(response.data),
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const fetchTeamRanking = ({ stype, rtype }) => axiosGet(apiUrls.FETCH_TEAM_RANKING, {}, stype, rtype).then(
    response => dfs(processTeamRank(response.data)),
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const fetchPlayerRanking = ({ stype, dtype, rtype }) => axiosGet(apiUrls.FETCH_PLAYER_RANK, {}, stype, rtype, dtype).then(
    response => dfs(processPlayerRank(response)),
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

const fetchEndOfOver = ({ slug }) => axiosGet(apiUrls.FETCH_END_OF_OVER, {}, slug).then(
    response => response.data,
    err => {
        return Promise.reject(() => ({
            message: err,
        }));
    }
);

export const endPoints = {
    SEND_TOKEN: sendToken,
    FETCH_TOKENS: fetchTokens,
    UPDATE_TOKEN: updateToken,
    DELETE_TOKEN: deleteToken,
    FETCH_TOKEN_BY_ID: fetchTokenById,
    CREATE_NOTIFICATION: createNotification,

    FETCH_LIVESCORES: fetchLiveScores,
    FETCH_LIVESCORES_TEST: fetchLiveScoresTest,
    FETCH_LIVESCORES_RECENT_FINISHED: fetchLiveScoresRecentFinished,
    FETCH_BD_MATCH: fetchBDMatch,
    FETCH_UPCOMING: fetchUpcoming,
    FETCH_FINISHED: fetchFininshed,
    FETCH_LIVE: fetchLive,
    FETCH_LOCAL_MATCHES: fetchLocalMatches,
    FETCH_INTERNATIONAL_MATCHES: fetchInternationalMatches,

    // FETCH_PLAYER_PROFILE: fetchPlayerProfile,
    FETCH_PLAYER_TABLE_DATA: fetchPlayerTableData,
    FETCH_TEAM_RANKING: fetchTeamRanking,
    FETCH_PLAYER_RANKING: fetchPlayerRanking,

    FETCH_MATCH: fetchMatch,
    FETCH_INNINGS: fetchInnings,
    FETCH_END_OF_OVER: fetchEndOfOver,
    FETCH_MATCH_WITH_INNINGS: fetchMatchWithInnings,

    LOGIN: login,
    REGISTER: register,
    FETCH_ID: fetchID,
    FETCH_PROFILE: fetchProfile,
    LOGOUT: () => Promise.resolve(),
    RESET_PASSWORD: resetPassword,
    FORGET_PASSWORD: forgetPassword,

    CREATE_BLOG: createBlog,
    FETCH_BLOGS: fetchBlogs,
    // FETCH_BLOG: fetchBlog,
    FETCH_BLOG_BY_ID: fetchBlogById,
    FETCH_BLOG_BY_SLUG: fetchBlogBySlug,
    UPDATE_BLOG: updateBlog,
    DELETE_BLOG: deleteBlog,
    COUNT_BLOG: countBlog,

    CREATE_DICTIONARY: createDictionary,
    FETCH_DICTIONARY: fetchDictionary,
    // FETCH_DICTIONARY: fetchDICTIONARY,
    FETCH_DICTIONARY_BY_ID: fetchDictionaryById,
    FETCH_DICTIONARY_BY_SLUG: fetchDictionaryBySlug,
    UPDATE_DICTIONARY: updateDictionary,
    DELETE_DICTIONARY: deleteDictionary,
    COUNT_DICTIONARY: countDictionary,

    CREATE_HIGHLIGHT: createHighlight,
    FETCH_HIGHLIGHTS: fetchHighlights,
    FETCH_HIGHLIGHT_BY_ID: fetchHighlightById,
    FETCH_HIGHLIGHT_BY_SLUG: fetchHighlightBySlug,
    FETCH_HIGHLIGHTS_BY_TAGS: fetchHighlightsByTags,
    UPDATE_HIGHLIGHT: updateHighlight,
    DELETE_HIGHLIGHT: deleteHighlight,
    COUNT_HIGHLIGHTS: countHighlights,

    CREATE_TRANSLATION: createTranslation,
    FETCH_TRANSLATION: fetchTranslation,
    FETCH_TRANSLATION_BY_ID: fetchTranslationById,
    UPDATE_TRANSLATION: updateTranslation,
    DELETE_TRANSLATION: deleteTranslation,
    COUNT_TRANSLATION: countTranslation,

    CREATE_NEWS: createNews,
    FETCH_NEWS: fetchNews,
    FETCH_NEWS_BY_ID: fetchNewsById,
    FETCH_NEWS_BY_SLUG: fetchNewsBySlug,
    UPDATE_NEWS: updateNews,
    DELETE_NEWS: deleteNews,
    COUNT_NEWS: countNews,

    CREATE_COMMENTARY: createCommentary,
    FETCH_COMMENTARY_BY_ID: fetchCommentaryById,
    FETCH_COMMENTARIES: fetchCommentaries,
    UPDATE_COMMENTARY: updateCommentary,
    DELETE_COMMENTARY: deleteCommentary,
    //No count for commentary? 

    CREATE_STATIC_CONTENT: createStaticContent,
    FETCH_STATIC_CONTENTS: fetchStaticContents,
    FETCH_STATIC_CONTENT_BY_ID: fetchStaticContentById,
    UPDATE_STATIC_CONTENT: updateStaticContent,
    DELETE_STATIC_CONTENT: deleteStaticContent,

    CREATE_NEWS_REQUEST: createNewsRequest,
    FETCH_NEWS_REQUESTS: fetchNewsRequests,
    FETCH_NEWS_REQUEST_BY_ID: fetchNewsRequestById,
    FETCH_NEWS_REQUEST_BY_SLUG: fetchNewsRequestBySlug,
    UPDATE_NEWS_REQUEST: updateNewsRequest,
    DELETE_NEWS_REQUEST: deleteNewsRequest,
    COUNT_NEWS_REQUESTS: countNewsRequests,

    CREATE_ON_THIS_DAY: createOnThisDay,
    FETCH_ON_THIS_DAY: fetchOnThisDay,
    FETCH_ALL_ON_THIS_DAY: fetchAllOnThisDay,
    FETCH_ON_THIS_DAY_BY_ID: fetchOnThisDayById,
    FETCH_ON_THIS_DAY_BY_SLUG: fetchOnThisDayBySlug,
    UPDATE_ON_THIS_DAY: updateOnThisDay,
    DELETE_ON_THIS_DAY: deleteOnThisDay,

    CREATE_COUNTER: createCounter,
    FETCH_COUNTER: fetchCounter,
    FETCH_COUNTERS: fetchCounters,
    UPDATE_COUNTER: updateCounter,
    DELETE_COUNTER: deleteCounter,

    CREATE_NOTICE: createNotice,
    FETCH_NOTICE: fetchNotice,
    FETCH_NOTICES: fetchNotices,
    UPDATE_NOTICE: updateNotice,
    DELETE_NOTICE: deleteNotice,

    FETCH_TOURNAMENT_BY_ID: fetchTournamentById,
    FETCH_TOURNAMENT_BY_SLUG: fetchTournamentBySlug,
};

const axiosGet = (f, header, ...d) => {
    if (typeof f === 'string') {
        if (!f) return Promise.reject(() => "empty request");
        return axios.get(f, {
            headers: header,
        });
    }

    if (typeof f === 'function') {
        if (!f(...d)) return Promise.reject(() => "empty request");
        return axios.get(f(...d), {
            headers: header,
        });
    }
    return Promise.reject(() => "Invalid Request");
};

const axiosPost = (f, data, header = {}, ...d) => {
    let headers = {
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Cache-Control': 'no-cache',
        'Access-Control-Allow-Origin': '*',
        ...header,
    }, url;

    if (f === 'https://fcm.googleapis.com/fcm/send') {
        headers = {
            'Content-Type': 'application/json',
            'Authorization': 'key=AAAA4DDrTEo:APA91bGkaZWyM89l4cxFxE0rrQXmEOupKWwLUSP7E29KeXH4Lbk4yXba22bjNj8YSL1J8P-g8CG0CaHhyBl4ABucW05RmpzI7IlgD174MR2JV9DuDS_08dKAieUbA3T2EtL3pB2Njhfq'
        }
    }

    if (typeof f === 'string') {
        if (!f) return Promise.reject(() => "empty request");
        url = f;
    }
    if (typeof f === 'function') {
        if (!f(...d)) return Promise.reject(() => "empty request");
        url = f(...d);
    }
    if (url !== '') return axios.post(url, data, {
        headers,
    });

    return Promise.reject(() => "Invalid Request");
};

let apiUrls = {};

export const generateApiUrls = backendUrl => {
    apiUrls = {
        FETCH_PLAYER_TABLE_DATA: (d) => backendUrl + `api/playerrecordslug/${d}`,

        SEND_TOKEN: backendUrl + `api/device`,
        FETCH_TOKENS: (skip, limit) => backendUrl + `api/getdevice/${skip}/${limit}`,
        FETCH_TOKEN_BY_ID: (id) => backendUrl + `api/device/${id}`,
        UPDATE_TOKEN: (id) => backendUrl + `api/deviceupdate/${id}`,
        DELETE_TOKEN: (id) => backendUrl + `api/device/remove/${id}`,
        CREATE_NOTIFICATION: `https://fcm.googleapis.com/fcm/send`,

        FETCH_TEAM_RANKING: (sex, type) => backendUrl + `api/teamrank/${sex}/${type}`,
        FETCH_PLAYER_RANK: (sex, type, discipline) => backendUrl + `api/rank/${sex}/${type}/${discipline}`,

        FETCH_LIVESCORES: backendUrl + 'api/recentrunning/limitedmatch',
        FETCH_LOCAL_MATCHES: backendUrl + 'api/localmatch/home',
        FETCH_INTERNATIONAL_MATCHES: backendUrl + 'api/internationalmatch/home',
        FETCH_LIVESCORES_RECENT_FINISHED: (skip, limit) => backendUrl + `api/recentended/${skip}/${limit}`,
        FETCH_LIVESCORES_TEST: backendUrl + 'api/recentrunning/testmatch',
        FETCH_UPCOMING: backendUrl + 'api/matchlive/upcoming',
        FETCH_FINISHED: backendUrl + 'api/matchlive/finished',
        FETCH_LIVE: backendUrl + 'api/matchlive/live',

        FETCH_INNINGS: slug => backendUrl + `api/scoreboard/${slug}`,
        FETCH_MATCH: slug => backendUrl + `api/matchslug/${slug}`,
        FETCH_MATCH_WITH_INNINGS: slug => backendUrl + `api/matchone/${slug}`,
        FETCH_END_OF_OVER: slug => backendUrl + `api/endover/${slug}`,
        FETCH_BD_MATCH: backendUrl + 'api/bdmatch/home',

        LOGIN: backendUrl + `api/token`,
        REGISTER: backendUrl + 'api/user',
        FETCH_ID: backendUrl + 'api/user/info/getid',
        FETCH_PROFILE: (id) => backendUrl + `api/user/${id}`,

        CREATE_BLOG: backendUrl + `api/blog`,
        FETCH_BLOGS: (skip, limit) => backendUrl + `api/allblog/${skip}/${limit}`,
        UPDATE_BLOG: (id) => backendUrl + `api/blogupdate/${id}`,
        DELETE_BLOG: (id) => backendUrl + `api/blog/remove/${id}`,
        COUNT_BLOG: backendUrl + `api/blog/info/count`,

        CREATE_DICTIONARY: backendUrl + `api/dictionary`,
        FETCH_DICTIONARY: (skip, limit) => backendUrl + `api/alldictionary/${skip}/${limit}`,
        UPDATE_DICTIONARY: (id) => backendUrl + `api/dictionary/update/${id}`,
        DELETE_DICTIONARY: (id) => backendUrl + `api/dictionary/remove/${id}`,
        FETCH_DICTIONARY_BY_ID: (id) => backendUrl + `api/dictionary/info/${id}`,
        FETCH_DICTIONARY_BY_SLUG: (slug) => backendUrl + `api/dictionary/search/${slug}`,
        COUNT_DICTIONARY: backendUrl + `api/dictionary/list/count`,

        CREATE_HIGHLIGHT: backendUrl + `api/youtube`,
        FETCH_HIGHLIGHTS: (skip, limit) => backendUrl + `api/videos/${skip}/${limit}`,
        FETCH_HIGHLIGHTS_BY_TAGS: backendUrl + `api/youtube/searchtag`,
        FETCH_HIGHLIGHT_BY_ID: (id) => backendUrl + `api/youtube/${id}`,
        UPDATE_HIGHLIGHT: (id) => backendUrl + `api/youtube/${id}`,
        DELETE_HIGHLIGHT: (id) => backendUrl + `api/youtube/remove/${id}`,
        COUNT_HIGHLIGHTS: backendUrl + `api/youtube/videos/count`,

        CREATE_NEWS: backendUrl + `api/news`,
        FETCH_NEWS: backendUrl + `api/news/info/all`,
        DELETE_NEWS: (id) => backendUrl + `api/news/remove/${id}`,
        UPDATE_NEWS: (id) => backendUrl + `api/news/update/${id}`,
        COUNT_NEWS: backendUrl + `api/news/count`,

        FETCH_BLOG_BY_ID: (id) => backendUrl + `api/blog/${id}`,
        FETCH_NEWS_BY_ID: (id) => backendUrl + `api/news/info/${id}`,
        FETCH_NEWS_REQUEST_BY_ID: (id) => backendUrl + `api/newsupdate/${id}`,
        FETCH_STATIC_CONTENT_BY_ID: (id) => backendUrl + `api/staticcontent/info/${id}`,
        FETCH_STATIC_CONTENT_BY_SLUG: (slug) => backendUrl + `api/staticcontent/getslug/${slug}`,

        CREATE_COMMENTARY: backendUrl + `api/commentary`,
        UPDATE_COMMENTARY: (id) => backendUrl + `api/commentary/update/${id}`,
        DELETE_COMMENTARY: (id) => backendUrl + `api/commentary/remove/${id}`,
        FETCH_COMMENTARY_BY_ID: (id) => backendUrl + `api/commentary/info/${id}`,
        FETCH_COMMENTARIES: (skip, limit) => backendUrl + `api/allcommentary/${skip}/${limit}`,

        CREATE_STATIC_CONTENT: backendUrl + `api/admin/staticcontent`,
        FETCH_STATIC_CONTENTS: backendUrl + `api/staticcontent/info/all`,
        UPDATE_STATIC_CONTENT: (id) => backendUrl + `api/staticcontent/update/${id}`,
        DELETE_STATIC_CONTENT: (id) => backendUrl + `api/staticcontent/remove/${id}`,

        CREATE_NEWS_REQUEST: backendUrl + `api/newsupdate`,
        UPDATE_NEWS_REQUEST: (id) => backendUrl + `api/newsupdate/update/${id}`,
        DELETE_NEWS_REQUEST: (id) => backendUrl + `api/newsupdate/remove/${id}`,
        FETCH_NEWS_REQUESTS: (skip, limit) => backendUrl + `api/allnewsupdate/${skip}/${limit}`,
        COUNT_NEWS_REQUESTS: backendUrl + `api/newsupdate/info/count`,

        CREATE_ON_THIS_DAY: backendUrl + `api/onthisday`,
        UPDATE_ON_THIS_DAY: (id) => backendUrl + `api/onthisday/update/${id}`,
        DELETE_ON_THIS_DAY: (id) => backendUrl + `api/onthisday/remove/${id}`,
        FETCH_ALL_ON_THIS_DAY: backendUrl + `api/onthisday/info/all`,
        FETCH_ON_THIS_DAY: onthisday => backendUrl + `api/onthisday/date/${onthisday}`,
        FETCH_ON_THIS_DAY_BY_ID: id => backendUrl + `api/onthisday/info/${id}`,
        FETCH_ON_THIS_DAY_BY_SLUG: slug => backendUrl + `api/onthisday/search/${slug}`,

        FORGET_PASSWORD: backendUrl + `api/user/resetemail`,
        RESET_PASSWORD: id => backendUrl + `api/user/resetpassword/${id}`,

        CREATE_TRANSLATION: backendUrl + `api/translation`,
        DELETE_TRANSLATION: (id) => backendUrl + `api/translation/remove/${id}`,
        UPDATE_TRANSLATION: (id) => backendUrl + `api/translation/update/${id}`,
        FETCH_TRANSLATION_BY_ID: (id) => backendUrl + `api/translation/info/${id}`,
        FETCH_TRANSLATION: (skip, limit) => backendUrl + `api/gettranslation/${skip}/${limit}`,
        COUNT_TRANSLATION: backendUrl + `api/translation/count`,

        FETCH_BLOG_BY_SLUG: (slug) => backendUrl + `api/blogslug/${slug}`,
        FETCH_NEWS_BY_SLUG: (slug) => backendUrl + `api/news/search/${slug}`,
        FETCH_NEWS_REQUEST_BY_SLUG: (slug) => backendUrl + `api/newsupdate/${slug}`,
        FETCH_HIGHLIGHT_BY_SLUG: (slug) => backendUrl + `api/youtube/info/all/${slug}`,

        CREATE_COUNTER: backendUrl + `api/counter`,
        DELETE_COUNTER: (id) => backendUrl + `api/counter/remove/${id}`,
        UPDATE_COUNTER: (id) => backendUrl + `api/counterupdate/${id}`,
        FETCH_COUNTER: (id) => backendUrl + `api/counter/${id}`,
        FETCH_COUNTERS: (skip, limit) => backendUrl + `api/allcounter/${skip}/${limit}`,

        CREATE_NOTICE: backendUrl + `api/notice`,
        DELETE_NOTICE: (id) => backendUrl + `api/notice/remove/${id}`,
        UPDATE_NOTICE: (id) => backendUrl + `api/noticeupdate/${id}`,
        FETCH_NOTICE: (id) => backendUrl + `api/notice/${id}`,
        FETCH_NOTICES: (skip, limit) => backendUrl + `api/allnotice/${skip}/${limit}`,

        FETCH_TOURNAMENT_BY_ID: id => backendUrl + `api/tournament/info/${id}`,
        FETCH_TOURNAMENT_BY_SLUG: slug => backendUrl + `api/tournament/getbyslug/${slug}`
    };
};

const BackendMapping = {
    matchTypes: {
        'ODIs': 'ওডিআই',
        'T20Is': 'টি২০আই',
        'T20': 'টি২০',
        'firstClass': 'প্রথম শ্রেণী',
        'listA': 'লিস্ট এ',
        'tests': 'টেস্ট',
    },
    bowling: {
        '10': 'দশ উইকেট',
        '5w': 'পাঁচ উইকেট',
        '4w': 'চার উইকেট',
        'SR': 'স্ট্রাইক রেট',
        'Econ': 'ইকোনমি',
        'Ave': 'বোলিং গড়',
        'BBM': 'ম্যাচ সেরা',
        'BBI': 'ইনিংস সেরা',
        'Wkts': 'উইকেট',
        'Runs': 'রান',
        'Balls': 'বল',
        'Inns': 'ইনিংস',
        'Mat': 'ম্যাচ',
    },
    batting: {
        '50': 'অর্ধশত',
        '100': 'শতরান',
        'St': 'স্টাম্পড',
        'Ct': 'ক্যাচ',
        '6s': 'ছয়',
        '4s': 'চার',
        'SR': 'স্ট্রাইক রেট',
        'BF': 'বল',
        'Ave': 'ব্যাটিং গড়',
        'HS': 'সর্বোচ্চ স্কোর',
        'Runs': 'রান',
        'NO': 'নট আউট',
        'Inns': 'ইনিংস',
        'Mat': 'ম্যাচ',
    },
};

const numberMapping = {
    "0": "\u09e6",
    "1": "\u09e7",
    "2": "\u09e8",
    "3": "\u09e9",
    "4": "\u09ea",
    "5": "\u09eb",
    "6": "\u09ec",
    "7": "\u09ed",
    "8": "\u09ee",
    "9": "\u09ef",
};
