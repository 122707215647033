const registerData = [
    {
        keyval: 'firstName',
        label: 'প্রথম নাম',
        helperText: 'শুধু অক্ষর, স্পেস, হাইফেন এবং কমা গ্রহণযোগ্য'
    },
    {
        keyval: 'lastName',
        label: 'শেষ নাম',
        helperText: 'শুধু অক্ষর, স্পেস, হাইফেন এবং কমা গ্রহণযোগ্য'
    },
    {
        keyval: 'userName',
        label: 'ব্যবহারকারীর নাম',
        helperText: 'শুধু অক্ষর, স্পেস, হাইফেন এবং কমা গ্রহণযোগ্য'
    },
    {
        keyval: 'email',
        label: 'ইমেইল',
        helperText: ''
    },
    {
        keyval: 'phone',
        label: 'ফোন নাম্বার',
        helperText: 'শুধু সংখ্যা গ্রহণযোগ্য'
    },
    {
        keyval: 'address',
        label: 'ঠিকানা',
        helperText: ''
    },
    {
        keyval: 'password1',
        label: 'পাসওয়ার্ড',
        helperText: 'কমপক্ষে ৮টি অক্ষর হতে হবে'
    },
    {
        keyval: 'password2',
        label: 'পুনরায় পাসওয়ার্ড',
        helperText: `পাসওয়ার্ড মিলেনি`
    },
];

export default registerData;