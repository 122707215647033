import {
    getToken
} from '../reducers';
import {
    endPoints
} from '../../Api';
import {actions} from "../action-types/counter";


export const fetchCounters = (skip, limit) => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_FETCH_COUNTERS,
            actions.SUCCESS_FETCH_COUNTERS,
            actions.FAILURE_FETCH_COUNTERS
        ],
        method: endPoints.FETCH_COUNTERS,
        race: () => false,
        data: {
            skip,
            limit,
            // token: getToken(getState())
        }
    }
});

export const fetchCounterById = id => ({
    RSAA: {
        types: [
            actions.REQUEST_FETCH_COUNTER_BY_ID,
            actions.SUCCESS_FETCH_COUNTER_BY_ID,
            actions.FAILURE_FETCH_COUNTER_BY_ID,
        ],
        method: endPoints.FETCH_COUNTER,
        race: () => false,
        data: {
            id,
        },
    },
});

export const createCounter = state => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_CREATE_COUNTER,
            actions.SUCCESS_CREATE_COUNTER,
            actions.FAILURE_CREATE_COUNTER
        ],
        method: endPoints.CREATE_COUNTER,
        race: () => false,
        data: {
            ...state,
            token: getToken(getState()),
            sequence: parseInt(state.sequence)
        }
    }
});

export const updateCounter = state => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_UPDATE_COUNTER,
            actions.SUCCESS_UPDATE_COUNTER,
            actions.FAILURE_UPDATE_COUNTER
        ],
        method: endPoints.UPDATE_COUNTER,
        race: () => false,
        data: {
            ...state,
            token: getToken(getState()),
            id: state.id,
            sequence: parseInt(state.sequence)
        }
    }
});

export const deleteCounter = id => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_DELETE_COUNTER,
            actions.SUCCESS_DELETE_COUNTER,
            actions.FAILURE_DELETE_COUNTER
        ],
        method: endPoints.DELETE_COUNTER,
        race: () => false,
        data: {
            id,
            token: getToken(getState()),
        }
    }
});