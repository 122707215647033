const loginData = [
    {
        keyval: 'user_name',
        label: 'ব্যবহারকারীর নাম',
    },
    {
        keyval: 'password',
        label: 'পাসওয়ার্ড',
    }
];

export default loginData;