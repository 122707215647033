import React from 'react';
import classNames from 'classnames';
import {
    Autocomplete,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    useTheme
} from '@mui/material';
import {
    makeStyles
} from '@mui/styles';
import PropTypes from 'prop-types';
import {
    BannerForPage,
    DatePicker,
    RichText,
} from '.';

const useStyles = makeStyles(
    theme => (
        {
            buttons: {
                margin: '0 auto'
            },
            care: {
                marginLeft: 10
            },
            formControl: {
                '& .MuiFormGroup-root': {
                    flexDirection: 'row'
                }
            },
            margin: {
                margin: theme.spacing(1),
            },
            radio: {
                marginTop: 8,
                padding: '0px 8px'
            },
            root: {
                maxWidth: '60%',
                width: '60%',
                margin: '100px auto',
                height: '100%',
                padding: theme.spacing(1),
                justifyContent: 'center',
                color: theme.palette.secondary.dark,
                [theme.breakpoints.down('sm')]: {
                    maxWidth: '95%',
                    width: '95%',
                },
                '& .MuiGrid-root': {
                    width: '100%',
                    marginTop: 0,
                    marginLeft: 0
                }
            },
            textField: {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
                width: '100%',
            },
        }
    )
);

const Entry = ({
    onDelete,
    onClick,
    state,
    data,
    date,
    richText,
    emptyState,
    setState,
    deleteText,
    pageBanner,
    menu,
    radio,
    checked,
    flag
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const onChange = key => event => {
        setState({
            ...state,
            [key]: event.target.value,
        });
    };

    const onChangeDate = key => event => {
        setState({
            ...state,
            [key]: event,
        });
    };

    const onChangeRichText = key => value => {
        setState({
            ...state,
            [key]: JSON.stringify(value)
        })
    };

    const handleChangeReviewed = key => event => {
        setState({
            ...state,
            [key]: Number(event.target.value)
        })
    };

    const onChangeChecked = key => event => {
        setState({
            ...state,
            [key]: event.target.checked
        });
    };

    const submit = () => {
        // if (state.type === 'phrase') {
        //     let re1 = '', re2 = '';

        //     re1 = state.english.split(' ');
        //     re2 = state.bangla.split(' ');
        //     if (re1.length !== re2.length) return;
        // }

        onClick(state);
    };

    return (
        <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={2}
            className={classes.root}
            sx={{
                width: '100%',
                marginTop: 0,
                marginLeft: 0,
                margin: '90px auto',
                [theme.breakpoints.down('sm')]: {
                    margin: '60px auto',
                },
            }}
        >
            <BannerForPage
                banner={pageBanner}
            />
            {
                menu
                &&
                menu.map(
                    (i, index) => (
                        <Autocomplete
                            id={i.label}
                            key={index}
                            defaultValue={state[i.keyval]}
                            onChange={(event, newValue) => {
                                setState({
                                    ...state,
                                    [i.keyval]: newValue,
                                });
                            }}
                            options={i.menus}
                            style={{ width: 300, paddingLeft: 15 }}
                            renderInput={(params) => <TextField {...params} label={params.id} />}
                        />
                    )
                )
            }
            {
                data.map(
                    (item, index) => (
                        <Grid
                            item
                            key={index}
                            xs
                        >
                            <TextField
                                label={item.label}
                                keyval={item.keyval}
                                required={item.required}
                                className={
                                    classNames(
                                        classes.margin,
                                        classes.textField
                                    )
                                }
                                value={state[item.keyval]}
                                color='secondary'
                                onChange={onChange(item.keyval)}
                            // state={state}
                            />
                        </Grid>
                    )
                )
            }
            {
                richText
                &&
                richText.map(
                    (item, index) => (
                        <Grid
                            item
                            key={index}
                            xs
                            className={classes.care}
                        >
                            <Typography>
                                {item.label}
                            </Typography>
                            <RichText
                                image={item.keyval === 'content' ? true : false}
                                label={item.label}
                                keyval={item.keyval}
                                className={
                                    classNames(
                                        classes.margin,
                                        classes.textField
                                    )
                                }
                                value={JSON.parse(state[item.keyval])}
                                onChange={onChangeRichText(item.keyval)}
                            />
                        </Grid>
                    )
                )
            }
            {
                date
                &&
                date.map(
                    (item, index) => (
                        <Grid
                            item
                            key={index}
                            xs
                            className={classes.care}
                        >
                            <DatePicker
                                value={state[item.keyval]}
                                label={item.keyval}
                                onChange={
                                    onChangeDate(item.keyval)
                                }
                                style={{
                                    '& .MuiTypography-colorPrimary': {
                                        // color: 'black'
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Grid>
                    )
                )
            }
            {
                radio
                &&
                radio.map(
                    (i, index) => (
                        <Grid
                            item
                            key={index}
                            xs
                            className={classes.care}
                        >
                            <FormControl component="fieldset" className={classes.formControl}>
                                <Typography>{i.label}</Typography>
                                <RadioGroup aria-label={i.label} name={i.label} value={state.importance} onChange={handleChangeReviewed(i.keyval)}>
                                    <Typography
                                        className={classes.radio}
                                    >কম</Typography>
                                    {
                                        [1, 2, 3, 4, 5].map((value) => (
                                            <FormControlLabel value={value} key={value} control={<Radio />} label={value} />
                                        ))
                                    }
                                    <Typography className={classes.radio}>বেশী</Typography>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    )
                )
            }
            {
                checked
                &&
                checked.map(
                    (item, index) => console.log('sdfsdh', item) || (
                        <Grid
                            item
                            key={index}
                            xs
                        >
                            <FormGroup row>
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            checked={state[item.keyval]}
                                            onChange={onChangeChecked(item.keyval)}
                                            name={item.label}
                                        />
                                    }
                                    label={item.label}
                                />
                            </FormGroup>
                        </Grid>
                    )
                )
            }

            <Grid
                item
                xs
                className={classes.buttons}
            >
                <Button
                    sx={{
                        margin: theme.spacing(2, 0),
                        // color: theme.palette.primary.dark,
                        textAlign: 'center',
                        // fontWeight: 600,
                        backgroundColor: theme.palette.primary.dark,
                        color: theme.palette.primary.light,
                        boxShadow: '#33691e 0px 1px 4px',
                        padding: theme.spacing(1, 2),
                        '&:hover': {
                            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                            color: theme.palette.primary.main,
                            fontWeight: 900,
                            backgroundColor: theme.palette.primary.dark,
                        }
                    }}
                    autoFocus
                    onClick={
                        flag
                            ?
                            submit
                            :
                            () => {
                                onClick(state);
                                setState(emptyState);
                            }}
                >
                    save
                </Button>
                {
                    state.id
                    &&
                    <Button
                        autoFocus
                        sx={{
                            margin: theme.spacing(2, 1),
                            textAlign: 'center',
                            backgroundColor: theme.palette.primary.dark,
                            color: theme.palette.primary.light,
                            boxShadow: '#33691e 0px 1px 4px',
                            '&:hover': {
                                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                                color: 'white',
                                fontWeight: 900,
                                backgroundColor: 'red',
                            }
                        }}
                        onClick={() => {
                            onDelete(state.id);
                            setState(emptyState);
                        }}
                    >
                        {deleteText}
                    </Button>
                }

            </Grid>
        </Grid >
    );
};

Entry.propTypes = {
    state: PropTypes.object.isRequired,
}

export default Entry;