import React, {
    useState
} from 'react';
import {
    Fab,
    useTheme
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import AlsoSee from '../alsoSeeSection';
import Appbar from '../appbar';
import Footer from '../footer';

const Layout = ({
    children
}) => {
    const [visible, setVisible] = useState(false);
    const theme = useTheme();

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <>
            <Appbar />
            {/* <CssBaseline /> */}
            {children}
            {
                window.pageYOffset > 0
                &&
                <Fab
                    sx={{
                        margin: 0,
                        top: 'auto',
                        right: 40,
                        bottom: 40,
                        left: 'auto',
                        position: 'fixed',
                        display: visible ? 'inline' : 'none',
                        backgroundColor: theme.palette.primary.dark,
                        color: theme.palette.primary.main,
                        ':hover': {
                            backgroundColor: theme.palette.primary.dark,
                            color: theme.palette.primary.main,
                            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
                        }
                    }}
                    onClick={scrollToTop}
                >
                    <ArrowUpwardIcon
                        sx={{
                            marginTop: '7px',
                            ':hover': {
                                height: '1.2em',
                                width: '1.2em'
                            }
                        }}
                    />
                </Fab>
            }
            <AlsoSee />
            <Footer />
        </>
    );
}

export default Layout;
