import React, {
    memo
} from 'react';
import {
    Grid,
} from '@mui/material';
import {
    makeStyles
} from '@mui/styles';
import PropTypes from 'prop-types';
import Banner from './counter/Banner.jsx';
import Banner1 from './Banner';
import Counter from './counter/index.jsx';
import {
    DateAndTimeForMatchLivescore,
} from '../../commonComponent';

const useStyles = makeStyles(
    theme => (
        {
            matchstatus: {
                color: theme.palette.primary.dark,
                fontWeight: 900
            }
        }
    )
);

// const areEqual = (
//     prevProps,
//     nextProps
// ) => true;

const Infos = memo(
    (
        {
            // matchStatus,
            start_time,
            days,
            series,
            stadium,
            t1,
            t2
        }
    ) => {
        const classes = useStyles();

        return (
            <Grid
                item
                container
                xs={12}
                direction="row"
                justifyContent='center'
            >
                {
                    <Grid
                        item
                        xs={12}
                    >
                        <DateAndTimeForMatchLivescore
                            className={classes.matchstatus}
                            variant="body1"
                            color='inherit'
                            start_time={start_time}
                            day={days}
                            align='center'
                            sx={{
                                fontWeight: 900
                            }}
                        />
                        <Banner1
                            variant="body1"
                            align='center'
                            color='inherit'
                            children={
                                series
                                    ?
                                    `${series}, ${stadium}`
                                    :
                                    `${stadium}`
                            }
                        />
                    </Grid >
                }

                <Grid
                    item
                    xs={10}
                    sm={10}
                    md={9}
                >
                    <Counter
                        start_time={start_time}
                    />
                </Grid>
                <Grid
                    item
                    xs={10}
                    sm={10}
                    md={9}
                >
                    <Banner
                        t1={t1}
                        t2={t2}
                    />
                </Grid>
            </Grid >
        );
    }
);

Infos.propTypes = {
    start_time: PropTypes.string.isRequired,
    // matchStatus: PropTypes.string.isRequired
};

export default Infos;