import {
    fetchBlogById,
    fetchBlogBySlug,
} from '../../redux/actions/Blogs';
import {
    fetchCounterById,
} from '../../redux/actions/Counter';
import {
    fetchDictionaryById,
    fetchDictionaryBySlug,
} from '../../redux/actions/Dictionary';
import {
    fetchHighlightById,
    fetchHighlightBySlug,
} from '../../redux/actions/Highlights';
import {
    fetchNewsById,
    fetchNewsBySlug,
} from '../../redux/actions/News';
import {
    fetchNewsRequestById,
    fetchNewsRequestBySlug
} from '../../redux/actions/NewsRequest';
import {
    fetchNoticeById,
} from '../../redux/actions/Notice';
import {
    fetchOnThisDayById,
    fetchOnThisDayBySlug,
} from '../../redux/actions/OnThisDay';
import {
    fetchTranslationById,
} from '../../redux/actions/Translation';
import {
    getBlog,
    getBlogBySlug,
    getCounterById,
    getNoticeById,
    getAdmin,
    getTranslationById,
    getIsFetchingCounterIndividual,
    getIsFetchingNoticeIndividual,
    getIsFetchingTranslationIndividual,
    getIsFetchingBlogIndividual,
    getHighlightById,
    getHighlightBySlug,
    getIsFetchingHighlightsIndividual,
    getIsFetchingNewsIndividual,
    getNewsById,
    getNewsBySlug,
    getIsFetchingNewsRequestIndividual,
    getIsFetchingOnThisDayIndividual,
    getOnThisDayById,
    getOnThisDayBySlug,
    getNewsRequestById,
    getNewsRequestBySlug,
    getIsFetchingDictionaryIndividual,
    getDictionaryById,
    getDictionaryBySlug
} from '../../redux/reducers';

const individual = {
    'blogs': {
        getAdmin: getAdmin,
        getElementById: getBlog,
        getElementBySlug: getBlogBySlug,
        getIsFetching: getIsFetchingBlogIndividual,
        title: 'খেলা হবে! ব্লগ',
        fetchActionById: fetchBlogById,
        fetchActionBySlug: fetchBlogBySlug,
        buttonText: 'Update Blog',
        pathName: '/entry/blog'
    },
    'dictionary': {
        getAdmin: getAdmin,
        getElementById: getDictionaryById,
        getElementBySlug: getDictionaryBySlug,
        getIsFetching: getIsFetchingDictionaryIndividual,
        title: 'খেলা হবে! ক্রিকেট অভিধান',
        fetchActionById: fetchDictionaryById,
        fetchActionBySlug: fetchDictionaryBySlug,
        buttonText: 'Update Dictionary',
        pathName: '/entry/dictionary'
    },
    'counter': {
        getAdmin: getAdmin,
        getElementById: getCounterById,
        getElementBySlug: () => false,
        getIsFetching: getIsFetchingCounterIndividual,
        title: 'খেলা হবে! কাউন্টার',
        fetchActionById: fetchCounterById,
        fetchActionBySlug: () => false,
        buttonText: 'Update Counter',
        pathName: '/entry/counter'
    },
    'notice': {
        getAdmin: getAdmin,
        getElementById: getNoticeById,
        getElementBySlug: () => false,
        getIsFetching: getIsFetchingNoticeIndividual,
        title: 'খেলা হবে! নোটিশ',
        fetchActionById: fetchNoticeById,
        fetchActionBySlug: () => false,
        buttonText: 'Update Notice',
        pathName: '/entry/notice'
    },
    'highlights': {
        getAdmin: getAdmin,
        getElementById: getHighlightById,
        getElementBySlug: getHighlightBySlug,
        getIsFetching: getIsFetchingHighlightsIndividual,
        title: 'খেলা হবে! হাইলাইটস',
        fetchActionById: fetchHighlightById,
        fetchActionBySlug: fetchHighlightBySlug,
        buttonText: 'Update Highlight',
        pathName: '/entry/highlight'
    },
    'highlights-tags': {
        getAdmin: getAdmin,
        getElementById: getHighlightById,
        getElementBySlug: getHighlightBySlug,
        getIsFetching: getIsFetchingHighlightsIndividual,
        title: 'খেলা হবে! হাইলাইটস',
        fetchActionById: fetchHighlightById,
        fetchActionBySlug: fetchHighlightBySlug,
        buttonText: 'Update Highlight',
        pathName: '/entry/highlight'
    },
    'news': {
        getAdmin: getAdmin,
        getElementById: getNewsById,
        getElementBySlug: getNewsBySlug,
        getIsFetching: getIsFetchingNewsIndividual,
        title: 'খেলা হবে! সংবাদ',
        fetchActionById: fetchNewsById,
        fetchActionBySlug: fetchNewsBySlug,
        buttonText: 'Update news',
        pathName: '/entry/news'
    },
    'news-requests': {
        getAdmin: getAdmin,
        getElementById: getNewsRequestById,
        getElementBySlug: getNewsRequestBySlug,
        getIsFetching: getIsFetchingNewsRequestIndividual,
        title: 'খেলা হবে! সংবাদ আবেদন',
        fetchActionById: fetchNewsRequestById,
        fetchActionBySlug: fetchNewsRequestBySlug,
        buttonText: 'Update news request',
        pathName: '/entry/news-request'
    },
    'on-this-day': {
        getAdmin: getAdmin,
        getElementById: getOnThisDayById,
        getElementBySlug: getOnThisDayBySlug,
        getIsFetching: getIsFetchingOnThisDayIndividual,
        title: 'খেলা হবে! এই দিনে',
        fetchActionById: fetchOnThisDayById,
        fetchActionBySlug: fetchOnThisDayBySlug,
        buttonText: 'Update On This Day',
        pathName: '/entry/on-this-day'
    },
    'translation': {
        getAdmin: getAdmin,
        getElementById: getTranslationById,
        getElementBySlug: () => false,
        getIsFetching: getIsFetchingTranslationIndividual,
        title: 'খেলা হবে! অনুবাদ',
        fetchActionById: fetchTranslationById,
        fetchActionBySlug: () => false,
        buttonText: 'Update translaton',
        pathName: '/entry/translation'
    },
};

export default individual;