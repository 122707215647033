import {
    combineReducers
} from 'redux';

import {
    actions
} from '../action-types/dictionary';

//const actions = giveActions();

const dictionary = (state = [], action) => {
    if (actions) {
        switch (action.type) {
            case actions.SUCCESS_FETCH_DICTIONARY:
                return action.response;
            default:
                return state;
        }
    }
    else return state;
};

const dictionaryIndividual = (state = [], action) => {
    if (actions) {
        switch (action.type) {
            case actions.SUCCESS_FETCH_DICTIONARY_BY_SLUG:
            case actions.SUCCESS_FETCH_DICTIONARY_BY_ID:
                return [action.response];
            default:
                return state;
        }
    }
    else return state;
};

const isFetchingIndividual = (state = false, action) => {
    if (actions) {
        switch (action.type) {
            case actions.REQUEST_FETCH_DICTIONARY_BY_ID:
            case actions.REQUEST_FETCH_DICTIONARY_BY_SLUG:
                return true;
            case actions.SUCCESS_FETCH_DICTIONARY_BY_ID:
            case actions.FAILURE_FETCH_DICTIONARY_BY_ID:
            case actions.SUCCESS_FETCH_DICTIONARY_BY_SLUG:
            case actions.FAILURE_FETCH_DICTIONARY_BY_SLUG:
                return false;
            default:
                return state;
        }
    }
    else return state;
};

const count = (state = 0, action) => {
    if (actions) {
        switch (action.type) {
            case actions.SUCCESS_COUNT_DICTIONARY:
                return action.response;
            default:
                return state;
        }
    }
    else return state;
}

const isFetching = (state = false, action) => {
    if (actions) {
        switch (action.type) {
            case actions.REQUEST_FETCH_DICTIONARY:
            case actions.REQUEST_CREATE_DICTIONARY:
            case actions.REQUEST_UPDATE_DICTIONARY:
            case actions.REQUEST_DELETE_DICTIONARY:
            case actions.REQUEST_COUNT_DICTIONARY:
                return true;
            case actions.SUCCESS_UPDATE_DICTIONARY:
            case actions.FAILURE_UPDATE_DICTIONARY:
            case actions.SUCCESS_DELETE_DICTIONARY:
            case actions.FAILURE_DELETE_DICTIONARY:
            case actions.FAILURE_COUNT_DICTIONARY:
            case actions.SUCCESS_COUNT_DICTIONARY:
            case actions.SUCCESS_CREATE_DICTIONARY:
            case actions.FAILURE_CREATE_DICTIONARY:
            case actions.SUCCESS_FETCH_DICTIONARY:
            case actions.FAILURE_FETCH_DICTIONARY:
                return false;
            default:
                return state;
        }
    }
    else return state;
};

export default combineReducers({
    dictionary,
    dictionaryIndividual,
    count,
    isFetching,
    isFetchingIndividual
});

export const getDictionary = state => (state.dictionary && state.dictionary) || [];

export const getIsFetchingIndividual = state => state.isFetchingIndividual;

export const getIsFetching = state => state.isFetching;

export const getDictionaryById = (state, id) => (state.dictionary && state.dictionary.length !== 0 && state.dictionary.find(i => i.id === id)) || '';

export const getDictionaryBySlug = (state, slug) => (state.dictionary && state.dictionary.length !== 0 && state.dictionary.find(i => i.slug === slug)) || '';

export const getCount = state => (state && state.count) || 0;