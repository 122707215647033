const tableCellTeam = [
    {
        name: 'অবস্থান',
        space: 1
    },
    {
        name: 'দল',
        space: 3
    },
    {
        name: 'ম্যাচ',
        space: 1
    },
    {
        name: 'রেটিং',
        space: 4
    },
    {
        name: 'পয়েন্ট',
        space: 1
    }
];

export default tableCellTeam;