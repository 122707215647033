const categories = [
    {
        Id: 0,
        Name: 'ওডিআই',
        Path: 'odi',
    },
    {
        Id: 1,
        Name: 'টেস্ট',
        Path: 'test',
    },
    {
        Id: 2,
        Name: 'টি২০',
        Path: 't20i',
    }
];

export default categories;