export const actions = {
    REQUEST_FETCH_TRANSLATION: 'REQUEST_FETCH_TRANSLATION',
    SUCCESS_FETCH_TRANSLATION: 'SUCCESS_FETCH_TRANSLATION',
    FAILURE_FETCH_TRANSLATION: 'FAILURE_FETCH_TRANSLATION',

    REQUEST_CREATE_TRANSLATION: 'REQUEST_CREATE_TRANSLATION',
    SUCCESS_CREATE_TRANSLATION: 'SUCCESS_CREATE_TRANSLATION',
    FAILURE_CREATE_TRANSLATION: 'FAILURE_CREATE_TRANSLATION',

    REQUEST_UPDATE_TRANSLATION: 'REQUEST_UPDATE_TRANSLATION',
    SUCCESS_UPDATE_TRANSLATION: 'SUCCESS_UPDATE_TRANSLATION',
    FAILURE_UPDATE_TRANSLATION: 'FAILURE_UPDATE_TRANSLATION',

    REQUEST_COUNT_TRANSLATION: 'REQUEST_COUNT_TRANSLATION',
    SUCCESS_COUNT_TRANSLATION: 'SUCCESS_COUNT_TRANSLATION',
    FAILURE_COUNT_TRANSLATION: 'FAILURE_COUNT_TRANSLATION',

    REQUEST_DELETE_TRANSLATION: 'REQUEST_DELETE_TRANSLATION',
    SUCCESS_DELETE_TRANSLATION: 'SUCCESS_DELETE_TRANSLATION',
    FAILURE_DELETE_TRANSLATION: 'FAILURE_DELETE_TRANSLATION',

    REQUEST_FETCH_TRANSLATION_BY_ID: 'REQUEST_FETCH_TRANSLATION_BY_ID',
    SUCCESS_FETCH_TRANSLATION_BY_ID: 'SUCCESS_FETCH_TRANSLATION_BY_ID',
    FAILURE_FETCH_TRANSLATION_BY_ID: 'FAILURE_FETCH_TRANSLATION_BY_ID',
};