import React from "react";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const Date_Picker = props => (
    <DateTimePicker
        variant="inline"
        color='primary'
        disablePast
        ampm
        {...props}
    />
);

export default Date_Picker;