const adminMenus = [
    {
        label: 'ব্লগ এন্ট্রি',
        link: '/entry/blog',
    },
    {
        label: 'কাউন্টডাউন এন্ট্রি',
        link: '/entry/counter',
    },
    {
        label: 'নোটিফিকেশন এন্ট্রি',
        link: '/entry/notification',
    },
    {
        label: 'নোটিশ এন্ট্রি',
        link: '/entry/notice',
    },
    {
        label: 'ক্রিকেট অভিধান এন্ট্রি',
        link: '/entry/dictionary',
    },
    {
        label: 'হাইলাইট এন্ট্রি',
        link: '/entry/highlight',
    },
    {
        label: 'সংবাদ এন্ট্রি',
        link: '/entry/news',
    },
    {
        label: 'ধারাভাষ্য এন্ট্রি',
        link: '/commentary-entry',
    },
    {
        label: 'স্ট্যাটিক কন্টেন্ট এন্ট্রি',
        link: '/entry/static-content',
    },
    {
        label: 'সংবাদ আবেদন এন্ট্রি',
        link: '/entry/news-request',
    },
    {
        label: 'এই দিনে এন্ট্রি',
        link: '/entry/on-this-day',
    },
    {
        label: 'অনুবাদ এন্ট্রি',
        link: '/entry/translation',
    },
    {
        label: 'অনুবাদ তালিকা',
        link: '/table/translation/0',
    },
    {
        label: 'সংবাদ আবেদন তালিকা',
        link: '/table/news-requests/0',
    },
    {
        label: 'কাউন্টডাউন তালিকা',
        link: '/table/counter/0',
    },
    {
        label: 'নোটিশ তালিকা',
        link: '/table/notice/0',
    },
];

export default adminMenus;