import React from 'react';
import {
	zoomIn
} from 'react-animations';
import {
	Grid,
	Typography,
} from '@mui/material';
import {
	makeStyles
} from '@mui/styles';
import styled, {
	keyframes
} from 'styled-components';

import Logo from './Logo';

const useStyles = makeStyles(
	theme => (
		{
			progress: {
				width: '100%',
				// maxHeight: '100vh',
				// minHeight: '50vh',
				height: 'auto',
				margin: '11px auto',
				padding: 'auto',
				display: 'flex',
				direction: 'column',
				justifyContent: 'center',
				color: theme.palette.primary.dark,
			},
		}
	)
);

const ZoomIn = styled.div`animation: 2s ${keyframes`${zoomIn}`} infinite`;

const Loading = (props) => {
	const classes = useStyles();

	return (
		<div
			{...props}
		>
			<ZoomIn
				className={classes.anime}
			>
				<Grid
					container
					direction="column"
					justify="flex-start"
					alignItems="center"
				>
					<Logo />
					<Typography
						variant='h5'
						align='center'
						display='block'
					>
						খেলা হবে!
					</Typography>
				</Grid>
			</ZoomIn>
			<Typography
				variant='subtitle1'
				align='center'
				display='block'
			>
				কিছুক্ষণ অপেক্ষা করুন
			</Typography>
		</div>
	);
}

export default Loading;
