const tableCellOthers = [
    {
        name: 'অবস্থান',
        space: 1
    },
    {
        name: 'সূচক',
        space: 1
    },
    {
        name: '',
        space: 1
    },
    {
        name: 'খেলোয়াড়',
        space: 4
    },
    {
        name: 'রেটিং',
        space: 1
    }
];

export default tableCellOthers;