import {
    countBlog,
    fetchBlogs,
} from '../../redux/actions/Blogs';
import {
    countDictionary,
    fetchDictionary,
} from '../../redux/actions/Dictionary';
import {
    fetchCounters,
} from '../../redux/actions/Counter';
import {
    countHighlights,
    fetchHighlights,
    fetchHighlightsByTags,
} from '../../redux/actions/Highlights';
import {
    countNews,
    fetchNews,
} from '../../redux/actions/News';
import {
    countNewsRequest,
    fetchNewsRequests,
} from '../../redux/actions/NewsRequest';
import {
    fetchNotices,
} from '../../redux/actions/Notice';
import {
    fetchOnThisDay,
} from '../../redux/actions/OnThisDay';
import {
    countTranslation,
    fetchTranslation,
} from '../../redux/actions/Translation';
import {
    getCount,
    getCounters,
    getIsFetchingCounters,
    getIsFetchingNotices,
    getBlogs,
    getHighlights,
    getHighlightsCount,
    getHighlightsByTags,
    getIsFetchingHighlights,
    getIsFetchingBlogs,
    getTranslationCount,
    getIsFetchingNews,
    getNews,
    getNewsCount,
    getNewsRequestCount,
    getNotices,
    getIsFetchingNewsRequest,
    getNewsRequests,
    getIsFetchingOnThisDay,
    getOnThisDay,
    getIsFetchingTranslations,
    getTranslations,
    getCountDictionary,
    getIsFetchingDictionary,
    getDictionary
} from '../../redux/reducers';

const table = {
    'blogs': {
        getElements: getBlogs,
        getCount: getCount,
        isFetching: getIsFetchingBlogs,
        pageName: 'খেলা হবে! ব্লগ',
        fetchAction: fetchBlogs,
        countAction: countBlog,
        height: 400,
        link: '/individualbyslug/blogs',
        isSlug: true,
        skip: 20,
        limit: 20
    },
    'dictionary': {
        getElements: getDictionary,
        getCount: getCountDictionary,
        isFetching: getIsFetchingDictionary,
        pageName: 'খেলা হবে! ক্রিকেট অভিধান',
        fetchAction: fetchDictionary,
        countAction: countDictionary,
        height: 340,
        link: '/individualbyslug/dictionary',
        isSlug: true,
        skip: 20,
        limit: 20
    },
    'counter': {
        getElements: getCounters,
        getCount: () => false,
        isFetching: getIsFetchingCounters,
        pageName: 'খেলা হবে! কাউন্টডাউন',
        fetchAction: fetchCounters,
        countAction: () => false,
        height: 200,
        link: '/individualbyid/counter',
        isSlug: false,
        skip: 0,
        limit: 10
    },
    'notice': {
        getElements: getNotices,
        getCount: () => false,
        isFetching: getIsFetchingNotices,
        pageName: 'খেলা হবে! নোটিশ',
        fetchAction: fetchNotices,
        countAction: () => false,
        height: 200,
        link: '/individualbyid/notice',
        isSlug: false,
        skip: 0,
        limit: 10
    },
    'highlights': {
        getElements: getHighlights,
        getCount: getHighlightsCount,
        isFetching: getIsFetchingHighlights,
        pageName: 'খেলা হবে! হাইলাইটস',
        fetchAction: fetchHighlights,
        countAction: countHighlights,
        height: 400,
        link: '/individualbyslug/highlights',
        isSlug: true,
        skip: 20,
        limit: 20
    },
    'highlights-tags': {
        getElements: getHighlightsByTags,
        getCount: () => false,
        isFetching: getIsFetchingHighlights,
        pageName: 'খেলা হবে! হাইলাইটস',
        fetchAction: fetchHighlightsByTags,
        countAction: () => false,
        height: 330,
        link: '/individualbyslug/highlights-tags',
        isSlug: true,
        skip: 20,
        limit: 20
    },
    'news': {
        getElements: getNews,
        getCount: getNewsCount,
        isFetching: getIsFetchingNews,
        pageName: 'খেলা হবে! সংবাদ',
        fetchAction: fetchNews,
        countAction: countNews,
        height: 360,
        link: '/individualbyslug/news',
        isSlug: true,
        skip: 20,
        limit: 20
    },
    'news-requests': {
        getElements: getNewsRequests,
        getCount: getNewsRequestCount,
        isFetching: getIsFetchingNewsRequest,
        pageName: 'খেলা হবে! সংবাদ আবেদন',
        fetchAction: fetchNewsRequests,
        countAction: countNewsRequest,
        height: 100,
        link: '/individualbyid/news-requests',
        isSlug: false,
        skip: 20,
        limit: 20
    },
    'on-this-day': {
        getElements: getOnThisDay,
        getCount: () => false,
        isFetching: getIsFetchingOnThisDay,
        pageName: 'খেলা হবে! ইতিহাসে এই দিনে',
        fetchAction: fetchOnThisDay,
        countAction: () => false,
        height: 100,
        link: '/individualbyslug/on-this-day',
        isSlug: true,
        skip: 0,
        limit: 0
    },
    'translation': {
        getElements: getTranslations,
        getCount: getTranslationCount,
        isFetching: getIsFetchingTranslations,
        pageName: 'খেলা হবে! অনুবাদ',
        fetchAction: fetchTranslation,
        countAction: countTranslation,
        height: 50,
        link: '/individualbyid/translation',
        isSlug: false,
        skip: 200,
        limit: 200
    },
};

export default table;