export const actions = {
    REQUEST_FETCH_MATCH: 'REQUEST_FETCH_MATCH',
    SUCCESS_FETCH_MATCH: 'SUCCESS_FETCH_MATCH',
    FAILURE_FETCH_MATCH: 'FAILURE_FETCH_MATCH',

    REQUEST_FETCH_MATCH_WITH_INNINGS: 'REQUEST_FETCH_MATCH_WITH_INNINGS',
    SUCCESS_FETCH_MATCH_WITH_INNINGS: 'SUCCESS_FETCH_MATCH_WITH_INNINGS',
    FAILURE_FETCH_MATCH_WITH_INNINGS: 'FAILURE_FETCH_MATCH_WITH_INNINGS',

    REQUEST_FETCH_INNINGS: 'REQUEST_FETCH_INNINGS',
    SUCCESS_FETCH_INNINGS: 'SUCCESS_FETCH_INNINGS',
    FAILURE_FETCH_INNINGS: 'FAILURE_FETCH_INNINGS',

    REQUEST_FETCH_ENDOFOVER: 'REQUEST_FETCH_ENDOFOVER',
    SUCCESS_FETCH_ENDOFOVER: 'SUCCESS_FETCH_ENDOFOVER',
    FAILURE_FETCH_ENDOFOVER: 'FAILURE_FETCH_ENDOFOVER',
};