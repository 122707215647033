const arr = [
    {
        id: 0,
        name: 'সারাংশ',
        path: 'summary',
    },
    {
        id: 1,
        name: 'ধারাভাষ্য',
        path: 'commentary'
    },
    {
        id: 2,
        name: 'স্কোরকার্ড',
        path: 'scorecard',
    },
    {
        id: 3,
        name: 'স্কোয়াড',
        path: 'squad',
    },
    {
        id: 4,
        name: 'সর্বশেষ',
        path: 'latest',
    },
    {
        id: 5,
        name: 'পরিসংখ্যান',
        path: 'statistics',
    },
];

export default arr;