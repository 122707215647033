import React from 'react';
import ClampLines from 'react-clamp-lines';
import {
    Link,
} from 'react-router-dom';
import {
    Grid,
    Paper,
    Table,
    TableFooter,
    TablePagination,
    TableRow,
    Typography,
    useTheme,
} from '@mui/material';
import {
    makeStyles
} from '@mui/styles';
import PropTypes from 'prop-types';

import {
    BannerForPage,
    Chip,
    DateAndTimeExtract,
    Loading,
    RichText,
    TablePaginationActions
} from '.';
import Banner from './liveScoreInfo/Banner';
import NotFound from '../components/notFound';
import Icon from '../assets/KhelahobeLogo.png';
import {
    dfs
} from '../Api';

const useStyles = makeStyles(
    theme => (
        {
            root: {
                width: '100%',
                margin: '100px auto',
                color: theme.palette.primary.dark,
                [theme.breakpoints.down('md')]: {
                    margin: '70px auto',
                },
            },
            gridlist: {
                width: '100%',
                height: 'auto',
                margin: '0px auto',
                justifyContent: 'center'
            },
            table: {
                width: '100%',
                // marginTop: theme.spacing(1),
                margin: '20px auto',
            },
            row: {
                width: '100%',
                height: 'auto',
                margin: '15px',
                // backgroundColor: 'white',
                '& .RichEditor-root': {
                    paddingBottom: 15,
                    paddingLeft: 15,
                },
                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                borderRadius: '8px',
                // borderColor: '#DCDCDC',
                '& .MuiGridListTile-tile': {
                    paddingTop: theme.spacing(2),
                }
            },
            media: {
                display: 'block',
                objectFit: 'contain',
                verticalAlign: 'middle',
                width: '90%',
                height: '90%',
                margin: '0 auto 8px auto',
            },
            div: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                height: '100%',
                margin: '0px auto',
                alignItems: 'flex-start',
                color: theme.palette.secondary.dark,
            },
            pagination: {
                '& .MuiTablePagination-spacer': {
                    flex: '0 0 0'
                },
                '& div:last-child > :last-child': {
                    align: 'center',
                    margin: '0 auto',
                }
            },
            link: {
                color: theme.palette.secondary.dark,
                textDecoration: 'none',
                width: '100%',
                height: '100%',
            },
            divImage: {
                height: '100%',
                overflow: 'hidden',
                margin: '0 auto'
            },
            linkimage: {
                width: '100%',
                color: theme.palette.primary.dark,
                textDecoration: 'none',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundOrigin: 'center',
                backgroundPosition: 'center',
                minHeight: '200px',
                maxHeight: '400px',
                height: 'auto',
            },
            clampline: {
                paddingBottom: 15,
                paddingLeft: 15
            },
            problem: {
                width: '100%',
                height: '30vh',
            },
            loading: {
                height: '100vh',
                marginTop: 120
            }
        }
    )
);

const Tables = ({
    admin,
    cells,
    count,
    height,
    isFetching,
    isSlug,
    link,
    page,
    pageName,
    setPage,
    slug,
    tag,
    width,
}) => {
    // const colN = col[width];
    const classes = useStyles();
    const theme = useTheme();
    function handleChangePage(event, newPage) {
        setPage(newPage);
    };

    if (page < 0 || page > ((count / 20))) return <NotFound />

    if (isFetching || !cells) return <Loading
        className={classes.loading}
    />;

    if (
        !cells.length
        ||
        cells === 'undefined'
    ) {
        return <Banner
            children={
                slug === "on-this-day"
                    ?
                    "দুঃখিত! আজকের দিনে কোন এন্ট্রি নেই।"
                    :
                    "কোন সমস্যা হয়েছে! কিছুক্ষণ পর আবার চেষ্টা করুন"
            }
            variant='h6'
            align='center'
            className={classes.problem}
            sx={{
                marginTop: '120px'
            }}
        />
    }
    return (
        <Paper
            elevation={0}
            className={classes.root}
            sx={{
                backgroundColor: '#eceff1',
            }}
        >
            <BannerForPage
                banner={pageName}
                align='center'
            />
            <Table className={classes.table}>
                {/* <TableBody> */}
                <Grid
                    container
                    justifyContent='center'
                >
                    {
                        cells.map((
                            b,
                            index
                        ) => {
                            const richText = b.intro || b.story || b.summary;
                            const img = (b.images && b.images[0]) || b.image;
                            let y = '';
                            if (typeof b.date === 'string') {
                                let [, , year] = b.date.split("-");
                                y = dfs(year);
                            }
                            return (
                                // <Masonry
                                //     // cellHeight={240}
                                //     className={classes.gridlist}
                                //     // cols={colN}
                                //     columns={xs ? 1 : (sm ? 2 : (md ? 3 : (lg ? 3 : 3)))}
                                //     spacing={0}
                                //     defaultHeight={height}
                                //     sx={{
                                //         height
                                //     }}
                                // >
                                <Grid
                                    className={classes.row}
                                    item
                                    sm={4}
                                    md={3}
                                    sx={{
                                        height,
                                        backgroundColor: 'white',
                                        borderRadius: '8px',
                                        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                                        padding: theme.spacing(2),
                                        '&:hover': {
                                            // transform: 'scale(1.03)',
                                            boxShadow: '0px 5px 2px -3px #33691e'
                                        },
                                        WebkitTransition: 'box-shadow 0.5s fade-in',
                                        MozTransition: 'box-shadow 0.5s fade-in',
                                        MsTransition: 'box-shadow 0.5s fade-in',
                                        OTransition: 'box-shadow 0.5s fade-in',
                                        transition: 'box-shadow 0.5s fade-in',
                                        margin: theme.spacing(1)
                                    }}
                                    key={index}
                                >
                                    <Link
                                        to={{
                                            pathname: isSlug ? `${link}/${b.slug || b.blog_slug}` : `${link}/${b.id}`,
                                            state: b.id,
                                        }}
                                        className={classes.link}
                                    >
                                        <div className={classes.div}>
                                            {
                                                b.title
                                                &&
                                                <Typography
                                                    variant="subtitle1"
                                                    color='inherit'
                                                    style={{
                                                        whiteSpace: 'pre-line',
                                                        // paddingLeft: 15,
                                                        color: theme.palette.primary.dark
                                                    }}
                                                >
                                                    {b.title}
                                                </Typography>
                                            }
                                            {
                                                slug === 'on-this-day'
                                                &&
                                                <Typography
                                                    color='inherit'
                                                    align='center'
                                                    variant='h6'
                                                    style={{
                                                        whiteSpace: 'pre-line',
                                                        // paddingLeft: 15,
                                                        color: theme.palette.primary.dark
                                                    }}
                                                >
                                                    {`${y} এর এই দিনে`}
                                                </Typography>
                                            }
                                            {/* {
                                                b.published_date
                                                &&
                                                <Typography
                                                    variant="body2"
                                                    color='textSecondary'
                                                    style={{
                                                        whiteSpace: 'pre-line',
                                                        paddingLeft: 15
                                                    }}
                                                    gutterBottom
                                                >
                                                    {b.published_date}
                                                </Typography>
                                            } */}
                                            {
                                                b.created_at
                                                &&
                                                slug === 'blogs'
                                                &&
                                                <DateAndTimeExtract
                                                    variant="body2"
                                                    color='textSecondary'
                                                    sx={{
                                                        whiteSpace: 'pre-line',
                                                        // paddingLeft: theme.spacing(2)
                                                    }}
                                                    time={b.created_at}
                                                />
                                            }
                                            {
                                                b.bangla
                                                &&
                                                <Typography
                                                    variant="body2"
                                                    color='textSecondary'
                                                    style={{
                                                        whiteSpace: 'pre-line',
                                                        // paddingLeft: 15
                                                    }}
                                                    gutterBottom
                                                >
                                                    {b.english}: {b.bangla}
                                                </Typography>
                                            }
                                            {
                                                (
                                                    img
                                                    &&
                                                    <div
                                                        className={classes.divImage}
                                                    >
                                                        <img
                                                            src={img}
                                                            alt={b.title}
                                                            className={classes.media}
                                                        />
                                                    </div>
                                                )
                                                ||
                                                <div
                                                    className={classes.divImage}
                                                >
                                                    <img
                                                        src={Icon}
                                                        alt={b.title}
                                                        className={classes.media}
                                                    />
                                                </div>
                                            }
                                            {
                                                b.youtube_id
                                                &&
                                                <div
                                                    align='center'
                                                    className={classes.linkimage}
                                                    style={{
                                                        backgroundImage: `url(https://img.youtube.com/vi/${b.youtube_id}/0.jpg)`,
                                                    }}
                                                >
                                                </div>
                                            }
                                            {
                                                b.tags && b.tags.length > 0
                                                &&
                                                <Chip
                                                    data={b.tags}
                                                    flag={link === 'highlights' && 1}
                                                />
                                            }
                                            {
                                                b.description
                                                &&
                                                <ClampLines
                                                    text={b.description}
                                                    id={b.id}
                                                    lines={2}
                                                    ellipsis='...'
                                                    buttons={false}
                                                    className={classes.clampline}
                                                />
                                            }
                                            {
                                                richText
                                                &&
                                                <RichText
                                                    value={JSON.parse(richText)}
                                                    readOnly
                                                    flag={1} //condition for clampline render
                                                />
                                            }
                                        </div>
                                    </Link>
                                </Grid>
                                // </Masonry>

                            )
                        }
                        )
                    }
                </Grid>
                {/* </TableBody> */}
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[]}
                            colSpan={3}
                            count={parseInt(count)}
                            rowsPerPage={20}
                            page={page}
                            labelDisplayedRows={() => { }}
                            labelRowsPerPage=""
                            link={link}
                            SelectProps={{
                                inputProps: { 'aria-label': 'Rows per page' },
                                //native: true,
                            }}
                            onPageChange={handleChangePage}
                            ActionsComponent={
                                props => <TablePaginationActions
                                    {...props}
                                    link={`/table/${slug}`}
                                    slug={slug}
                                    tag={tag}
                                />
                            }
                            className={classes.pagination}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </Paper>
    );
}

Tables.propTypes = {
    cells: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired
};

export default Tables;