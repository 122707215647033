const tableCellOthers = [
    {
        name: 'ব্যাটার',
        space: 2,
        align: 'left'
    },
    {
        name: '',
        space: 3,
        align: 'center'
    },
    {
        name: 'রান',
        space: 1,
        align: 'center'
    },
    {
        name: 'বল',
        space: 1,
        align: 'center'
    },
    {
        name: 'চার',
        space: 1,
        align: 'center'
    },
    {
        name: 'ছক্কা',
        space: 1,
        align: 'center'
    },
    {
        name: 'স্ট্রাইক রেট',
        space: 1,
        align: 'center'
    },
];

export default tableCellOthers;