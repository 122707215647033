import {
    getToken
} from '../reducers';
import {
    endPoints
} from '../../Api';
import {actions} from "../action-types/dictionary";


export const fetchDictionary = (skip, limit) => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_FETCH_DICTIONARY,
            actions.SUCCESS_FETCH_DICTIONARY,
            actions.FAILURE_FETCH_DICTIONARY
        ],
        method: endPoints.FETCH_DICTIONARY,
        race: () => false,
        data: {
            skip,
            limit,
            // token: getToken(getState())
        }
    }
});

export const fetchDictionaryById = id => ({
    RSAA: {
        types: [
            actions.REQUEST_FETCH_DICTIONARY_BY_ID,
            actions.SUCCESS_FETCH_DICTIONARY_BY_ID,
            actions.FAILURE_FETCH_DICTIONARY_BY_ID,
        ],
        method: endPoints.FETCH_DICTIONARY_BY_ID,
        race: () => false,
        data: {
            id,
        },
    },
});

export const fetchDictionaryBySlug = slug => ({
    RSAA: {
        types: [
            actions.REQUEST_FETCH_DICTIONARY_BY_SLUG,
            actions.SUCCESS_FETCH_DICTIONARY_BY_SLUG,
            actions.FAILURE_FETCH_DICTIONARY_BY_SLUG,
        ],
        method: endPoints.FETCH_DICTIONARY_BY_SLUG,
        race: () => false,
        data: {
            slug,
        },
    },
});

export const createDictionary = state => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_CREATE_DICTIONARY,
            actions.SUCCESS_CREATE_DICTIONARY,
            actions.FAILURE_CREATE_DICTIONARY
        ],
        method: endPoints.CREATE_DICTIONARY,
        race: () => false,
        data: {
            ...state,
            images: [state.images],
            token: getToken(getState()),
            sequence: parseInt(state.sequence)
        }
    }
});

export const updateDictionary = state => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_UPDATE_DICTIONARY,
            actions.SUCCESS_UPDATE_DICTIONARY,
            actions.FAILURE_UPDATE_DICTIONARY
        ],
        method: endPoints.UPDATE_DICTIONARY,
        race: () => false,
        data: {
            ...state,
            images: [state.images],
            token: getToken(getState()),
            id: state.id,
            sequence: parseInt(state.sequence)
        }
    }
});

export const deleteDictionary = id => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_DELETE_DICTIONARY,
            actions.SUCCESS_DELETE_DICTIONARY,
            actions.FAILURE_DELETE_DICTIONARY
        ],
        method: endPoints.DELETE_DICTIONARY,
        race: () => false,
        data: {
            id,
            token: getToken(getState()),
        }
    }
});

export const countDictionary = () => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_COUNT_DICTIONARY,
            actions.SUCCESS_COUNT_DICTIONARY,
            actions.FAILURE_COUNT_DICTIONARY,
        ],
        method: endPoints.COUNT_DICTIONARY,
        race: () => false,
    },
});