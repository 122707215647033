export const actions = {
    REQUEST_FETCH_COMMENTARY_BY_ID: 'REQUEST_FETCH_COMMENTARY_BY_ID',
    SUCCESS_FETCH_COMMENTARY_BY_ID: 'SUCCESS_FETCH_COMMENTARY_BY_ID',
    FAILURE_FETCH_COMMENTARY_BY_ID: 'FAILURE_FETCH_COMMENTARY_BY_ID',

    REQUEST_CREATE_COMMENTARY: 'REQUEST_CREATE_COMMENTARY',
    SUCCESS_CREATE_COMMENTARY: 'SUCCESS_CREATE_COMMENTARY',
    FAILURE_CREATE_COMMENTARY: 'FAILURE_CREATE_COMMENTARY',

    REQUEST_UPDATE_COMMENTARY: 'REQUEST_UPDATE_COMMENTARY',
    SUCCESS_UPDATE_COMMENTARY: 'SUCCESS_UPDATE_COMMENTARY',
    FAILURE_UPDATE_COMMENTARY: 'FAILURE_UPDATE_COMMENTARY',

    REQUEST_FETCH_COMMENTARIES: 'REQUEST_FETCH_COMMENTARIES',
    SUCCESS_FETCH_COMMENTARIES: 'SUCCESS_FETCH_COMMENTARIES',
    FAILURE_FETCH_COMMENTARIES: 'FAILURE_FETCH_COMMENTARIES',

    REQUEST_DELETE_COMMENTARY: 'REQUEST_DELETE_COMMENTARY',
    SUCCESS_DELETE_COMMENTARY: 'SUCCESS_DELETE_COMMENTARY',
    FAILURE_DELETE_COMMENTARY: 'FAILURE_DELETE_COMMENTARY',
}