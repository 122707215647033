import {
    getID,
    getLoginIsFetching,
    getToken,
} from '../reducers';
import {
    endPoints
} from '../../Api';
import {actions} from "../action-types/login";

export const giveActions = () => {
    return actions ; 
}

export const login = ({
    user_name,
    password
}) => async (
    dispatch,
    getState
) => {
        await dispatch({
            RSAA: {
                types: [
                    actions.REQUEST_LOGIN,
                    actions.SUCCESS_LOGIN,
                    actions.FAILURE_LOGIN
                ],
                method: endPoints.LOGIN,
                race: getLoginIsFetching,
                data: {
                    user_name,
                    password
                }
            }
        });

        const token = getToken(getState());

        await dispatch({
            RSAA: {
                types: [
                    actions.REQUEST_FETCH_ID,
                    actions.SUCCESS_FETCH_ID,
                    actions.FAILURE_FETCH_ID
                ],
                method: endPoints.FETCH_ID,
                race: getLoginIsFetching,
                data: {
                    token,
                }
            }
        });

        const id = getID(getState());

        await dispatch({
            RSAA: {
                types: [
                    actions.REQUEST_FETCH_PROFILE,
                    actions.SUCCESS_FETCH_PROFILE,
                    actions.FAILURE_FETCH_PROFILE
                ],
                method: endPoints.FETCH_PROFILE,
                race: getLoginIsFetching,
                data: {
                    id,
                    token
                }
            }
        })
    };

export const logout = () => ({
    RSAA: {
        types: [
            actions.REQUEST_LOGOUT,
            actions.SUCCESS_LOGOUT,
            actions.FAILURE_LOGOUT
        ],
        method: endPoints.LOGOUT,
        race: () => false,
    },
})