import React, {
	useEffect,
} from 'react';
import ReactGA from 'react-ga';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
	BrowserRouter as Router
} from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { withStyles } from '@mui/styles';

import Routes from './routes';
import AutoScrollToTop from '../utils/AutoScrollToTop.jsx';
import {
	saveState
} from '../utils/LocalStorage.js';
import UpdateChecker from '../SWUpdateWrapper';
// import Background from '../assets/khelahobeBackground.jpg';
import { CssBaseline } from '@mui/material';

const theme = createTheme({
	palette: {
		primary: {
			main: '#e8f5e9', //lighter green
			dark: '#1b5e20', //darker green
			light: '#e8f5e9' //lightest green for poppers
		},
		secondary: {
			main: '#616161',
			dark: '#37474f', //darker color for font
		},
	},
	typography: {
		fontFamily: "Kalpurush",
		useNextVariants: true,
	},
	components: {
		// Name of the component
		MuiButton: {
			styleOverrides: {
				// Name of the slot
				root: {
					// Some CSS
					borderRadius: '5px'
				},
			},
		}
	}
});

const styles = {
	'@global': {
		'html, body, #root': {
			width: '100%',
			maxWidth: '100vw',
			height: 'auto',
			minHeight: '100vh',
			// overflowX: 'hidden',
			padding: 0,
			margin: 0,
			backgroundColor: '#eceff1',
		},
	},
};

ReactGA.initialize('UA-141649180-1');
ReactGA.pageview("/ranking");

const version = 3;

const App = ({ store }) => {

	const versionrunning = localStorage.getItem('version');

	useEffect(
		() => {
			const f = () => saveState(store.getState());

			return store.subscribe(f);	//TO-DO: what does useEffect in details? 
		}, []
	);

	useEffect(
		() => {
			localStorage.setItem('version', version);
		},
		[
			// version
		]
	);

	useEffect(
		() => {
			// console.log('hi locaclstorage', versionrunning, version);
			if (versionrunning !== version || version === undefined || version === null) {
				localStorage.clear();
				localStorage.setItem('version', version);
				caches.keys().then(function (cacheNames) {
					cacheNames.forEach(function (cacheName) {
						// console.log('hi cache', cacheName)
						caches.delete(cacheName);
					});
				});
			}
		},
		[
			// version,
			versionrunning
		]
	)

	// useEffect(
	// 	() => {
	// 		// setToken(firebaseToken);
	// 		if (token !== '') {
	// 			dispatch(
	// 				sendToken(token)
	// 			);
	// 		}
	// 	},
	// 	[
	// 		token,
	// 		dispatch
	// 	]
	// );



	// useEffect(
	// 	() => {
	// 		dispatch(
	// 			sendToken(token)
	// 		);
	// 	},
	// 	[
	// 		dispatch,
	// 		token
	// 	]
	// );

	return (
		<UpdateChecker>
			<Router
				basename="/"
			>
				<ThemeProvider theme={theme}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<AutoScrollToTop>
							<CssBaseline />
							<Routes />
						</AutoScrollToTop>
						{/* </MuiPickersUtilsProvider> */}
					</LocalizationProvider>
				</ThemeProvider>
			</Router>
		</UpdateChecker>
	)
};

export default withStyles(styles)(App);