import React, {
    useEffect,
    useRef,
    useState
} from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    ClickAwayListener,
    Grow,
    IconButton,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from '@mui/material';
import {
    makeStyles
} from '@mui/styles';
import {
    AccountCircle
} from '@mui/icons-material';
import {
    Link
} from '../../commonComponent';
import {
    getUserName
} from '../../redux/reducers';
import {
    logout
} from '../../redux/actions/Login';

const useStyles = makeStyles(
    theme => (
        {
            color: {
                color: theme.palette.primary.dark,
                textDecoration: 'none',
                textTransform: 'capitalize',
                minWidth: 'auto'
            },
            paper: {
                backgroundColor: theme.palette.primary.light,
                minWidth: 100,
                color: theme.palette.primary.dark
            },
            popper: {
                zIndex: 1200,
            }
        }
    )
);

const ProfileActions = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [
        open,
        setOpen
    ] = useState(false);
    const anchorRef = useRef(null);
    const name = useSelector(
        state => getUserName(state)
    );

    const handleToggle = () => {
        setOpen(
            prevOpen => !prevOpen
        );
    };

    const handleClose = event => {
        if (
            anchorRef.current
            &&
            anchorRef.current.contains(
                event.target
            )
        ) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(
        () => {
            if (
                prevOpen.current === true
                &&
                open === false
            ) {
                anchorRef.current.focus();
            }

            prevOpen.current = open;
        },
        [
            open
        ]
    );

    return (
        <>
            <IconButton
                ref={anchorRef}
                aria-controls={
                    open
                        ?
                        'menu-list-grow'
                        :
                        undefined
                }
                aria-haspopup="true"
                onClick={handleToggle}
                color='primary'
            >
                <AccountCircle />
            </IconButton>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                transition
                className={classes.popper}
            >
                {
                    (
                        {
                            TransitionProps,
                            placement
                        }
                    ) => (
                        <Grow
                            {...TransitionProps}
                            style={
                                {
                                    transformOrigin: placement === 'bottom'
                                        ?
                                        'center top'
                                        :
                                        'center bottom'
                                }
                            }
                        >
                            <Paper
                                className={classes.paper}
                            >
                                <ClickAwayListener
                                    onClickAway={handleClose}
                                >
                                    <MenuList
                                        autoFocusItem={open}
                                        className={classes.color}
                                        id="menu-list-grow"
                                        onKeyDown={handleListKeyDown}
                                    >
                                        <Link
                                            to="/userprofile"
                                        >
                                            <MenuItem
                                                // component={Link}
                                                className={classes.color}
                                                onClick={handleClose}
                                            >
                                                {name}
                                            </MenuItem>
                                        </Link>

                                        <MenuItem
                                            className={classes.color}
                                            onClick={
                                                state => dispatch(
                                                    logout(state)
                                                )
                                            }
                                        >
                                            লগ আউট
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )
                }
            </Popper>
        </>
    );
};

export default ProfileActions;