const Months = {
    'January': 'জানুয়ারি',
    'February': 'ফেব্রুয়ারি',
    'March': 'মার্চ',
    'April': 'এপ্রিল',
    'May': 'মে',
    'June': 'জুন',
    'July': 'জুলাই',
    'August': 'আগস্ট',
    'September': 'সেপ্টেম্বর',
    'October': 'অক্টোবর',
    'November': 'নভেম্বর',
    'December': 'ডিসেম্বর',
    'January,': 'জানুয়ারি,',
    'February,': 'ফেব্রুয়ারি,',
    'March,': 'মার্চ,',
    'April,': 'এপ্রিল,',
    'May,': 'মে,',
    'June,': 'জুন,',
    'July,': 'জুলাই,',
    'August,': 'আগস্ট,',
    'September,': 'সেপ্টেম্বর,',
    'October,': 'অক্টোবর,',
    'November,': 'নভেম্বর,',
    'December,': 'ডিসেম্বর,',
    '0': 'জানুয়ারি',
    '1': 'ফেব্রুয়ারি',
    '2': 'মার্চ',
    '3': 'এপ্রিল',
    '4': 'মে',
    '5': 'জুন',
    '6': 'জুলাই',
    '7': 'আগস্ট',
    '8': 'সেপ্টেম্বর',
    '9': 'অক্টোবর',
    '10': 'নভেম্বর',
    '11': 'ডিসেম্বর',
};

export default Months;