import React from 'react';
import {
	flash,
	fadeIn
} from 'react-animations';
import clsx from 'clsx';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableRow,
	useTheme
} from '@mui/material';
import {
	makeStyles
} from '@mui/styles';
import { styled as styless } from '@mui/material/styles';
import {
	Brightness1
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import styled, {
	keyframes
} from 'styled-components';

import {
	Flag
} from '../../commonComponent';

const CustomTableCell = styless(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		color: theme.palette.secondary.dark,
		paddingRight: 4,
		paddingLeft: 5,
		minHeight: '75px',
		maxHeight: '75px',
		height: '75px',
	},
}));

const useStyles = makeStyles(
	theme => (
		{
			root: {
				height: 'auto',
				width: '100%',
				overflowX: 'auto',
				color: theme.palette.secondary.dark,
				backgroundColor: '#FAFAFA'
			},
			colorsAnother: {
				backgroundColor: '#FFFAFA'
			},
			table: {
				color: theme.palette.secondary.dark,
				boxShadow: '0px',
				'& .MuiSvgIcon-root': {
					width: '0.5em',
					height: '0.5em'
				}
			},
			img: {
				height: '40px',
				width: '40px',
				padding: theme.spacing(1),
				verticalAlign: 'middle',
			},
			colors: {
				color: theme.palette.primary.dark,
				fontWeight: 900,
				backgroundColor: theme.palette.primary.light
			},
			pulse: {
				color: '#ab003c'
			},
			test: {
				'& .MuiTableBody-root': {
					height: '75px',
					minHeight: '75px',
					maxHeight: '75px'
				},
				'& .MuiTableRow-root': {
					height: '75px',
					minHeight: '75px',
					maxHeight: '75px'
				},
				'& .MuiTableCell-root': {
					height: '75px',
					minHeight: '75px',
					maxHeight: '75px'
				}
			}
		}
	)
);

const Pulse0 = styled.div`animation: 3s ${keyframes`${flash}`} infinite`;
const Pulse1 = styled.div`animation: 1s ${keyframes`${fadeIn}`}`;
// const areEqual = (prevProps, nextProps) => true;

const TableForLiveScore = React.memo(({
	total,
	out,
	over,
	total1,
	out1,
	over1,
	total2,
	out2,
	over2,
	team,
	flag,
	winning,
	running,
	overs
}) => {
	let o = '';
	if (out !== '১০') { o = `/${out}` }

	let test1 = '';
	if (out1 !== '১০') { test1 = `/${out1}` }

	let test2 = '';
	if (out2 !== '১০') { test2 = `/${out2}` }

	const classes = useStyles();
	const theme = useTheme();

	return (
		<Paper
			elevation={0}
			className={classes.root}
		>
			<Table className={classes.table}>
				<TableBody>
					<TableRow
						className={
							clsx(
								{
									[classes.colorsAnother]: winning === '' || winning !== team,
									[classes.colors]: winning !== '' && winning === team,
									[classes.test]: total1
								}
							)
						}
					>
						<CustomTableCell
							width="50%"
							color="primary"
							className={
								clsx(
									{
										[classes.colors]: winning !== '' && winning === team
									}
								)
							}
							style={{
								minHeight: '75px',
								maxHeight: '80px',
								height: '75px',
								display: 'inline-flex',
								width: '100%',
								color: (winning !== '' && winning === team) && theme.palette.primary.dark,
								fontWeight: (winning !== '' && winning === team) && 900,
								// backgroundColor: (winning !== '' && winning === team) && theme.palette.primary.light
							}}
						>
							<Flag
								country={team}
								team={false}
							/>
							{team}
							{
								running
								&&
								<Pulse0
									style={{
										paddingLeft: 3
									}}
								>
									<Brightness1
										className={classes.pulse}
									/>
								</Pulse0>
							}
						</CustomTableCell>
						<CustomTableCell
							width="50%"
							align="right"
							className={
								clsx(
									{
										[classes.colors]: winning !== '' && winning === team,
									}
								)
							}
							style={{
								color: (winning !== '' && winning === team) && theme.palette.primary.dark,
								fontWeight: (winning !== '' && winning === team) && 900,
								// backgroundColor: (winning !== '' && winning === team) && theme.palette.primary.light
							}}
						>
							<Pulse1
								key={
									running &&
									Math.random()}
							>
								{
									flag === '0'
									&&
									(
										(over !== "০")
											?
											(
												`${total}${o} (${over}/${overs} ওভার)`
											)
											:
											null
									)
								}
								{
									flag === '1'
									&&
									(
										(
											over1 !== "০"
											&&
											total1 !== "০"
										)
											?
											(
												`${total1}${test1} (${over1} ওভার)`
											)
											:
											null
									)
								}
								{
									flag === '1'
									&&
									(
										(
											over2 !== "০"
											&&
											total2 !== "০"
										)
											?
											(
												` এবং ${total2}${test2} (${over2} ওভার)`
											)
											:
											null
									)
								}
							</Pulse1>
						</CustomTableCell>
					</TableRow>
				</TableBody>
			</Table>
		</Paper>
	);
})

TableForLiveScore.propTypes = {
	team: PropTypes.string.isRequired,
};

export default TableForLiveScore;