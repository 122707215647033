import {
    getIsFetchingOnThisDay,
    getToken
} from '../reducers';
import {
    endPoints
} from '../../Api';
import {actions} from "../action-types/onThisDay";

export const fetchOnThisDay = onthisday => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_FETCH_ON_THIS_DAY,
            actions.SUCCESS_FETCH_ON_THIS_DAY,
            actions.FAILURE_FETCH_ON_THIS_DAY
        ],
        method: endPoints.FETCH_ON_THIS_DAY,
        race: () => false,
        data: {
            onthisday
        }
    }
});

export const fetchOnThisDayById = id => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_FETCH_ON_THIS_DAY_BY_ID,
            actions.SUCCESS_FETCH_ON_THIS_DAY_BY_ID,
            actions.FAILURE_FETCH_ON_THIS_DAY_BY_ID
        ],
        method: endPoints.FETCH_ON_THIS_DAY_BY_ID,
        race: () => false,
        data: {
            id
        }
    }
});

export const fetchOnThisDayBySlug = slug => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_FETCH_ON_THIS_DAY_BY_SLUG,
            actions.SUCCESS_FETCH_ON_THIS_DAY_BY_SLUG,
            actions.FAILURE_FETCH_ON_THIS_DAY_BY_SLUG
        ],
        method: endPoints.FETCH_ON_THIS_DAY_BY_SLUG,
        race: () => false,
        data: {
            slug
        }
    }
});

export const createOnThisDay = state => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_CREATE_ON_THIS_DAY,
            actions.SUCCESS_CREATE_ON_THIS_DAY,
            actions.FAILURE_CREATE_ON_THIS_DAY
        ],
        method: endPoints.CREATE_ON_THIS_DAY,
        race: () => false,
        data: {
            ...state,
            players: state.players.split(','),
            token: getToken(getState())
        }
    }
});

export const updateOnThisDay = state => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_UPDATE_ON_THIS_DAY,
            actions.SUCCESS_UPDATE_ON_THIS_DAY,
            actions.FAILURE_UPDATE_ON_THIS_DAY
        ],
        method: endPoints.UPDATE_ON_THIS_DAY,
        race: () => false,
        data: {
            ...state,
            players: state.players.split(','),
            token: getToken(getState()),
            id: state.id
        }
    }
});

export const deleteOnThisDay = id => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_DELETE_ON_THIS_DAY,
            actions.SUCCESS_DELETE_ON_THIS_DAY,
            actions.FAILURE_DELETE_ON_THIS_DAY
        ],
        method: endPoints.DELETE_ON_THIS_DAY,
        race: getIsFetchingOnThisDay,
        data: {
            id,
            token: getToken(getState()),
        }
    }
});

export const fetchAllOnThisDay = () => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_FETCH_ALL_ON_THIS_DAY,
            actions.SUCCESS_FETCH_ALL_ON_THIS_DAY,
            actions.FAILURE_FETCH_ALL_ON_THIS_DAY,
        ],
        method: endPoints.FETCH_ALL_ON_THIS_DAY,
        race: getIsFetchingOnThisDay,
    },
});