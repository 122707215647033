import React from 'react';
import {
    Button,
} from '@mui/material';
import {
    makeStyles
} from '@mui/styles';

const useStyles = makeStyles(
    theme => (
        {
            buttons: {
                margin: theme.spacing(1),
                color: theme.palette.primary.dark,
                textAlign: 'center',
                fontWeight: 600
            },
        }
    )
);

const CustomLink = ({
    text,
    ...props
}) => {
    const classes = useStyles();

    return (
        <Button
            variant='outlined'
            className={classes.buttons}
            {...props}
        >
            {text}
        </Button>
    );
}

export default CustomLink;