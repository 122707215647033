import React from 'react';
import {
    Link
} from 'react-router-dom';
import {
    makeStyles
} from '@mui/styles';

const useStyles = makeStyles(
    theme => (
        {
            link: {
                textDecoration: 'none',
                color: theme.palette.primary.dark
            }
        }
    )
);

const Links = ({
    to,
    children,
    ...props
}) => {
    const classes = useStyles();

    return (
        <Link
            to={to}
            className={classes.link}
            {...props}
        >
            {children}
        </Link>
    );
}

export default Links;