import {
    getToken
} from '../reducers';
import {
    endPoints
} from '../../Api';
import {actions} from "../action-types/translation"

export const giveActions = () => {
    return actions ; 
}

export const fetchTranslation = (skip, limit) => (
    dispatch,
    getState
) => console.log('jj', skip, limit) || dispatch({
    RSAA: {
        types: [
            actions.REQUEST_FETCH_TRANSLATION,
            actions.SUCCESS_FETCH_TRANSLATION,
            actions.FAILURE_FETCH_TRANSLATION
        ],
        data: {
            skip,
            limit
        },
        method: endPoints.FETCH_TRANSLATION,
        race: () => false,
    }
});

export const createTranslation = state => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_CREATE_TRANSLATION,
            actions.SUCCESS_CREATE_TRANSLATION,
            actions.FAILURE_CREATE_TRANSLATION
        ],
        method: endPoints.CREATE_TRANSLATION,
        race: () => false,
        data: {
            ...state,
            token: getToken(getState()),
        }
    }
});

export const updateTranslation = state => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_UPDATE_TRANSLATION,
            actions.SUCCESS_UPDATE_TRANSLATION,
            actions.FAILURE_UPDATE_TRANSLATION
        ],
        method: endPoints.UPDATE_TRANSLATION,
        race: () => false,
        data: {
            ...state,
            token: getToken(getState()),
        }
    }
});

export const deleteTranslation = id => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_DELETE_TRANSLATION,
            actions.SUCCESS_DELETE_TRANSLATION,
            actions.FAILURE_DELETE_TRANSLATION
        ],
        method: endPoints.DELETE_TRANSLATION,
        race: () => false,
        data: {
            id,
            token: getToken(getState()),
        }
    }
});

export const fetchTranslationById = id => ({
    RSAA: {
        types: [
            actions.REQUEST_FETCH_TRANSLATION_BY_ID,
            actions.SUCCESS_FETCH_TRANSLATION_BY_ID,
            actions.FAILURE_FETCH_TRANSLATION_BY_ID,
        ],
        method: endPoints.FETCH_TRANSLATION_BY_ID,
        race: () => false,
        data: {
            id,
        },
    },
});

export const countTranslation = () => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_COUNT_TRANSLATION,
            actions.SUCCESS_COUNT_TRANSLATION,
            actions.FAILURE_COUNT_TRANSLATION,
        ],
        method: endPoints.COUNT_TRANSLATION,
        race: () => false,
    },
});