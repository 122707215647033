const ranking = [
    {
        row: 'অবস্থান',
        cols: 1
    },
    {
        row: 'দল',
        cols: 2
    },
    {
        row: 'সম্পন্ন ম্যাচ',
        cols: 1
    },
    {
        row: 'জয়',
        cols: 1
    },
    {
        row: 'হার',
        cols: 1
    },
    {
        row: 'অসমাপ্ত',
        cols: 1
    },
    {
        row: 'পয়েন্ট',
        cols: 1
    },
    {
        row: 'নেট রান রেট',
        cols: 1
    }
];

export default ranking;