import React, {
    forwardRef,
    useState,
    useEffect
} from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    AppBar,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    Grow,
    IconButton,
    ListItem,
    ListItemText,
    Paper,
    Slide,
    TextField,
    Toolbar,
    useTheme
} from '@mui/material';
import {
    makeStyles
} from '@mui/styles';
import {
    Clear,
    ExpandMore,
    Menu,
    // Search
} from '@mui/icons-material';

import menus from './Menus';
import ProfileActions from '../profileActions';
import Notification from '../notification';
import {
    Button,
    Link,
    Logo
} from '../../commonComponent';
import {
    fetchLiveScores,
    fetchInternationalMatches,
    fetchUpcoming,
    fetchLocalMatches
} from '../../redux/actions/LiveScore';
import {
    fetchNotices
} from '../../redux/actions/Notice';
import {
    getIsLoggedIn,
    getLiveScoresHome,
    getActiveNotice,
} from '../../redux/reducers';

const Transition = forwardRef(
    function Transition(
        props, ref
    ) {
        return <Slide
            direction="down"
            ref={ref}
            {...props}
            timeout={800}
        />;
    }
);

const useStyles = makeStyles(
    theme => (
        {
            toolbar: {
                width: '100%',
                padding: 0,
                color: theme.palette.secondary.main,
                // backgroundColor: '#1B5E20',
                background: 'transparent',
                // boxShadow: 'none',
                backgroundColor: 'hsla(97,87%,18%,1)',
                opacity: '0.9',
                backgroundImage: `radial-gradient(at 97% 49%, hsla(133,43%,24%,1) 0px, transparent 50%),
				radial-gradient(at 2% 67%, hsla(107,40%,26%,1) 0px, transparent 50%),
				radial-gradient(at 35% 8%, hsla(108,51%,25%,1) 0px, transparent 50%),
				radial-gradient(at 51% 6%, hsla(107,32%,19%,1) 0px, transparent 50%),
				radial-gradient(at 23% 40%, hsla(105,47%,18%,1) 0px, transparent 50%),
				radial-gradient(at 55% 35%, hsla(113,69%,14%,1) 0px, transparent 50%)`
            },
            links: {
                textDecoration: "none",
                paddingLeft: 0,
                color: theme.palette.secondary.main,
            },
            button: {
                color: 'white',
                fontWeight: 900
            },
            menu: {
                height: 'auto',
                width: '350px',
                color: theme.palette.secondary.main,
                paddingLeft: 10,
            },
            menu1: {
                height: 'auto',
                width: '350px',
                color: theme.palette.secondary.main,
                backgoundColor: '#b0bec5',
                paddingLeft: 10,
            },
            list: {
                paddingLeft: 5,
                color: theme.palette.secondary.main,
            },
            clear: {
                height: '50px',
                width: '50px',
                margin: '0 auto'
            },
            div: {
                width: '33%',
                display: 'flex',
                flexFlow: 'row',
                justifyContent: 'flex-start',
                '& .MuiButton-root': {
                    minWidth: '40px'
                }
            },
            divmid: {
                width: '33%',
                display: 'flex',
                flexFlow: 'row',
                justifyContent: 'center',
                '& .MuiButton-root': {
                    minWidth: '40px'
                }
            },
            divLast: {
                width: '33%',
                display: 'flex',
                flexFlow: 'row',
                justifyContent: 'flex-end',
                '& .MuiButton-root': {
                    minWidth: '40px'
                }
            },
            title: {
                margin: '10px auto',
                padding: 0
            },
            link: {
                textDecoration: 'none'
            }
        }
    )
);

const AppbarforSmaller = () => {
    const classes = useStyles();
    const theme = useTheme();

    const [
        anchorEl,
        setAnchorEl
    ] = useState(null);

    const [
        state,
        setState
    ] = useState({});

    const [
        open,
        setOpen
    ] = useState(false);

    const dispatch = useDispatch();

    useEffect(
        () => {
            dispatch(
                fetchLiveScores() //for the menu ready, don't try to remove it from appbar, scoreboard menu will crush
            );
        },
        [
            dispatch
        ]
    );

    useEffect(
        () => {
            dispatch(
                fetchLocalMatches()
            );
        },
        [
            dispatch
        ]
    );

    useEffect(
        () => {
            dispatch(
                fetchInternationalMatches()
            );
        },
        [
            dispatch
        ]
    );

    useEffect(
        () => {
            dispatch(
                fetchUpcoming()
            );
        },
        [
            dispatch
        ]
    );

    useEffect(
        () => {
            dispatch(
                fetchNotices(0, 0)
            );
        },
        [
            dispatch
        ]
    );

    const handleOpen = item => {
        setState(
            prevState => (
                {
                    [item]: !prevState[item]
                }
            )
        );
    };

    const notice = useSelector(
        state => getActiveNotice(state)
    );
    const a = useSelector(
        state => getLiveScoresHome(state)
    );
    const loggedin = useSelector(
        state => getIsLoggedIn(state)
    );

    // if (isFetching) return null;
    const data = [
        {
            name: "স্কোরবোর্ড",
            children: a.map(
                (
                    {
                        slug,
                        teamone,
                        teamtwo,
                        matchType
                    }
                ) => slug && (
                    {
                        name: `${teamone} বনাম ${teamtwo}`,
                        url: slug[0] && `/scoreboard/${slug[0]}`
                    }
                )
            )
        },
        ...menus.data.slice(0)
    ];

    const handler = children => {
        return children.map(
            (
                subOption, index
            ) => {
                if (!subOption.children) {
                    return (
                        <Grow
                            in={(subOption.name && true) || false}
                            key={index}
                            timeout={850}
                        >
                            <Paper
                                elevation={0}
                                className={classes.menu}
                            >
                                <ListItem
                                    button
                                    component={Link}
                                    to={subOption.url}
                                    divider
                                    className={classes.list}
                                >
                                    <ListItemText
                                        //style={{paddingLeft: subOption.children ? '2px' : '0' }} 
                                        //inset 
                                        onClick={handleClose}
                                        className={classes.list}
                                        primary={subOption.name}
                                    />
                                </ListItem>
                            </Paper>
                        </Grow>
                    );
                }
                return (
                    <Paper
                        key={index}
                        className={classes.menu1}
                        elevation={0}
                    >
                        <ListItem
                            button
                            divider
                            alignItems="flex-start"
                            className={classes.list}
                            onClick={
                                () => handleOpen(subOption.name)
                            }
                        >
                            <ListItemText
                                //inset 
                                primary={subOption.name}
                                className={classes.list}
                            />
                            <ExpandMore />
                        </ListItem>
                        <Collapse
                            in={state[subOption.name]}
                            timeout={850}
                            unmountOnExit
                        >
                            {
                                handler(subOption.children)
                            }
                        </Collapse>
                    </Paper>
                );
            }
        );
    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = event => {
        setAnchorEl(null);
    };

    const handleClickSearch = event => {
        setOpen(true);
    }

    const handleCloseSearch = event => {
        setOpen(false);
    }

    const open1 = Boolean(anchorEl);
    const open2 = Boolean(open);

    return (
        <AppBar
            position="fixed"
            className={classes.toolbar}
        >
            <Toolbar className={classes.toolbar}>
                <div
                    align='left'
                    className={classes.div}
                >
                    <Button
                        className={classes.button}
                        aria-owns={open1 ? 'fade-menu' : null}
                        aria-haspopup="true"
                        onClick={handleClick}
                        startIcon={
                            <Menu />
                        }
                        variant='text'
                    />
                </div>

                <div
                    align='center'
                    className={classes.divmid}
                >
                    <Link
                        to="/"
                    >
                        {/* <Button
                            className={classes.button}
                        >
                            খেলা হবে!
                        </Button> */}
                        <Logo
                            height='45px'
                            width='50px'
                        />
                    </Link>

                </div>

                <div
                    align='right'
                    className={classes.divLast}
                >
                    {/* <Button
                        className={classes.button}
                        onClick={handleClickSearch}
                    >
                        <Search />
                    </Button> */}
                    {
                        (loggedin
                            &&
                            <ProfileActions />
                        )
                        ||
                        <Button
                            component={Link}
                            to="/login"
                            text='লগ ইন'
                            className={classes.button}
                            variant='text'
                        />
                    }
                </div>
                <Drawer
                    id="fade-menu"
                    //className={classes.menu}
                    open={open1}
                    autoFocus
                    onClose={handleClose}
                    transitionDuration={500}
                //TransitionComponent={Fade}
                >
                    {handler(data)}
                    <div
                        align='center'
                    >
                        <IconButton
                            className={classes.clear}
                            variant='outlined'
                            onClick={handleClose}
                        >
                            <Clear
                                sx={{
                                    color: theme.palette.primary.dark
                                }}
                            />
                        </IconButton>
                    </div>

                </Drawer>
                <Dialog
                    open={open2}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClickSearch}
                >
                    <DialogTitle
                        className={classes.title}
                    >
                        খেলা হবে! অনুসন্ধান
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="খেলোয়ার, দল, সিরিজ ইত্যাদি"
                            type="অনুসন্ধান"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            fullWidth
                            onClick={handleCloseSearch}
                            color="secondary"
                            text='অনুসন্ধান'
                        />
                    </DialogActions>
                </Dialog>
                {
                    notice.active === true
                    &&
                    <Notification
                        severity='warning'
                        message={notice.notice}
                    />
                }
            </Toolbar>
        </AppBar>
    );
}

export default AppbarforSmaller;