import React from 'react';
import {
    makeStyles
} from '@mui/styles';

import Icon from '../assets/KhelahobeLogo.png';

const useStyles = makeStyles(
    theme => (
        {
            image: {
                margin: '10px auto',
                transform: 'scale(1.5)',
                objectFit: 'cover'
            },
        }
    )
);

const Logo = ({
    height,
    width
}) => {
    const classes = useStyles();

    return (
        <div
            align='center'
        >
            <img
                alt="খেলা হবে!"
                src={Icon}
                height={height || '90'}
                width={width || '80'}
                className={classes.image}
            />
        </div>
    );
}

export default Logo;