import {
    combineReducers
} from 'redux';
import {
    actions
} from '../action-types/notification';

//const actions = giveActions();

const tokens = (state = [], action) => {
    switch (action.type) {
        case actions.SUCCESS_FETCH_TOKENS:
            return action.response;
        default:
            return state;
    }
};

const token = (state = [], action) => {
    if (actions) {
        switch (action.type) {
            case actions.SUCCESS_FETCH_TOKEN_BY_ID:
                return [action.response];
            default:
                return state;
        }
    }
    else return state;
};


const isFetching = (state = false, action) => {
    switch (action.type) {
        case actions.REQUEST_FETCH_TOKENS:
        case actions.REQUEST_FETCH_TOKEN_BY_ID:
            return true;
        case actions.SUCCESS_FETCH_TOKENS:
        case actions.FAILURE_FETCH_TOKENS:
        case actions.SUCCESS_FETCH_TOKEN_BY_ID:
        case actions.FAILURE_FETCH_TOKEN_BY_ID:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    token,
    tokens,
    isFetching,
});

export const getTokens = state => (state.tokens && state.tokens.map(y => y.device_token)) || [];

export const getTokenById = (state, id) => (state.token.length !== 0 && state.token.find(i => i.id === id)) || '';