/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
// import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, title, image, url }) {
    // const { site } = useStaticQuery(
    //     graphql`
    //   query {
    //     site {
    //       siteMetadata {
    //         title
    //         description
    //         author
    //       }
    //     }
    //   }
    // `
    // )

    // const metaDescription = description
    // const defaultTitle = title
    console.log('tttt', url, image, title)
    return (
        // <Helmet
        //     htmlAttributes={{ lang: 'en' }}
        //     title={title}
        //     meta={[{ name: 'description', content: description, },
        //     { name: 'og:image', content: image, },
        //         // { name: 'description', content: description, }, 
        //         // { name: 'description', content: description, }, 
        //         // { name: 'description', content: description, }, 
        //         // { name: 'description', content: description, }, 
        //         // { name: 'description', content: description, }, 
        //         // { name: 'keywords', content: keywords.join(), 
        //     ]}
        //     links={
        //         [{ rel: 'canonical', href: url, }
        //         ]}
        // />
        <MetaTags>
            <title>{title}</title>
            <meta name="description" content={description || 'খেলা হবে! - Cricket updates in Bangla.'} />
            <meta name="title" content={title || 'খেলা হবে!'} />
            <meta property="og:url" content={url || "https://www.khelahobe.co/"} />
            <meta property="og:image" content={image || 'https://i.ibb.co/tcbf59n/khelahobe.png'} />
            <meta property="og:image:url" content={image || 'https://i.ibb.co/tcbf59n/khelahobe.png'} />
            <meta name="twitter:card" content={title || 'খেলা হবে!'} />
            <meta property="og:image:secure_url" content={image || 'https://i.ibb.co/tcbf59n/khelahobe.png'} />
            <meta name="twitter:title" content={title || 'খেলা হবে!'} />
            <meta name="twitter:description" content={description || 'খেলা হবে! - Cricket updates in Bangla.'} />
            <meta name="twitter:image" content={image || 'https://i.ibb.co/tcbf59n/khelahobe.png'} />
            <link rel="canonical" href={url || "https://www.khelahobe.co/"} />
        </MetaTags>
        // </Helmet >
    )
}

SEO.defaultProps = {
    // lang: `bn`,
    // meta: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string.isRequired,
}

export default SEO;

// import { Helmet } from 'react-helmet';
// import React from 'react';

// const Seo = ({ title, description, pathSlug, keywords }) => {
//     const url = `https://malikgabroun.com/${pathSlug}`
//     return (
//         <Helmet
//             htmlAttributes={{ lang: 'en' }}
//             title={title}
//             meta={[{ name: 'description', content: description, },
//             { name: 'keywords', content: keywords.join(), },]}
//             links={[{ rel: 'canonical', href: url, },
//             ]} />);
// }export default Seo;
