import {
    combineReducers
} from 'redux';
import {
    actions
} from '../action-types/livescore';

//const actions = giveActions();

const liveScores = (state = [], action) => {
    switch (action.type) {
        case actions.SUCCESS_FETCH_LIVESCORES:
            return action.response.data;
        default:
            return state;
    }
};

const upcoming = (state = [], action) => {
    switch (action.type) {
        case actions.SUCCESS_FETCH_UPCOMING:
            return action.response.data;
        default:
            return state;
    }
};

const localMacthes = (state = [], action) => {
    switch (action.type) {
        case actions.SUCCESS_FETCH_LOCAL_MATCHES:
            // console.log('hi action', action.response.all)
            return action.response.data.all;
        default:
            return state;
    }
};

const internationalMatches = (state = [], action) => {
    switch (action.type) {
        case actions.SUCCESS_FETCH_INTERNATIONAL_MATCHES:
            return action.response.data.all;
        default:
            return state;
    }
};

const finished = (state = [], action) => {
    switch (action.type) {
        case actions.SUCCESS_FETCH_FINISHED:
            return action.response.data;
        default:
            return state;
    }
};

const live = (state = [], action) => {
    switch (action.type) {
        case actions.SUCCESS_FETCH_LIVE:
            return action.response.data;
        default:
            return state;
    }
};

const bdMatch = (state = [], action) => {
    switch (action.type) {
        case actions.SUCCESS_FETCH_BD_MATCH:
            return action.response.bd_matches_home;
        default:
            return state;
    }
};

const liveScoresTest = (state = [], action) => {
    switch (action.type) {
        case actions.SUCCESS_FETCH_LIVESCORES_TEST:
            return action.response.data;
        default:
            return state;
    }
};

const liveScoresRecentFinished = (state = [], action) => {
    switch (action.type) {
        case actions.SUCCESS_FETCH_LIVESCORES_RECENT_FINISHED:
            return action.response.data;
        default:
            return state;
    }
};

const isFetchingLivescoresBDMatch = (state = false, action) => {
    switch (action.type) {
        case actions.REQUEST_FETCH_BD_MATCH:
            return true;
        case actions.SUCCESS_FETCH_BD_MATCH:
        case actions.FAILURE_FETCH_BD_MATCH:
            return false;
        default:
            return state;
    }
};

const isFetchingLivescores = (state = false, action) => {
    switch (action.type) {
        case actions.REQUEST_FETCH_LIVESCORES:
        case actions.REQUEST_FETCH_BD_MATCH:
        case actions.REQUEST_FETCH_LIVESCORES_TEST:
        case actions.REQUEST_FETCH_UPCOMING:
        case actions.REQUEST_FETCH_FINISHED:
        case actions.REQUEST_FETCH_LIVE:
        case actions.REQUEST_FETCH_LIVESCORES_RECENT_FINISHED:
        case actions.REQUEST_FETCH_LOCAL_MATCHES:
        case actions.REQUEST_FETCH_INTERNATIONAL_MATCHES:
            return true;
        case actions.SUCCESS_FETCH_LIVESCORES:
        case actions.SUCCESS_FETCH_BD_MATCH:
        case actions.SUCCESS_FETCH_LIVESCORES_TEST:
        case actions.SUCCESS_FETCH_LIVESCORES_RECENT_FINISHED:
        case actions.FAILURE_FETCH_LIVESCORES:
        case actions.FAILURE_FETCH_BD_MATCH:
        case actions.FAILURE_FETCH_LIVESCORES_TEST:
        case actions.FAILURE_FETCH_LIVESCORES_RECENT_FINISHED:
        case actions.SUCCESS_FETCH_UPCOMING:
        case actions.SUCCESS_FETCH_FINISHED:
        case actions.SUCCESS_FETCH_LIVE:
        case actions.SUCCESS_FETCH_LOCAL_MATCHES:
        case actions.SUCCESS_FETCH_INTERNATIONAL_MATCHES:
        case actions.FAILURE_FETCH_UPCOMING:
        case actions.FAILURE_FETCH_FINISHED:
        case actions.FAILURE_FETCH_LIVE:
        case actions.FAILURE_FETCH_LOCAL_MATCHES:
        case actions.FAILURE_FETCH_INTERNATIONAL_MATCHES:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    liveScores,
    liveScoresTest,
    isFetchingLivescores,
    bdMatch,
    liveScoresRecentFinished,
    upcoming,
    finished,
    live,
    localMacthes,
    internationalMatches,
    isFetchingLivescoresBDMatch
});

export const getLiveScores = state => state.liveScores || [];

export const getBDMatch = state => state.bdMatch;

export const getLiveScoresTest = state => state.liveScoresTest || [];

function arrayUnique(array) {
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
            if ((a[i] !== null && a[j] !== null && a[i].slug && a[i].slug[0]) && (a[i].slug[0] === a[j].slug[0]))
                a.splice(j--, 1);
        }
    }

    return a;
};

export const getLiveScoresAll = state => arrayUnique(state.liveScores.concat(state.liveScoresTest)) || [];

export const getLiveScoresAllWithRecent = state => {
    let b = arrayUnique(
        state.liveScores.concat(
            state.liveScoresTest
        )
    )
        ||
        [];

    if (b.length < 5) {
        b = arrayUnique(
            b.concat(
                state.liveScoresRecentFinished
            )
        )
    }

    return b;
};

export const getLiveScoresRecentFinished = state => state.liveScoresRecentFinished || [];

export const getLiveScoresHome = state => {
    let bdLocals = getLocalMatches(state);

    let intl = getInternationalMatches(state);

    let all = bdLocals && bdLocals.concat(intl);
    let less = Math.abs(all.length - 5);
    let upcomes = getUpcoming(state);

    if (all.length < 5) {
        all.concat(upcomes.slice(0, less));
    }

    return all;
};

export const getIsFetchingLiveScores = state => state.isFetchingLivescores;

export const getIsFetchingLiveScoresBDMatch = state => state.isFetchingLivescoresBDMatch;

export const getUpcoming = state => state.upcoming || [];

export const getFinished = state => state.finished || [];

export const getLive = state => state.live || [];

export const getLocalMatches = state => state.localMacthes || [];

export const getInternationalMatches = state => state.internationalMatches || [];