export const actions = {
    REQUEST_FETCH_LIVESCORES: 'REQUEST_FETCH_LIVESCORES',
    SUCCESS_FETCH_LIVESCORES: 'SUCCESS_FETCH_LIVESCORES',
    FAILURE_FETCH_LIVESCORES: 'FAILURE_FETCH_LIVESCORES',

    REQUEST_FETCH_UPCOMING: 'REQUEST_FETCH_UPCOMING',
    SUCCESS_FETCH_UPCOMING: 'SUCCESS_FETCH_UPCOMING',
    FAILURE_FETCH_UPCOMING: 'FAILURE_FETCH_UPCOMING',

    REQUEST_FETCH_FINISHED: 'REQUEST_FETCH_FINISHED',
    SUCCESS_FETCH_FINISHED: 'SUCCESS_FETCH_FINISHED',
    FAILURE_FETCH_FINISHED: 'FAILURE_FETCH_FINISHED',

    REQUEST_FETCH_LIVE: 'REQUEST_FETCH_LIVE',
    SUCCESS_FETCH_LIVE: 'SUCCESS_FETCH_LIVE',
    FAILURE_FETCH_LIVE: 'FAILURE_FETCH_LIVE',

    REQUEST_FETCH_BD_MATCH: 'REQUEST_FETCH_BD_MATCH',
    SUCCESS_FETCH_BD_MATCH: 'SUCCESS_FETCH_BD_MATCH',
    FAILURE_FETCH_BD_MATCH: 'FAILURE_FETCH_BD_MATCH',

    REQUEST_FETCH_LOCAL_MATCHES: 'REQUEST_FETCH_LOCAL_MATCHES',
    SUCCESS_FETCH_LOCAL_MATCHES: 'SUCCESS_FETCH_LOCAL_MATCHES',
    FAILURE_FETCH_LOCAL_MATCHES: 'FAILURE_FETCH_LOCAL_MATCHES',

    REQUEST_FETCH_INTERNATIONAL_MATCHES: 'REQUEST_FETCH_INTERNATIONAL_MATCHES',
    SUCCESS_FETCH_INTERNATIONAL_MATCHES: 'SUCCESS_FETCH_INTERNATIONAL_MATCHES',
    FAILURE_FETCH_INTERNATIONAL_MATCHES: 'FAILURE_FETCH_INTERNATIONAL_MATCHES',

    REQUEST_FETCH_LIVESCORES_TEST: 'REQUEST_FETCH_LIVESCORES_TEST',
    SUCCESS_FETCH_LIVESCORES_TEST: 'SUCCESS_FETCH_LIVESCORES_TEST',
    FAILURE_FETCH_LIVESCORES_TEST: 'FAILURE_FETCH_LIVESCORES_TEST',

    REQUEST_FETCH_LIVESCORES_RECENT_FINISHED: 'REQUEST_FETCH_LIVESCORES_RECENT_FINISHED',
    SUCCESS_FETCH_LIVESCORES_RECENT_FINISHED: 'SUCCESS_FETCH_LIVESCORES_RECENT_FINISHED',
    FAILURE_FETCH_LIVESCORES_RECENT_FINISHED: 'FAILURE_FETCH_LIVESCORES_RECENT_FINISHED',
}