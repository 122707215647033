import {
    combineReducers
} from 'redux';
import {
    actions
} from '../action-types/tournament';

//const actions = giveActions();

// const onThisDay = (state = [], action) => {
//     switch (action.type) {
//         case actions.SUCCESS_FETCH_TOURNAMENT:
//             return action.response;
//         default:
//             return state;
//     }
// };

const tournamentById = (state = [], action) => {
    switch (action.type) {
        case actions.SUCCESS_FETCH_TOURNAMENT_BY_SLUG:
            return [action.response];
        case actions.SUCCESS_FETCH_TOURNAMENT_BY_ID:
            return [action.response];
        default:
            return state;
    }
};

// const isFetching = (state = false, action) => {
//     switch (action.type) {
//         case actions.REQUEST_FETCH_TOURNAMENT:
//         case actions.REQUEST_FETCH_ALL_TOURNAMENT:
//             return true;
//         case actions.SUCCESS_FETCH_ALL_TOURNAMENT:
//         case actions.FAILURE_FETCH_ALL_TOURNAMENT:
//         case actions.SUCCESS_FETCH_TOURNAMENT:
//         case actions.FAILURE_FETCH_TOURNAMENT:
//             return false;
//         default:
//             return state;
//     }
// };

const isFetchingIndividual = (state = false, action) => {
    switch (action.type) {
        case actions.REQUEST_FETCH_TOURNAMENT_BY_ID:
        case actions.REQUEST_FETCH_TOURNAMENT_BY_SLUG:
            return true;
        case actions.SUCCESS_FETCH_TOURNAMENT_BY_ID:
        case actions.SUCCESS_FETCH_TOURNAMENT_BY_SLUG:
        case actions.FAILURE_FETCH_TOURNAMENT_BY_ID:
        case actions.FAILURE_FETCH_TOURNAMENT_BY_SLUG:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    tournamentById,
    // onThisDayById,
    // isFetching,
    isFetchingIndividual
});

// export const getOnThisDayAll = state => state.onThisDay || [];

// export const getIsFetching = state => state.isFetching;

export const getTournamentById = (state, id) => (state.tournamentById && state.tournamentById.find(i => i.id === id)) || '';

export const getTournamentBySlug = (state, slug) => (state.tournamentById && state.tournamentById.find(i => i.slug === slug)) || '';

// export const getOnThisDayBySlug = (state, slug) => (state.onThisDayById && state.onThisDayById.find(i => i.slug === slug)) || '';

export const getIsFetchingIndividual = state => state.isFetchingIndividual;