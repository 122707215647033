import Afghanistan from '../Afghanistan.png';
import Argentina from '../Argentina.png';
import Australia from '../Australia.png';
import Austria from '../Austria.png';
import Bahrain from '../Bahrain.png';
import Bahamas from '../Bahamas.png';
import Bangladesh from '../Bangladesh.png';
import Belgium from '../Belgium.png';
import Belize from '../Belize.png';
import Bermuda from '../Bermuda.png';
import Bhutan from '../Bhutan.png';
import Botswana from '../Botswana.png';
import Brazil from '../Brazil.png';
import Bulgaria from '../Bulgaria.png';
import Cameroon from '../Cameroon.png';
import Canada from '../Canada.png';
import CaymanIslands from '../CaymanIslands.png';
import Chile from '../Chile.png';
import China from '../China.png';
import CostaRica from '../CostaRica.png';
import Cyprus from '../Cyprus.png';
import CzechRepublic from '../CzechRepublic.png';
import Denmark from '../Denmark.png';
import England from '../England.png';
import Estonia from '../Estonia.png';
import Fiji from '../Fiji.png';
import Finland from '../Finland.png';
import France from '../France.png';
import Gambia from '../Gambia.png';
import Germany from '../Germany.png';
import Ghana from '../Ghana.png';
import Gibraltar from '../Gibraltar.png';
import Guernsey from '../Guernsey.png';
import Hongkong from '../Hongkong.png';
import Hungary from '../Hungary.png';
import India from '../India.png';
import Indonesia from '../Indonesia.png';
import IsleofMan from '../IsleofMan.png';
import Israel from '../Israel.png';
import Italy from '../Italy.png';
import Ireland from '../Ireland.png';
import Japan from '../Japan.png';
import Jersey from '../Jersey.png';
import Kenya from '../Kenya.png';
import Kuwait from '../Kuwait.png';
import Lesotho from '../Lesotho.png';
import Luxembourg from '../Luxembourg.png';
import Malawi from '../Malawi.png';
import Malaysia from '../Malaysia.png';
import Malta from '../Malta.png';
import Maldives from '../Maldives.png';
import Mexico from '../Mexico.png';
import Moldova from '../Moldova.png';
import Mozambique from '../Mozambique.png';
import Namibia from '../Namibia.png';
import Nepal from '../Nepal.png';
import Netherland from '../Netherland.png';
import NewZealand from '../NewZealand.png';
import Nigeria from '../Nigeria.png';
import Norway from '../Norway.png';
import Oman from '../Oman.png';
import Pakistan from '../Pakistan.png';
import Panama from '../Panama.png';
import PapuaNew from '../PapuaNew.png';
import Peru from '../Peru.png';
import Philippines from '../Philippines.png';
import Portugal from '../Portugal.png';
import Qatar from '../Qatar.png';
import Rwanda from '../Rwanda.png';
import SaintHelena from '../SaintHelena.png';
import Samoa from '../Samoa.png';
import SaudiArabia from '../SaudiArabia.png';
import Scotland from '../Scotland.png';
import Seychelles from '../Seychelles.png';
// import SierraLeone from '../SierraLeone.png';
import Singapore from '../Singapore.png';
import SouthAfrica from '../SouthAfrica.png';
import SouthKorea from '../SouthKorea.png';
import Spain from '../Spain.png';
import Srilanka from '../Srilanka.png';
import Swaziland from '../Swaziland.png';
import Sweden from '../Sweden.png';
import Switzerland from '../Switzerland.png';
import Tanzania from '../Tanzania.png';
import Thailand from '../Thailand.png';
import UAE from '../UAE.png';
import Uganda from '../Uganda.png';
import USA from '../USA.png';
import Vanuatu from '../Vanuatu.png';
import WestIndies from '../WestIndies.png';
import Zambia from '../Zambia.png';
import Zimbabwe from '../Zimbabwe.png';

const FlagByCountry = {
    'ভারত নারী': {
        src: India,
        className: 'img',
        color: '#4EA8E2'
    },
    'ভারত': {
        src: India,
        className: 'img',
        color: '#4EA8E2'
    },
    'ভারত এ': {
        src: India,
        className: 'img',
        color: '#4EA8E2'
    },
    'ভারত অনুর্ধ্ব-১৯': {
        src: India,
        className: 'img',
        color: '#4EA8E2'
    },
    'পাকিস্তান নারী': {
        src: Pakistan,
        className: 'img'
    },
    'পাকিস্তান': {
        src: Pakistan,
        className: 'img',
        color: '#55AF2D'
    },
    'পাকিস্তান অনুর্ধ্ব-১৯': {
        src: Pakistan,
        className: 'img',
        color: '#55AF2D'
    },
    'পাকিস্তান শাহীনস': {
        src: Pakistan,
        className: 'img',
        color: '#55AF2D'
    },
    'অস্ট্রেলিয়া নারী': {
        src: Australia,
        className: 'img',
        color: '#696502'
    },
    'অস্ট্রেলিয়া': {
        src: Australia,
        className: 'img',
        color: '#696502'
    },
    'অস্ট্রেলিয়া অনুর্ধ্ব-১৯': {
        src: Australia,
        className: 'img',
        color: '#696502'
    },
    'অস্ট্রেলিয়া এ': {
        src: Australia,
        className: 'img',
        color: '#696502'
    },
    'দক্ষিণ আফ্রিকা নারী': {
        src: SouthAfrica,
        className: 'img',
        color: '#65A944'
    },
    'দক্ষিণ আফ্রিকা': {
        src: SouthAfrica,
        className: 'img',
        color: '#65A944'
    },
    'দক্ষিণ আফ্রিকা অনুর্ধ্ব-১৯': {
        src: SouthAfrica,
        className: 'img',
        color: '#65A944'
    },
    'দক্ষিণ আফ্রিকা এ': {
        src: SouthAfrica,
        className: 'img',
        color: '#65A944'
    },
    'ইংল্যান্ড নারী': {
        src: England,
        className: 'img'
    },
    'ইংল্যান্ড': {
        src: England,
        className: 'img',
        color: '#0C0433'
    },
    'ইংল্যান্ড লায়ন্স': {
        src: England,
        className: 'img',
        color: '#0C0433'
    },
    'ইংল্যান্ড অনুর্ধ্ব-১৯': {
        src: England,
        className: 'img',
        color: '#0C0433'
    },
    'জিম্বাবুয়ে নারী': {
        src: Zimbabwe,
        className: 'img',
        color: '#DA4B2C'
    },
    'জিম্বাবুয়ে': {
        src: Zimbabwe,
        className: 'img',
        color: '#DA4B2C'
    },
    'জিম্বাবুয়ে অনুর্ধ্ব-১৯': {
        src: Zimbabwe,
        className: 'img',
        color: '#DA4B2C'
    },
    'বাংলাদেশ নারী': {
        src: Bangladesh,
        className: 'img',
        color: '#317108'
    },
    'বাংলাদেশ': {
        src: Bangladesh,
        className: 'img',
        color: '#317108'
    },
    'বাংলাদেশ অনুর্ধ্ব-১৯': {
        src: Bangladesh,
        className: 'img',
        color: '#317108'
    },
    'আফগানিস্তান নারী': {
        src: Afghanistan,
        className: 'img',
        color: "#AC1307"
    },
    'আফগানিস্তান': {
        src: Afghanistan,
        className: 'img',
        color: "#AC1307"
    },
    'আফগানিস্তান অনুর্ধ্ব-১৯': {
        src: Afghanistan,
        className: 'img',
        color: "#AC1307"
    },
    'নিউ জিল্যান্ড নারী': {
        src: NewZealand,
        className: 'img',
        color: '#3B3837'
    },
    'নিউ জিল্যান্ড একাদশ': {
        src: NewZealand,
        className: 'img',
        color: '#3B3837'
    },
    'নিউ জিল্যান্ড': {
        src: NewZealand,
        className: 'img',
        color: '#3B3837'
    },
    'সংযুক্ত আরব আমিরাত নারী': {
        src: UAE,
        className: 'img',
        color: '#62B8F6'
    },
    'সংযুক্ত আরব আমিরাত': {
        src: UAE,
        className: 'img',
        color: '#62B8F6'
    },
    'সংযুক্ত আরব আমিরাত অনুর্ধ্ব-১৯': {
        src: UAE,
        className: 'img',
        color: '#62B8F6'
    },
    'শ্রী লঙ্কা নারী': {
        src: Srilanka,
        className: 'img',
        color: '#061657'
    },
    'শ্রী লঙ্কা': {
        src: Srilanka,
        className: 'img',
        color: '#061657'
    },
    'শ্রী লঙ্কা অনুর্ধ্ব-১৯': {
        src: Srilanka,
        className: 'img',
        color: '#061657'
    },
    'শ্রী লঙ্কা এ': {
        src: Srilanka,
        className: 'img',
        color: '#061657'
    },
    'শ্রী লঙ্কা ক্রিকেট প্রেসিডেন্টস একাদশ': {
        src: Srilanka,
        className: 'img',
        color: '#061657'
    },
    'স্কটল্যান্ড নারী': {
        src: Scotland,
        className: 'img'
    },
    'স্কটল্যান্ড': {
        src: Scotland,
        className: 'img'
    },
    'স্কটল্যান্ড অনুর্ধ্ব-১৯': {
        src: Scotland,
        className: 'img'
    },
    'আয়ারল্যান্ড নারী': {
        src: Ireland,
        className: 'img'
    },
    'আয়ারল্যান্ড অনুর্ধ্ব-১৯': {
        src: Ireland,
        className: 'img'
    },
    'আয়ারল্যান্ড': {
        src: Ireland,
        className: 'img'
    },
    'ইতালি নারী': {
        src: Italy,
        className: 'img'
    },
    'ইতালি': {
        src: Italy,
        className: 'img'
    },
    'মালদ্বীপ': {
        src: Maldives,
        className: 'img'
    },
    'হংকং নারী': {
        src: Hongkong,
        className: 'img'
    },
    'হংকং': {
        src: Hongkong,
        className: 'img'
    },
    'ওয়েস্ট ইন্ডিজ নারী': {
        src: WestIndies,
        className: 'img'
    },
    'ওয়েস্ট ইন্ডিজ': {
        src: WestIndies,
        className: 'img'
    },
    'ওয়েস্ট ইন্ডিজ অনুর্ধ্ব-১৯': {
        src: WestIndies,
        className: 'img'
    },
    'ওয়েস্ট ইন্ডিয়ানস': {
        src: WestIndies,
        className: 'img'
    },
    'নেপাল নারী': {
        src: Nepal,
        className: 'img'
    },
    'নেপাল': {
        src: Nepal,
        className: 'img'
    },
    'পাপুয়া নিউ গিনি নারী': {
        src: PapuaNew,
        className: 'img'
    },
    'পাপুয়া নিউ গিনি': {
        src: PapuaNew,
        className: 'img'
    },
    'পাপুয়া নিউ গিনি অনুর্ধ্ব-১৯': {
        src: PapuaNew,
        className: 'img'
    },
    'বার্মুডা': {
        src: Bermuda,
        className: 'img'
    },
    'বাহামাস': {
        src: Bahamas,
        className: 'img'
    },
    'নেদারল্যান্ডস নারী': {
        src: Netherland,
        className: 'img'
    },
    'নেদারল্যান্ডস': {
        src: Netherland,
        className: 'img'
    },
    'ওমান নারী': {
        src: Oman,
        className: 'img'
    },
    'ওমান': {
        src: Oman,
        className: 'img'
    },
    'নামিবিয়া নারী': {
        src: Namibia,
        className: 'img'
    },
    'নামিবিয়া': {
        src: Namibia,
        className: 'img'
    },
    'কাতার নারী': {
        src: Qatar,
        className: 'img'
    },
    'কাতার': {
        src: Qatar,
        className: 'img'
    },
    'সৌদী আরব নারী': {
        src: SaudiArabia,
        className: 'img'
    },
    'সৌদী আরব': {
        src: SaudiArabia,
        className: 'img'
    },
    'সিঙ্গাপুর নারী': {
        src: Singapore,
        className: 'img'
    },
    'সিঙ্গাপুর': {
        src: Singapore,
        className: 'img'
    },
    'ডেনমার্ক নারী': {
        src: Denmark,
        className: 'img'
    },
    'ডেনমার্ক': {
        src: Denmark,
        className: 'img'
    },
    'কানাডা নারী': {
        src: Canada,
        className: 'img'
    },
    'কানাডা': {
        src: Canada,
        className: 'img'
    },
    'কানাডা অনুর্ধ্ব-১৯': {
        src: Canada,
        className: 'img'
    },
    'সেইচেলেস': {
        src: Seychelles,
        className: 'img'
    },
    'ক্যামেরুন নারী': {
        src: Cameroon,
        className: 'img'
    },
    'ক্যামেরুন': {
        src: Cameroon,
        className: 'img'
    },
    'জার্সি নারী': {
        src: Jersey,
        className: 'img'
    },
    'জার্সি': {
        src: Jersey,
        className: 'img'
    },
    'নরওয়ে নারী': {
        src: Norway,
        className: 'img'
    },
    'নরওয়ে': {
        src: Norway,
        className: 'img'
    },
    'কুয়েত নারী': {
        src: Kuwait,
        className: 'img'
    },
    'কুয়েত': {
        src: Kuwait,
        className: 'img'
    },
    'কুয়েত অনুর্ধ্ব-১৯': {
        src: Kuwait,
        className: 'img'
    },
    'ঘানা নারী': {
        src: Ghana,
        className: 'img'
    },
    'ঘানা': {
        src: Ghana,
        className: 'img'
    },
    'কেনিয়া নারী': {
        src: Kenya,
        className: 'img'
    },
    'কেনিয়া': {
        src: Kenya,
        className: 'img'
    },
    'সুইজারল্যান্ড নারী': {
        src: Switzerland,
        className: 'img'
    },
    'সুইজারল্যান্ড': {
        src: Switzerland,
        className: 'img'
    },
    'বৎসোয়ানা নারী': {
        src: Botswana,
        className: 'img'
    },
    'বৎসোয়ানা': {
        src: Botswana,
        className: 'img'
    },
    'ইউএসএ নারী': {
        src: USA,
        className: 'img'
    },
    'ইউএসএ': {
        src: USA,
        className: 'img'
    },
    'ইউনাইটেড স্টেটস অফ আমেরিকা নারী': {
        src: USA,
        className: 'img'
    },
    'ইউনাইটেড স্টেটস অফ আমেরিকা': {
        src: USA,
        className: 'img'
    },
    'অস্ট্রিয়া নারী': {
        src: Austria,
        className: 'img'
    },
    'অস্ট্রিয়া': {
        src: Austria,
        className: 'img'
    },

    'মালয়েশিয়া নারী': {
        src: Malaysia,
        className: 'img'
    },
    'মালয়েশিয়া': {
        src: Malaysia,
        className: 'img'
    },
    'জার্মানি নারী': {
        src: Germany,
        className: 'img'
    },
    'জার্মানি': {
        src: Germany,
        className: 'img'
    },
    'গার্নসি নারী': {
        src: Guernsey,
        className: 'img'
    },
    'গার্নসি': {
        src: Guernsey,
        className: 'img'
    },
    'কায়মান আইল্যান্ডস': {
        src: CaymanIslands,
        className: 'img'
    },
    'উগান্ডা নারী': {
        src: Uganda,
        className: 'img'
    },
    'উগান্ডা': {
        src: Uganda,
        className: 'img'
    },
    'উগান্ডা অনুর্ধ্ব-১৯': {
        src: Uganda,
        className: 'img'
    },
    'এসওয়াতিনি নারী': {
        src: 'https://i.ibb.co/5GXygrz/Eswatini.png',
        className: 'imgdpl2'
    },
    'এসওয়াতিনি': {
        src: 'https://i.ibb.co/5GXygrz/Eswatini.png',
        className: 'imgdpl2'
    },
    'সুইডেন নারী': {
        src: Sweden,
        className: 'img'
    },
    'এস্তোনিয়া': {
        src: Estonia,
        className: 'imgdpl2'
    },
    'সাইপ্রাস': {
        src: Cyprus,
        className: 'img'
    },
    'সুইডেন': {
        src: Sweden,
        className: 'img'
    },
    'তানজানিয়া নারী': {
        src: Tanzania,
        className: 'img'
    },
    'তানজানিয়া': {
        src: Tanzania,
        className: 'img'
    },
    'নাইজেরিয়া নারী': {
        src: Nigeria,
        className: 'img'
    },
    'নাইজেরিয়া': {
        src: Nigeria,
        className: 'img'
    },
    'সিয়েরা লিওন নারী': {
        src: 'https://i.ibb.co/bgzPL4c/Sierra-leon.png',
        className: 'imgdpl2'
    },
    'সিয়েরা লিওন': {
        src: 'https://i.ibb.co/bgzPL4c/Sierra-leon.png',
        className: 'imgdpl2'
    },
    'লুক্সেমবার্গ': {
        src: Luxembourg,
        className: 'img'
    },
    'ফিলিপিন্স': {
        src: Philippines,
        className: 'img'
    },
    'স্পেন নারী': {
        src: Spain,
        className: 'img'
    },
    'স্পেন': {
        src: Spain,
        className: 'img'
    },
    'বেলিজ': {
        src: Belize,
        className: 'img'
    },
    'পেরু': {
        src: Peru,
        className: 'img'
    },
    'ফ্রান্স নারী': {
        src: France,
        className: 'img'
    },
    'ফ্রান্স': {
        src: France,
        className: 'img'
    },
    'বাহরাইন': {
        src: Bahrain,
        className: 'img'
    },
    'মেক্সিকো': {
        src: Mexico,
        className: 'img'
    },
    'ফিজি': {
        src: Fiji,
        className: 'img'
    },
    'সামোয়া': {
        src: Samoa,
        className: 'img'
    },
    'ভানুয়াতু': {
        src: Vanuatu,
        className: 'img'
    },
    'পানামা': {
        src: Panama,
        className: 'img'
    },
    'বেলজিয়াম': {
        src: Belgium,
        className: 'img'
    },
    'জাপান': {
        src: Japan,
        className: 'img'
    },
    'কোস্টা রিকা': {
        src: CostaRica,
        className: 'img'
    },
    'থাইল্যান্ড': {
        src: Thailand,
        className: 'img'
    },
    'থাইল্যান্ড নারী': {
        src: Thailand,
        className: 'img'
    },
    'আর্জেন্টিনা': {
        src: Argentina,
        className: 'img'
    },
    'আর্জেন্টিনা নারী': {
        src: Argentina,
        className: 'img'
    },
    'হাঙ্গেরি': {
        src: Hungary,
        className: 'img'
    },
    'চিলি': {
        src: Chile,
        className: 'img'
    },
    'মালাউই': {
        src: Malawi,
        className: 'img'
    },
    'মোজাম্বিক': {
        src: Mozambique,
        className: 'img'
    },
    'মোজাম্বিক নারী': {
        src: Mozambique,
        className: 'img'
    },
    'ইসরায়েল': {
        src: Israel,
        className: 'img'
    },
    'ভুটান': {
        src: Bhutan,
        className: 'img'
    },
    'ভুটান নারী': {
        src: Bhutan,
        className: 'img'
    },
    'ফিনল্যান্ড': {
        src: Finland,
        className: 'img'
    },
    'সাউথ কোরিয়া': {
        src: SouthKorea,
        className: 'img'
    },
    'আইল অব ম্যান': {
        src: IsleofMan,
        className: 'img'
    },
    'পর্তুগাল': {
        src: Portugal,
        className: 'img'
    },
    'মাল্টা': {
        src: Malta,
        className: 'img'
    },
    'বুলগেরিয়া': {
        src: Bulgaria,
        className: 'img'
    },
    'ব্রাজিল': {
        src: Brazil,
        className: 'img'
    },
    'ব্রাজিল নারী': {
        src: Brazil,
        className: 'img'
    },
    'চেক প্রজাতন্ত্র': {
        src: CzechRepublic,
        className: 'img'
    },
    'সেণ্ট হেলেনা': {
        src: SaintHelena,
        className: 'img'
    },
    'মলডোভা': {
        src: Moldova,
        className: 'img'
    },
    'জিব্রাল্টার': {
        src: Gibraltar,
        className: 'img'
    },
    'ইন্দোনেশিয়া': {
        src: Indonesia,
        className: 'img'
    },
    'লেসোথো': {
        src: Lesotho,
        className: 'img'
    },
    'রুয়ান্ডা': {
        src: Rwanda,
        className: 'img'
    },
    'রুয়ান্ডা নারী': {
        src: Rwanda,
        className: 'img'
    },
    'সোয়াজিল্যান্ড': {
        src: Swaziland,
        className: 'img'
    },
    'গাম্বিয়া': {
        src: Gambia,
        className: 'img'
    },
    'চায়না': {
        src: China,
        className: 'img'
    },
    'জাম্বিয়া': {
        src: Zambia,
        className: 'img'
    },
    'পারটেক্স স্পোর্টিং ক্লাব': {
        src: 'https://i.ibb.co/mJFjMP1/Partex.webp',
        className: 'imgdpl'
    },
    'মোহামেডান স্পোর্টিং ক্লাব': {
        src: 'https://i.ibb.co/zQMJ7Zt/Mohammedan.webp',
        className: 'imgdpl'
    },
    'আবাহনী লিমিটেড': {
        src: 'https://i.ibb.co/7Kn8CtY/Abahani.webp',
        className: 'imgdpl'
    },
    'ওল্ড ডিওএইচএস স্পোর্টস ক্লাব': {
        src: `https://i.ibb.co/rsS1TVq/Old-DOHS.webp`,
        className: 'imgdpl'
    },
    'ব্রাদার্স ইউনিয়ন': {
        src: 'https://i.ibb.co/7K1XHTX/Brothers.webp',
        className: 'imgdpl'
    },
    'লিজেন্ডস অফ রূপগঞ্জ': {
        src: 'https://i.ibb.co/XycmVbL/Legends.webp',
        className: 'imgdpl'
    },
    'রূপগঞ্জ টাইগার্স ক্রিকেট ক্লাব': {
        src: 'https://i.ibb.co/sH9Bh6y/Rupgonj-Tigers.webp',
        className: 'imgdpl'
    },
    'সিটি ক্লাব': {
        src: 'https://i.ibb.co/8Kk07Gy/City-Club.webp',
        className: 'imgdpl'
    },
    'খেলাঘর সমাজ কল্যাণ সমিতি': {
        src: 'https://i.ibb.co/Stm1fqz/Khelaghar.webp',
        className: 'imgdpl'
    },
    'শেখ জামাল ধানমন্ডি ক্লাব': {
        src: `https://i.ibb.co/Qn5GmPX/Sheikh-Jamal.webp`,
        className: 'imgdpl'
    },
    'প্রাইম ব্যাংক ক্রিকেট ক্লাব': {
        src: 'https://i.ibb.co/Tq1Gcm2/Prime-Bank.webp',
        className: 'imgdpl'
    },
    'গাজী গ্রুপ ক্রিকেটার্স': {
        src: 'https://i.ibb.co/JKw2bxx/Gazi-Group.webp',
        className: 'imgdpl'
    },
    'প্রাইম দোলেশ্বর স্পোর্টিং ক্লাব': {
        src: 'https://i.ibb.co/Bfdymf9/Doleshwar.webp',
        className: 'imgdpl'
    },
    'শাইনপুকুর ক্রিকেট ক্লাব': {
        src: `https://i.ibb.co/VHkKL26/Shinepukur.webp`,
        className: 'imgdpl'
    },
    'মুম্বাই ইন্ডিয়ানস': {
        src: 'https://i.ibb.co/wzrD6Y3/MI.webp',
        className: 'imgdpl'
    },
    'মুম্বাই ইন্ডিয়ানস নারী': {
        src: 'https://i.ibb.co/wzrD6Y3/MI.webp',
        className: 'imgdpl'
    },
    'রয়েল চ্যালেঞ্জার্স বেঙ্গালুর': {
        src: 'https://i.ibb.co/sHBNz5D/RCB.webp',
        className: 'imgdpl'
    },
    'চেন্নাই সুপার কিংস': {
        src: 'https://i.ibb.co/NsbX6Y1/CSK.webp',
        className: 'imgdpl'
    },
    'দিল্লী ক্যাপিটালস': {
        src: `https://i.ibb.co/8XBQ67f/DC.webp`,
        className: 'imgdpl'
    },
    'সানরাইজার্স হায়দারাবাদ': {
        src: 'https://i.ibb.co/51sgTmS/SH.webp',
        className: 'imgdpl'
    },
    'কলকাতা নাইট রাইডার্স': {
        src: 'https://i.ibb.co/HFV2KGW/KKK.webp',
        className: 'imgdpl'
    },
    'রাজস্হান রয়েলস': {
        src: 'https://i.ibb.co/BL4qcsP/RR.webp',
        className: 'imgdpl'
    },
    'পাঞ্জাব কিংস': {
        src: `https://i.ibb.co/q7tvxpr/PK.webp`,
        className: 'imgdpl'
    },
    'সাসেক্স': {
        src: 'https://i.ibb.co/WGzZVn3/sussex.webp',
        className: 'imgdpl'
    },
    'ইয়র্কশায়ার': {
        src: 'https://i.ibb.co/L0W2dRB/Yorkshire.webp',
        className: 'imgdpl'
    },
    'নটিংহামশায়ার': {
        src: 'https://i.ibb.co/drgnM3B/Nottinghamshire.webp',
        className: 'imgdpl'
    },
    'এসেক্স': {
        src: `https://i.ibb.co/8NsZXLz/Essex.webp`,
        className: 'imgdpl'
    },
    'ওয়ারউইকশায়ার': {
        src: 'https://i.ibb.co/TvSD7J4/Warwickshire.webp',
        className: 'imgdpl'
    },
    'ডার্বিশায়ার': {
        src: 'https://i.ibb.co/HKPKWhX/Derbyshire.webp',
        className: 'imgdpl'
    },
    'সমারসেট': {
        src: 'https://i.ibb.co/3vwb7C4/Somerset.webp',
        className: 'imgdpl'
    },
    'হ্যাম্পশায়ার': {
        src: `https://i.ibb.co/hVJn7Wf/Hampshire.webp`,
        className: 'imgdpl'
    },
    'লেস্টারশায়ার': {
        src: 'https://i.ibb.co/Fz4ggFJ/Leicestershire.png',
        className: 'imgdpl'
    },
    'গ্লস্টারশায়ার': {
        src: 'https://i.ibb.co/bbqzj3G/Gloucestershire.png',
        className: 'imgdpl'
    },
    'গ্লুস্টারশায়ার': {
        src: 'https://i.ibb.co/bbqzj3G/Gloucestershire.png',
        className: 'imgdpl'
    },
    'ল্যাঙ্কাশায়ার': {
        src: `https://i.ibb.co/MhfgHXh/Lancashire.png`,
        className: 'imgdpl'
    },
    'ল্যাংকাশায়ার': {
        src: 'https://i.ibb.co/MhfgHXh/Lancashire.png',
        className: 'imgdpl'
    },
    'গ্ল্যামারগান': {
        src: 'https://i.ibb.co/M5LbRwf/Glamorgan.png',
        className: 'imgdpl'
    },
    'উস্টারশায়ার': {
        src: 'https://i.ibb.co/d4d8k1t/Worcestershire.web',
        className: 'imgdpl'
    },
    'হ্যাম্পশায়র': {
        src: `https://i.ibb.co/hVJn7Wf/Hampshire.webp`,
        className: 'imgdpl'
    },
    'সেন্ট কিটস অ্যান্ড নেভিস প্যাট্রিয়টস': {
        src: 'https://i.ibb.co/PGqQMgz/St-Kitts-Nevis-Patriots.webp',
        className: 'imgdpl'
    },
    'ত্রিনবাগো নাইট রাইডার্স': {
        src: 'https://i.ibb.co/dMKzFYS/Trinbago-Knight-Riders.webp',
        className: 'imgdpl'
    },
    'জ্যামাইকা তালাওয়াহস': {
        src: 'https://i.ibb.co/nBWXGfm/Jamaica-Tallawahas.webp',
        className: 'imgdpl'
    },
    'সেন্ট লুসিয়া কিংস': {
        src: `https://i.ibb.co/88nJhgH/St-Lucia-Kings.webp`,
        className: 'imgdpl'
    },
    'গায়ানা অ্যামাজন ওয়ারিয়র্স': {
        src: 'https://i.ibb.co/tHwfkdm/Guana-Amazon-Warriors.webp',
        className: 'imgdpl'
    },
    'বার্বাডোজ রয়্যালস': {
        src: 'https://i.ibb.co/DVpPmtL/Barbados-Royals.webp',
        className: 'imgdpl'
    },
    'গুয়ানা আমাজন ওয়ারিয়র্স': {
        src: 'https://i.ibb.co/WWm5kGg/Guana-Amazon-Warriors.webp',
        className: 'imgdpl'
    },
    'ডারহাম': {
        src: `https://i.ibb.co/VYYTGGR/durham.webp`,
        className: 'imgdpl'
    },
    'নর্দাম্পটনশায়ার': {
        src: 'https://i.ibb.co/JKs0fqg/Northamptonshire.png',
        className: 'imgdpl'
    },
    'কেন্ট': {
        src: 'https://i.ibb.co/c2Q0Lfv/Kent.png',
        className: 'imgdpl'
    },
    'ওয়েস্টার্ন স্টর্ম': {
        src: 'https://i.ibb.co/DMbBsBx/Western-Storm.png',
        className: 'imgdpl'
    },
    'সাউথ ইস্ট স্টারস': {
        src: `https://i.ibb.co/CmBrzJW/South-East-Stars.png`,
        className: 'imgdpl'
    },
    'সানরাইজার্স': {
        src: 'https://i.ibb.co/x7DJPmY/Sunrisers.png',
        className: 'imgdpl'
    },
    'নর্দার্ন ডায়ামন্ডস': {
        src: 'https://i.ibb.co/tQ7Bkb9/Northern-Diamonds.png',
        className: 'imgdpl'
    },
    'সেন্ট্রাল স্পার্কস': {
        src: 'https://i.ibb.co/1qFdNY6/Central-Sparks.png',
        className: 'imgdpl'
    },
    'সাউদার্ন ভাইপার্স': {
        src: `https://i.ibb.co/fkyrBBV/Southern-Vipers.png`,
        className: 'imgdpl'
    },
    'থান্ডার': {
        src: 'https://i.ibb.co/rk29Ffm/Thunder.png',
        className: 'imgdpl'
    },
    'লাইটনিং': {
        src: 'https://i.ibb.co/n1KhTpC/Lightning.png',
        className: 'imgdpl'
    },
    'বার্মিংহাম বেয়ার্স': {
        src: 'https://i.ibb.co/HnGHGJf/313279-logo.webp',
        className: 'imgdpl'
    },
    'সারে': {
        src: `https://i.ibb.co/4jFVq4x/313277-logo.webp`,
        className: 'imgdpl'
    },
    'মিডলসেক্স': {
        src: 'https://i.ibb.co/drtLkj5/313273-logo.webp',
        className: 'imgdpl'
    },
    'সার্বিয়া': {
        src: 'https://i.ibb.co/hXjBnQh/Serbia.webp',
        className: 'imgdpl'
    },
    'গ্রীস': {
        src: 'https://i.ibb.co/JRJ3Gk2/greece.webp',
        className: 'imgdpl'
    },
    'রোমানিয়া': {
        src: `https://i.ibb.co/NVxPFhC/romania-2.png`,
        className: 'imgdpl'
    },
    'ওয়েলশ ফায়ার (নারী)': {
        src: 'https://i.ibb.co/s6VJwJR/Welsh-Fire.webp',
        className: 'imgdpl'
    },
    'ওয়েলশ ফায়ার (পুরুষ)': {
        src: 'https://i.ibb.co/s6VJwJR/Welsh-Fire.webp',
        className: 'imgdpl'
    },
    'ওভাল ইনভিন্সিবলস (নারী)': {
        src: 'https://i.ibb.co/2nfL4FZ/Oval-Invincible.webp',
        className: 'imgdpl'
    },
    'ওভাল ইনভিন্সিবলস (পুরুষ)': {
        src: `https://i.ibb.co/2nfL4FZ/Oval-Invincible.webp`,
        className: 'imgdpl'
    },
    'সাউদার্ন ব্রেভ (নারী)': {
        src: 'https://i.ibb.co/Q8vbG9Y/Southern-Brave.webp',
        className: 'imgdpl'
    },
    'সাউদার্ন ব্রেভ (পুরুষ)': {
        src: 'https://i.ibb.co/Q8vbG9Y/Southern-Brave.webp',
        className: 'imgdpl'
    },
    'নর্দান সুপারচার্জার্স (নারী)': {
        src: 'https://i.ibb.co/r7gpQd9/Super-Chargers.webp',
        className: 'imgdpl'
    },
    'নর্দান সুপারচার্জার্স (পুরুষ)': {
        src: `https://i.ibb.co/r7gpQd9/Super-Chargers.webp`,
        className: 'imgdpl'
    },
    'লন্ডন স্পিরিট (নারী)': {
        src: 'https://i.ibb.co/Tv1fpsQ/London-Spirit.webp',
        className: 'imgdpl'
    },
    'লন্ডন স্পিরিট (পুরুষ)': {
        src: 'https://i.ibb.co/Tv1fpsQ/London-Spirit.webp',
        className: 'imgdpl'
    },
    'ম্যানচেস্টার অরিজিনালস (নারী)': {
        src: 'https://i.ibb.co/Cw7CPSX/Manchester-Originals.webp',
        className: 'imgdpl'
    },
    'ম্যানচেস্টার অরিজিনালস (পুরুষ)': {
        src: `https://i.ibb.co/Cw7CPSX/Manchester-Originals.webp`,
        className: 'imgdpl'
    },
    'বার্মিংহাম ফিনিক্স (নারী)': {
        src: 'https://i.ibb.co/FKhy58Y/Birmingham-Phoenix.webp',
        className: 'imgdpl'
    },
    'বার্মিংহাম ফিনিক্স (পুরুষ)': {
        src: 'https://i.ibb.co/FKhy58Y/Birmingham-Phoenix.webp',
        className: 'imgdpl'
    },
    'ট্রেন্ট রকেটস (নারী)': {
        src: 'https://i.ibb.co/h1p6Fz0/Trent-Rockets.webp',
        className: 'imgdpl'
    },
    'ট্রেন্ট রকেটস (পুরুষ)': {
        src: `https://i.ibb.co/h1p6Fz0/Trent-Rockets.webp`,
        className: 'imgdpl'
    },
    'ওয়েস্টার্ন অস্ট্রেলিয়া': {
        src: `https://i.ibb.co/ypXxN0x/Western-Australia.webp`,
        className: 'imgdpl'
    },
    'তাসমানিয়া': {
        src: `https://i.ibb.co/dQqWV2k/Tasmania.webp`,
        className: 'imgdpl'
    },
    'রংপুর বিভাগ': {
        src: `https://i.ibb.co/J5V9CSG/Rangpur-Division.webp`,
        className: 'imgdpl'
    },
    'খুলনা বিভাগ': {
        src: `https://i.ibb.co/qJdG7nF/Khulna-Division.webp`,
        className: 'imgdpl'
    },
    'চট্টগ্রাম বিভাগ': {
        src: `https://i.ibb.co/qFjwhqq/Chittagong-Division.webp`,
        className: 'imgdpl'
    },
    'রাজশাহী বিভাগ': {
        src: `https://i.ibb.co/Xk8FZS2/Rajshahi-DIvision.webp`,
        className: 'imgdpl'
    },
    'ঢাকা মেট্রোপলিস': {
        src: `https://i.ibb.co/N7FGjkK/Dhaka-Metropolis.webp`,
        className: 'imgdpl'
    },
    'বরিশাল বিভাগ': {
        src: `https://i.ibb.co/ZNL8Jwt/Barisal-Division.webp`,
        className: 'imgdpl'
    },
    'ঢাকা বিভাগ': {
        src: `https://i.ibb.co/F08QQKg/Dhaka-Division.webp`,
        className: 'imgdpl'
    },
    'সিলেট বিভাগ': {
        src: `https://i.ibb.co/bgpkxyX/Sylhet-Division.webp`,
        className: 'imgdpl'
    },
    'সাউদার্ন রকস': {
        src: `https://i.ibb.co/xh2Wr8d/Southern-Rocks.webp`,
        className: 'imgdpl'
    },
    'মেটাবেলেল্যান্ড টাস্কার্স': {
        src: `https://i.ibb.co/FHYx76y/Metabeleland-Tuskers.webp`,
        className: 'imgdpl'
    },
    'বুস্ট রিজিওন': {
        src: `https://i.ibb.co/DMS0PKf/Boost.webp`,
        className: 'imgdpl'
    },
    'আমো রিজিওন': {
        src: `https://i.ibb.co/fCrXpnp/Amor.webp`,
        className: 'imgdpl'
    },
    'ওয়ারিয়র্স': {
        src: `https://i.ibb.co/rxzZ0Ws/Warriors.webp`,
        className: 'imgdpl'
    },
    'ডলফিনস': {
        src: `https://i.ibb.co/DkXDV3x/Dolphins.webp`,
        className: 'imgdpl'
    },
    'নর্থ ওয়েস্ট': {
        src: `https://i.ibb.co/mJ5n60W/North-West.webp`,
        className: 'imgdpl'
    },
    'বোল্যান্ড': {
        src: `https://i.ibb.co/vLhYNMf/Boland.webp`,
        className: 'imgdpl'
    },
    'মিড ওয়েস্ট রাইনোস': {
        src: `https://i.ibb.co/XWHzGG8/mid-West-Rhinos.webp`,
        className: 'imgdpl'
    },
    'মাউন্টেনিয়ার্স': {
        src: `https://i.ibb.co/txm1hR9/Mountaineers.webp`,
        className: 'imgdpl'
    },
    'নর্দান ২য় একাদশ (পাকিস্তান)': {
        src: `https://i.ibb.co/fVgP5Ds/Northern-2nd-XI.webp`,
        className: 'imgdpl'
    },
    'খাইবার পাখতুনখোয়া দ্বিতীয় একাদশ': {
        src: `https://i.ibb.co/b1y0Cf4/Khyber-Pakhtunkhwa-2n-XI.webp`,
        className: 'imgdpl'
    },
    'দক্ষিণ পাঞ্জাব ২য় একাদশ (পাকিস্তান)': {
        src: `https://i.ibb.co/hFzQ7CM/South-Punjab-2nd-XI.webp`,
        className: 'imgdpl'
    },
    'সেন্ট্রাল পাঞ্জাব দ্বিতীয় একাদশ (পাকিস্তান)': {
        src: `https://i.ibb.co/8rkhWWy/Central-Punjab-2nd-XI.webp`,
        className: 'imgdpl'
    },
    'খাইবার পাখতুনখোয়া': {
        src: `https://i.ibb.co/D7nYLxf/Khyber-Pkahtunkhwa.webp`,
        className: 'imgdpl'
    },
    'নর্দার্ন ( পাকিস্তান )': {
        src: `https://i.ibb.co/NZxYmYD/Northern-Pakistan.webp`,
        className: 'imgdpl'
    },
    'বেলুচিস্তান': {
        src: `https://i.ibb.co/30PG0Ng/Beluchistan.webp`,
        className: 'imgdpl'
    },
    'সিন্ধ': {
        src: `https://i.ibb.co/QYR32Sx/Sindh.webp`,
        className: 'imgdpl'
    },
    'সেন্ট্রাল পাঞ্জাব (পাকিস্তান)': {
        src: `https://i.ibb.co/DgMgRRY/Central-Punjab.webp`,
        className: 'imgdpl'
    },
    'সাউদার্ন পাঞ্জাব ( পাকিস্তান )': {
        src: `https://i.ibb.co/cbYzxV2/Southern-punjab.webp`,
        className: 'imgdpl'
    },
    'মেলবোর্ন স্টারস নারী': {
        src: `https://i.ibb.co/bmz8LHr/Melbourne-Stars-Women.webp`,
        className: 'imgdpl'
    },
    'মেলবোর্ন স্টারস': {
        src: `https://i.ibb.co/bmz8LHr/Melbourne-Stars-Women.webp`,
        className: 'imgdpl'
    },
    'হোবার্ট হারিকেন্স নারী': {
        src: `https://i.ibb.co/W6HwXWL/Hobart-hurricanes-Women.webp`,
        className: 'imgdpl'
    },
    'হোবার্ট হারিকেন্স': {
        src: `https://i.ibb.co/W6HwXWL/Hobart-hurricanes-Women.webp`,
        className: 'imgdpl'
    },
    'সাউথ অস্ট্রেলিয়া': {
        src: `https://i.ibb.co/qptdZ54/South-Australia.webp`,
        className: 'imgdpl'
    },
    'কুইন্সল্যান্ড': {
        src: `https://i.ibb.co/r5kCZ7c/Queensland.webp`,
        className: 'imgdpl'
    },
    'মেলবোর্ন রেনেগেডস নারী': {
        src: `https://i.ibb.co/5r4H0s8/Melbourne-Renegedes-Women.webp`,
        className: 'imgdpl'
    },
    'মেলবোর্ন রেনেগেডস': {
        src: `https://i.ibb.co/5r4H0s8/Melbourne-Renegedes-Women.webp`,
        className: 'imgdpl'
    },
    'সিডনি থান্ডার ওমেন': {
        src: `https://i.ibb.co/Wkt2k90/Sydney-Thunders-Women.webp`,
        className: 'imgdpl'
    },
    'সিডনি থান্ডার': {
        src: `https://i.ibb.co/Wkt2k90/Sydney-Thunders-Women.webp`,
        className: 'imgdpl'
    },
    'ওটাগো': {
        src: `https://i.ibb.co/SVVzHJq/Otago.webp`,
        className: 'imgdpl'
    },
    'ওয়েলিংটন': {
        src: `https://i.ibb.co/5GdVZsY/Wellington.webp`,
        className: 'imgdpl'
    },
    'ক্যান্টারবারি': {
        src: `https://i.ibb.co/bP81ySZ/Canterbury.webp`,
        className: 'imgdpl'
    },
    'সেন্ট্রাল ডিস্ট্রিক্টস': {
        src: `https://i.ibb.co/CtxX8dK/Central-districts.webp`,
        className: 'imgdpl'
    },
    'সেন্ট্রাল ডিস্ট্রিক্টস ওমেন': {
        src: `https://i.ibb.co/CtxX8dK/Central-districts.webp`,
        className: 'imgdpl'
    },
    'বেলুচিস্তান দ্বিতীয় একাদশ': {
        src: `https://i.ibb.co/b5fNgMh/Beluchistan-2nd-XI.webp`,
        className: 'imgdpl'
    },
    'সিন্ধু দ্বিতীয় একাদশ': {
        src: `https://i.ibb.co/1dMWWwn/Sindh-2nd-XI.webp`,
        className: 'imgdpl'
    },
    'মাশোনাল্যান্ড ইগল্স': {
        src: `https://i.ibb.co/YysJDzj/Mashonaland-Eagles.webp`,
        className: 'imgdpl'
    },
    'মেটাবেল্যান্ড টাস্কার্স': {
        src: `https://i.ibb.co/bLCRtkM/Matabelend-Tuskers.webp`,
        className: 'imgdpl'
    },
    'সিডনি সিক্সার্স নারী': {
        src: `https://i.ibb.co/H2brD4L/Sydney-Sixers-Women.webp`,
        className: 'imgdpl'
    },
    'সিডনি সিক্সার্স': {
        src: `https://i.ibb.co/H2brD4L/Sydney-Sixers-Women.webp`,
        className: 'imgdpl'
    },
    'ব্রিসবেন হিট ওমেন': {
        src: `https://i.ibb.co/YjR8tVF/Brisben-Heat.webp`,
        className: 'imgdpl'
    },
    'ব্রিসবেন হিট': {
        src: `https://i.ibb.co/YjR8tVF/Brisben-Heat.webp`,
        className: 'imgdpl'
    },
    'পার্থ স্কর্চার্স নারী': {
        src: `https://i.ibb.co/pz4ZR3J/Perth-Scorchers-Women.webp`,
        className: 'imgdpl'
    },
    'অ্যাডিলেড স্ট্রাইকার্স নারী': {
        src: `https://i.ibb.co/q9NjdXc/Adelaide-Strikers-Women.webp`,
        className: 'imgdpl'
    },
    'ওয়েস্টার্ন প্রভিন্স': {
        src: `https://i.ibb.co/fnXGF9m/Western-Province.webp`,
        className: 'imgdpl'
    },
    'ভিক্টোরিয়া': {
        src: `https://i.ibb.co/H4mMVcv/Victoria.webp`,
        className: 'imgdpl'
    },
    'নাইটস': {
        src: `https://i.ibb.co/dMpZ0Sy/knights.webp`,
        className: 'imgdpl'
    },
    'নিউ সাউথ ওয়েলস': {
        src: `https://i.ibb.co/ZT36QQN/New-South-Wales.webp`,
        className: 'imgdpl'
    },
    'লায়ন্স': {
        src: `https://i.ibb.co/09QMh5F/Lions.webp`,
        className: 'imgdpl'
    },
    'কাওয়াজুলু-নাটাল ইনল্যান্ড': {
        src: `https://i.ibb.co/C15D34Z/kwazulu-natal-inland.webp`,
        className: 'imgdpl'
    },
    'ইস্টার্ন্স': {
        src: `https://i.ibb.co/1v0FDRH/easterns.webp`,
        className: 'imgdpl'
    },
    'টাইটানস': {
        src: `https://i.ibb.co/jr6cWbL/Titans.webp`,
        className: 'imgdpl'
    },
    'বর্ডার': {
        src: `https://i.ibb.co/X3H6gZ8/Border.webp`,
        className: 'imgdpl'
    },
    'লিম্পোপো': {
        src: `https://i.ibb.co/WKnrWZW/Limopo.webp`,
        className: 'imgdpl'
    },
    'পানাদুরা স্পোর্টস ক্লাব': {
        src: `https://i.ibb.co/QNNxk80/Panadura-Sports-Club.webp`,
        className: 'imgdpl'
    },
    'সারাসেন্স স্পোর্টস ক্লাব': {
        src: `https://i.ibb.co/bBT1thG/Ace-Capital-Cricket-Club.webp`,
        className: 'imgdpl'
    },
    'বুর্গার রিক্রিয়েশন ক্লাব': {
        src: `https://i.ibb.co/BKHP0XR/Burgher.webp`,
        className: 'imgdpl'
    },
    'নুগেগোদা স্পোর্টস ক্লাব': {
        src: `https://i.ibb.co/SmtXR7S/Nugegoda-SWC.webp`,
        className: 'imgdpl'
    },
    'চিলাও মারিয়ান্স ক্রিকেট ক্লাব': {
        src: `https://i.ibb.co/PcC3CHP/Chilaw-Marians-Cricket-Club.webp`,
        className: 'imgdpl'
    },
    'গল ক্রিকেট ক্লাব': {
        src: `https://i.ibb.co/cwksPrV/Galle-Cricket-Club.webp`,
        className: 'imgdpl'
    },
    'নেগোম্বো ক্রিকেট ক্লাব': {
        src: `https://i.ibb.co/nkQXftH/Negombo-Cricket-Club.webp`,
        className: 'imgdpl'
    },
    'মুরস স্পোর্টস ক্লাব': {
        src: `https://i.ibb.co/mHj8vhy/Moors-Sports-Club.webp`,
        className: 'imgdpl'
    },
    'শ্রী লঙ্কা আর্মি স্পোর্টস ক্লাব': {
        src: `https://i.ibb.co/qmHSQpy/Sri-Lanka-Army-Sports-Club.webp`,
        className: 'imgdpl'
    },
    'ননডেসক্রিপ্টস ক্রিকেট ক্লাব': {
        src: `https://i.ibb.co/MSHnZxT/Nondescripts-Cricket-Club.webp`,
        className: 'imgdpl'
    },
    'ব্লুমফিল্ড ক্রিকেট অ্যান্ড অ্যাথলেটিক ক্লাব': {
        src: `https://i.ibb.co/ncD20VY/Bloomfield-Cricket-and-Athletic-Club.webp`,
        className: 'imgdpl'
    },
    'সেবাসটিয়ানিটেস ক্রিকেট অ্যান্ড অ্যাথলেটিক ক্লাব': {
        src: `https://i.ibb.co/5YC7P4q/Sebastianites-Cricket-and-Athletic-Club.webp`,
        className: 'imgdpl'
    },
    'দিল্লী বুলস': {
        src: `https://i.ibb.co/YTFN4Jm/Delhi-Bulls.webp`,
        className: 'imgdpl'
    },
    'টিম আবু ধাবি': {
        src: `https://i.ibb.co/nD8WLnH/Team-Abu-Dhabi.webp`,
        className: 'imgdpl'
    },
    'নর্দার্ন ওয়ারিয়র্স': {
        src: `https://i.ibb.co/HtLM4T2/Northern-Warriors.webp`,
        className: 'imgdpl'
    },
    'চেন্নাই ব্রেভস': {
        src: `https://i.ibb.co/T4MTyjk/Chennai-Braves.webp`,
        className: 'imgdpl'
    },
    'ডেকান গ্ল্যাডিয়াটর্স': {
        src: `https://i.ibb.co/9NSmHsg/Deccan-Gladiators.webp`,
        className: 'imgdpl'
    },
    'বাংলা টাইগার্স': {
        src: `https://i.ibb.co/mG9MTsV/Bangla-Tigers.webp`,
        className: 'imgdpl'
    },
    'ওয়েলিংটন নারী': {
        src: `https://i.ibb.co/9NSmHsg/Deccan-Gladiators.webp`,
        className: 'imgdpl'
    },
    'ক্যান্টারবারি নারী': {
        src: `https://i.ibb.co/mG9MTsV/Bangla-Tigers.webp`,
        className: 'imgdpl'
    },
    'চাতিশগড়': {
        src: `https://i.ibb.co/8NN8nvL/Chattishgarh.webp`,
        className: 'imgdpl'
    },
    'মহারাষ্ট্র': {
        src: `https://i.ibb.co/2FKn88s/Maharastra.webp`,
        className: 'imgdpl'
    },
    'অরুণাচল প্রদেশ': {
        src: `https://i.ibb.co/n8KNs41/Arunachol.webp`,
        className: 'imgdpl'
    },
    'নাগাল্যান্ড': {
        src: `https://i.ibb.co/PZsbFsS/Nagaland.webp`,
        className: 'imgdpl'
    },
    'সিক্কিম': {
        src: `https://i.ibb.co/PxhggvV/Sikkim.webp`,
        className: 'imgdpl'
    },
    'ত্রিপুরা': {
        src: `https://i.ibb.co/YBwcF3t/Tripura.webp`,
        className: 'imgdpl'
    },
    'চণ্ডিগড়': {
        src: `https://i.ibb.co/qCRfhtT/Chandigarh.webp`,
        className: 'imgdpl'
    },
    'মধ্য প্রদেশ': {
        src: `https://i.ibb.co/swth6JN/Maddha-Pradesh.webp`,
        className: 'imgdpl'
    },
    'বান্দ-এ-আমির রিজিয়ন': {
        src: `https://i.ibb.co/dW48Zq8/Band-e-Amir.webp`,
        className: 'imgdpl'
    },
    'পার্থ স্কর্চার্স': {
        src: `https://i.ibb.co/DpmVnxv/Perth-Scorchers.webp`,
        className: 'imgdpl'
    },
    'অ্যাডিলেড স্ট্রাইকার্স': {
        src: `https://i.ibb.co/q9NjdXc/Adelaide-Strikers-Women.webp`,
        className: 'imgdpl'
    },
    'কাওলুন লায়ন্স': {
        src: `https://i.ibb.co/cJcm7Fz/Kowloon-Lions.webp`,
        className: 'imgdpl'
    },
    'হংকং আইল্যান্ডার্স': {
        src: `https://i.ibb.co/wJ8WgNF/Hong-Kong-Islanders.webp`,
        className: 'imgdpl'
    },
    'ক্যান্ডি ওয়ারিয়র্স': {
        src: `https://i.ibb.co/4TrmVqZ/Kandy-Warriors.webp`,
        className: 'imgdpl'
    },
    'জাফনা কিংস': {
        src: `https://i.ibb.co/v38ss2g/Jaffna-Kings.webp`,
        className: 'imgdpl'
    },
    'গল গ্ল্যাডিয়েটর্স': {
        src: `https://i.ibb.co/0MXCJz1/Galle-Gladiators.webp`,
        className: 'imgdpl'
    },
    'নর্দার্ন ডিস্ট্রিক্টস': {
        src: `https://i.ibb.co/phCFyC9/Northern-Districts.webp`,
        className: 'imgdpl'
    },
    'ফরচুন বরিশাল': {
        src: `https://i.ibb.co/HhwDDJd/Fortune-Barisal.webp`,
        className: 'imgdpl'
    },
    'চট্টগ্রাম চ্যাালেঞ্জার্স': {
        src: `https://i.ibb.co/Sxftxz6/Chattogram-Challengers.webp`,
        className: 'imgdpl'
    },
    'সিলেট সানরাইজার্স': {
        src: `https://i.ibb.co/5LLXYS9/Sylhet-Sunrisers.webp`,
        className: 'imgdpl'
    },
    'কুমিল্লা ভিক্টোরিয়ান্স': {
        src: `https://i.ibb.co/4pjCcx2/Cumilla-Warriors.webp`,
        className: 'imgdpl'
    },
    'মিনিস্টার গ্রুপ ঢাকা': {
        src: `https://i.ibb.co/6Nw9TSQ/Minister-Dhaka.webp`,
        className: 'imgdpl'
    },
    'খুলনা টাইগার্স': {
        src: `https://i.ibb.co/qgH655F/Khulna-Tigers.webp`,
        className: 'imgdpl'
    },
    'রংপুর রাইডার্স': {
        src: `https://i.ibb.co/cNrhdsg/Rangpur-Riders-Logo.png`,
        className: 'imgdpl'
    },
    'ঢাকা ডমিনেটর্স': {
        src: `https://i.ibb.co/gTC5whD/Dhaka-Dominators.png`,
        className: 'imgdpl'
    },
    'সিলেট স্ট্রাইকার্স': {
        src: `https://i.ibb.co/ZN8jH8Y/Sylhet-Strikers-logo.png`,
        className: 'imgdpl'
    },


    'জাফনা': {
        src: `https://i.ibb.co/ZTV8mB7/Jaffna.webp`,
        className: 'imgdpl'
    },
    'গল': {
        src: `https://i.ibb.co/cbXcTNQ/Galle.webp`,
        className: 'imgdpl'
    },
    'ক্যান্ডি': {
        src: `https://i.ibb.co/mqqJJ3f/Kandy.webp`,
        className: 'imgdpl'
    },
    'ডাম্বুলা': {
        src: `https://i.ibb.co/zRXR2XV/Dambulla.webp`,
        className: 'imgdpl'
    },
    'ওয়েস্টার্ন অস্ট্রেলিয়া নারী': {
        src: `https://i.ibb.co/6gdqX9v/Western-Australia-Women.webp`,
        className: 'imgdpl'
    },
    'কুইন্সল্যান্ড নারী': {
        src: `https://i.ibb.co/pvV5FKj/Queensland-Women.webp`,
        className: 'imgdpl'
    },
    'দিল্লী': {
        src: `https://i.ibb.co/FWqcbj4/Delhi.webp`,
        className: 'imgdpl'
    },
    'মণিপুর': {
        src: `https://i.ibb.co/hFnvKGJ/Monipur.webp`,
        className: 'imgdpl'
    },
    'মিজোরাম': {
        src: `https://i.ibb.co/VLWCDVt/Mizoram.webp`,
        className: 'imgdpl'
    },
    'মুম্বাই': {
        src: `https://i.ibb.co/wNNXcpS/Mumbai.webp`,
        className: 'imgdpl'
    },
    'কেরালা': {
        src: `https://i.ibb.co/YDxYCBR/Kerala.webp`,
        className: 'imgdpl'
    },
    'জম্মু ও কাশ্মীর': {
        src: `https://i.ibb.co/c8DFTCK/Jammu-Kashmir.webp`,
        className: 'imgdpl'
    },
    'মেঘালয়': {
        src: `https://i.ibb.co/F4Yx3Bx/Meghalaya.webp`,
        className: 'imgdpl'
    },
    'হিমাচল প্রদেশ': {
        src: `https://i.ibb.co/GVkk31b/Himachal-Pradesh.webp`,
        className: 'imgdpl'
    },
    'গুজরাট': {
        src: `https://i.ibb.co/rmSGFFr/Gujarat.webp`,
        className: 'imgdpl'
    },
    'গোয়া': {
        src: `https://i.ibb.co/sRtrjgp/Goa.webp`,
        className: 'imgdpl'
    },
    'বারোদা': {
        src: `https://i.ibb.co/2FG72CV/Baroda.webp`,
        className: 'imgdpl'
    },
    'বেঙ্গল': {
        src: `https://i.ibb.co/2P0srz2/Bengal.webp`,
        className: 'imgdpl'
    },
    'ঝাড়খন্ড': {
        src: `https://i.ibb.co/CbTT16L/Jharkhond.webp`,
        className: 'imgdpl'
    },
    'উড়িষ্যা': {
        src: `https://i.ibb.co/9n8FJKr/Odisha.webp`,
        className: 'imgdpl'
    },
    'রেলওয়েজ': {
        src: `https://i.ibb.co/8B9m0p0/Railways.webp`,
        className: 'imgdpl'
    },
    'উত্তরাখণ্ড': {
        src: `https://i.ibb.co/p01C3JF/Uttarakhand.webp`,
        className: 'imgdpl'
    },
    'পুডুচেরি': {
        src: `https://i.ibb.co/xFpMZWN/Punducherry.webp`,
        className: 'imgdpl'
    },
    'উত্তর প্রদেশ': {
        src: `https://i.ibb.co/yN61LNq/Uttar-Pradesh.webp`,
        className: 'imgdpl'
    },
    'আসাম': {
        src: `https://i.ibb.co/KKNw1sv/Assam.webp`,
        className: 'imgdpl'
    },
    'বিদর্ভ': {
        src: `https://i.ibb.co/J7ZzXL3/Vidarbha.webp`,
        className: 'imgdpl'
    },
    'হারিয়ানা': {
        src: `https://i.ibb.co/6X7q77h/Hariyana.webp`,
        className: 'imgdpl'
    },
    'সার্ভিসেস': {
        src: `https://i.ibb.co/HGFDkHh/Services.webp`,
        className: 'imgdpl'
    },
    'পাঞ্জাব': {
        src: `https://i.ibb.co/7WCwR5f/Punjab.webp`,
        className: 'imgdpl'
    },
    'হায়দারাবাদ(ভারত)': {
        src: `https://i.ibb.co/k0yrVpB/Hyderabad-India.webp`,
        className: 'imgdpl'
    },
    'অন্ধ্র': {
        src: `https://i.ibb.co/xJTWRs3/Andhra.webp`,
        className: 'imgdpl'
    },
    'সৌরাষ্ট্র': {
        src: `https://i.ibb.co/2dNcq4L/Saurashtra.webp`,
        className: 'imgdpl'
    },
    'রাজস্থান': {
        src: `https://i.ibb.co/Mk7BdrQ/Rajasthan.webp`,
        className: 'imgdpl'
    },
    'তামিল নাড়ু': {
        src: `https://i.ibb.co/18Mn8WG/Tamil-Nadu.webp`,
        className: 'imgdpl'
    },
    'কর্ণাটক': {
        src: `https://i.ibb.co/fND0LBt/Karnataka.webp`,
        className: 'imgdpl'
    },
    'বিহার': {
        src: `https://i.ibb.co/440Mdt2/Bihar.webp`,
        className: 'imgdpl'
    },

    'হংকং ক্রিকেট ক্লাব': {
        src: `https://i.ibb.co/wSVm8qN/hongkongcricketclub.jpg`,
        className: 'imgdpl'
    },
    'পাকিস্তান অ্যাসোসিয়েশন অফ হংকং': {
        src: `https://i.ibb.co/t2MGv8v/hongkongassociation.webp`,
        className: 'imgdpl'
    },
    'কওলুন ক্রিকেট ক্লাব': {
        src: `https://i.ibb.co/8P4G70d/kowlooncricketclub.png`,
        className: 'imgdpl'
    },
    'ইউনাইটেড সার্ভিসেস রিক্রিয়েশন ক্লাব': {
        src: `https://i.ibb.co/DzXsNXN/united-services-recreation-club.png`,
        className: 'imgdpl'
    },
    'ইউপি ওয়ারিয়র্জ ওমেন' : {
        src: 'https://i.ibb.co/j5H6zK7/UP-Warriorz-Logo.webp',
        className: 'imgdbl'
    },
    'ইউপি ওয়ারিয়র্জ নারী' : {
        src: 'https://i.ibb.co/j5H6zK7/UP-Warriorz-Logo.webp',
        className: 'imgdbl'
    },
    'করাচি কিংস' : {
        src: 'https://i.ibb.co/8skQK1G/Karachi-Kings.png',
        className: 'imgdbl'
    },
    'লাহোর কালান্দারস' : {
        src: 'https://i.ibb.co/gFs012b/Lahore-Qalandars.png',
        className: 'imgdbl'
    },
    'ঢাকা লেপার্ডস' : {
        src: 'https://i.ibb.co/Tv7YQDF/dhaka-leopards.webp',
        className: 'imgdbl'
    },
    'অগ্রণী ব্যাংক ক্রিকেট ক্লাব' : {
        src: 'https://i.ibb.co/NTV5p8Q/Agrani-Bank.jpg',
        className: 'imgdbl'
    },
    'ত্রিনিদাদ অ্যান্ড টোবাগো' : {
        src: 'https://i.ibb.co/q0qxCqB/2560px-Flag-of-Trinidad-and-Tobago-svg.png',
        className: 'imgdbl'
    },
    'বার্বাডোজ' : {
        src: 'https://i.ibb.co/4Z4N0KW/Flag-of-Barbados-svg.png',
        className: 'imgdbl'
    },
    'জ্যামাইকা' : {
        src: 'https://i.ibb.co/ZcZGm7X/Flag-of-Jamaica-svg.png',
        className: 'imgdbl'
    },
    'গায়ানা' : {
        src: 'https://i.ibb.co/xjW3V2w/Flag-of-Guyana-svg.png',
        className: 'imgdbl'
    },
    'লিওয়ার্ড আইল্যান্ডস' : {
        src: 'https://i.ibb.co/xmqBXcn/Leewards-islands-flag-1.png',
        className: 'imgdbl'
    },
    'উইন্ডওয়ার্ড আইল্যান্ডস' : {
        src: 'https://i.ibb.co/8gFQtj0/Windward-islands-flag-1.png',
        className: 'imgdbl'
    },
    'মাউন্টেনিয়ার্স ওমেন' : {
        src: 'https://i.ibb.co/kGQsL7F/mountaineers.webp',
        className: 'imgdbl'
    },
    'ইগলস ওমেন' : {
        src: 'https://i.ibb.co/z5qZgqz/eagles-women.webp',
        className: 'imgdbl'
    },
    'টাসকার্স ওমেন' : {
        src: 'https://i.ibb.co/Gks0ZTC/tuskers-women-2.webp',
        className: 'imgdbl'
    },
    'রাইনোস ওমেন' : {
        src: 'https://i.ibb.co/brRFMZB/rhions.webp',
        className: 'imgdbl'
    },
    'প্রভিন্স নাম্বার ওয়ান নারী' : {
        src: 'https://i.ibb.co/PMmC6BH/province-1.webp',
        className: 'imgdbl'
    },
    'আর্মড পুলিশ ফোর্স নারী' : {
        src: 'https://i.ibb.co/mbmXq38/apf-women.webp',
        className: 'imgdbl'
    },
    'গুয়াহাটি অ্যাভেঞ্জার্স' : {
        src: 'https://i.ibb.co/hH0HNs0/guahati-avengers.png',
        className: 'imgdbl'
    },
    'ইনদোর নাইটস' : {
        src: 'https://i.ibb.co/3zbP49J/indore-knights-2.png',
        className: 'imgdbl'
    },
    'নর্থ ওয়েস্ট নারী' : {
        src: 'https://i.ibb.co/ngS9SD3/north-west-women.jpg',
        className: 'imgdbl'
    },
    'সাউথ ওয়েস্টার্ন ডিস্ট্রিক্টস নারী' : {
        src: 'https://i.ibb.co/1zg0XVm/swd-women.jpg',
        className: 'imgdbl'
    },
    'নর্দার্ন কেপ নারী' : {
        src: 'https://i.ibb.co/93N5bFz/northern-cape.jpg',
        className: 'imgdbl'
    },
    'ওয়েস্টার্ন প্রভিন্স নারী' : {
        src: 'https://i.ibb.co/K2CwLBX/western-province-2.jpg',
        className: 'imgdbl'
    },
    'ফ্রি স্টেট নারী' : {
        src: 'https://i.ibb.co/WgXjxQc/free-state.jpg',
        className: 'imgdbl'
    },
};

export default FlagByCountry;