const keySeries = [
    {
        "name": "আইসিসি ওয়ার্ল্ড টেস্ট চ্যাম্পিয়নশীপ",
        "url": "/tournament/icc-world-test-championship-2021-2023"
    },
    {
        "name": "এশিয়া কাপ'২২",
        "url": "/tournament/asia-cup-2022"
    },
    {
        "name": "আইসিসি ওমেন্স  চ্যাম্পিয়নশীপ",
        "url": "/tournament/icc-women-s-championship-2022-23-2025"
    },
    {
        "name": "আইসিসি মেন্স ক্রিকেট ওয়ার্ল্ড কাপ সুপার লিগ",
        "url": "/tournament/world-cup-super-league-2020-2022-23"
    }
];

export default keySeries;