import React, {
    useEffect
} from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    Button,
    // Divider,
    Grid,
    Link,
    SvgIcon,
    Typography,
    useTheme
} from '@mui/material';
import {
    makeStyles
} from '@mui/styles';
import {
    Copyright
} from '@mui/icons-material';
import {
    Link as CustomLink,
    Divider
} from '../../commonComponent';
import {
    dfs
} from '../../Api';
import {
    fetchStaticContents
} from '../../redux/actions/StaticContent';
import {
    getStaticContents
} from '../../redux/reducers';
import {
    keySeries,
    socialmedia
} from '../../assets/data';
import Logo from '../../assets/KhelahobeLogo.png';

const useStyles = makeStyles(
    theme => (
        {
            root: {
                width: '100%',
                minHeight: '25vh',
                overflow: 'hidden',
                margin: '0 auto',
                color: theme.palette.secondary.dark,
                '& .MuiButton-label': {
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start'
                }
            },
            icon: {
                fontSize: '15px',
                color: theme.palette.secondary.main,
                //display: 'inline-flex',
                verticalAlign: 'middle',
                position: 'static',
            },
            item: {
                //minWidth: '200px',
                lineHeight: '1.8',
                // margin: '0 auto',
                color: theme.palette.secondary.dark,
                textAlign: 'left',
                margin: theme.spacing(1)
            },
            typo: {
                color: theme.palette.secondary.dark,
            },
            link: {
                textDecoration: 'none',
                color: theme.palette.secondary.dark,
                width: '100%'
            },
            linkWithoutColor: {
                color: theme.palette.secondary.dark,
                width: 'auto',
                minWidth: '60%',
                maxWidth: '100%'
            },
            divider: {
                width: '80%',
                margin: '10px 0'
            },
            logo: {
                width: 80,
                height: 80,
                transform: 'scale(1.5)',
                objectFit: 'cover'
            },
            copyright: {
                margin: '0 auto',
                textAlign: 'center'
            }
        }
    )
);

const Footer = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    var currentTime = new Date();
    currentTime = `২০১৮-${dfs(currentTime.getFullYear())}`;
    const links = useSelector(
        state => getStaticContents(state)
    );
    const theme = useTheme();

    useEffect(
        () => {
            dispatch(
                fetchStaticContents()
            );
        },
        [
            dispatch
        ]
    );

    return (
        <Grid
            container
            justifyContent="flex-start"
            alignItems='flex-start'
            direction='row'
            className={classes.root}
        // spacing={3}
        >
            <Grid
                item
                lg={3}
                md={3}
                sm={6}
                xs={12}
                sx={{
                    lineHeight: '1.8',
                    // margin: '0 auto',
                    color: theme.palette.secondary.dark,
                    textAlign: 'left',
                    margin: theme.spacing(1)
                }}
            >
                <Typography
                    color="inherit"
                    variant="h6"
                    align="left"
                >
                    <img
                        src={Logo}
                        alt='খেলা হবে!'
                        className={classes.logo}
                    />
                </Typography>

                <Typography
                    sx={{
                        color: theme.palette.primary.dark
                    }}
                    variant="h6"
                    align="left"
                >
                    খেলা হবে!
                </Typography>
                <Typography
                    color="inherit"
                    variant="body2"
                    align="left"
                    gutterBottom
                >
                    ঠিকানা: বাড়ি নম্বর ৩৭, রোড নম্বর ৩, ধানমন্ডি, ঢাকা
                </Typography>
                <Typography
                    color="inherit"
                    variant="body2"
                    align="left"
                    gutterBottom
                >
                    ই-মেইল: tarifezaz@aubichol.com
                </Typography>
            </Grid>
            <Grid
                item
                lg={3}
                md={3}
                sm={6}
                xs={12}
                sx={{
                    lineHeight: '1.8',
                    // margin: '0 auto',
                    color: theme.palette.primary.dark,
                    textAlign: 'left',
                    margin: theme.spacing(1),
                }}
            >
                <Typography
                    color="inherit"
                    variant="h6"
                    align="inherit"
                //gutterBottom
                >
                    কী-সিরিজ
                </Typography>
                <Divider
                    sx={{
                        width: '80%',
                        margin: '10px 0'
                    }}
                />
                {
                    keySeries.map(
                        (
                            series, index
                        ) => (
                            <Button
                                key={index}
                                sx={{
                                    color: theme.palette.secondary.dark,
                                    width: '100%',
                                    minWidth: '60%',
                                    justifyContent: 'flex-start',
                                    maxWidth: '100%',
                                    "&:hover": {
                                        // backgroundColor: theme.palette.primary.light,
                                        color: theme.palette.primary.dark,
                                        textDecoration: 'underline'
                                    }
                                }}
                                to={series.url}
                                component={CustomLink}
                            >
                                {series.name}
                            </Button>
                        )
                    )
                }
            </Grid>
            <Grid
                item
                lg={3}
                md={3}
                sm={6}
                xs={12}
                sx={{
                    lineHeight: '1.8',
                    // margin: '0 auto',
                    color: theme.palette.primary.dark,
                    textAlign: 'left',
                    margin: theme.spacing(1)
                }}
            >
                <Typography
                    color="inherit"
                    variant="h6"
                    align="left"
                >
                    আমাদেরকে অনুসরণ করুন
                </Typography>
                <Divider
                    sx={{
                        width: '80%',
                        margin: '10px 0'
                    }}
                />
                {
                    socialmedia.map(
                        (
                            row,
                            index
                        ) => (
                            <Button
                                key={index}
                                component={CustomLink}
                                sx={{
                                    // link: {
                                    textDecoration: 'none',
                                    color: theme.palette.secondary.dark,
                                    justifyContent: 'flex-start',
                                    width: '100%',
                                    "&:hover": {
                                        textDecoration: 'underline',
                                        color: theme.palette.primary.dark
                                    }
                                    // },
                                }}
                                startIcon={
                                    <SvgIcon
                                        className={classes.icon}
                                        sx={{
                                            "&:hover": {
                                                backgroundColor: theme.palette.primary.light,
                                                color: theme.palette.primary.dark
                                            }
                                        }}
                                    >
                                        <svg
                                            xmlns={row.xmlns}
                                            viewBox={row.viewBox}
                                        >
                                            <path
                                                d={row.path}
                                            />
                                        </svg>
                                    </SvgIcon>
                                }
                            >
                                {row.name}
                            </Button>
                        )
                    )
                }
            </Grid>
            <Grid
                item
                lg={2}
                md={2}
                sm={6}
                xs={12}
                sx={{
                    lineHeight: '1.8',
                    // margin: '0 auto',
                    color: theme.palette.primary.dark,
                    textAlign: 'left',
                    margin: theme.spacing(1)
                }}
            >
                <Typography
                    color="inherit"
                    variant="h6"
                    align="left"
                    gutterBottom
                >
                    আমাদের কোম্পানি
                </Typography>
                <Divider
                    sx={{
                        width: '80%',
                        margin: '10px 0'
                    }}
                />
                {
                    links.map(
                        (
                            row, index
                        ) => (
                            <Button
                                component={CustomLink}
                                key={index}
                                to={`/footer/${row.content_slug}`}
                                sx={{
                                    // link: {
                                    textDecoration: 'none',
                                    color: theme.palette.secondary.dark,
                                    justifyContent: 'flex-start',
                                    width: '100%',
                                    "&:hover": {
                                        textDecoration: 'underline',
                                        color: theme.palette.primary.dark
                                    }
                                    // },
                                }}
                            >
                                {row.title}
                            </Button>
                        )
                    )
                }
            </Grid>
            <Divider

            />
            <Grid
                item
                xs={12}
                className={classes.copyright}
            >
                <Typography
                    color="inherit"
                    variant="caption"
                    //align="right"
                    gutterBottom
                >
                    {currentTime}
                    <Copyright
                        className={classes.icon}
                    />
                    khelahobe.co, <Link
                        href="http://www.aubichol.com"
                        sx={{
                            // link: {
                            textDecoration: 'none',
                            color: theme.palette.secondary.dark,
                            width: '100%',
                            "&:hover": {
                                textDecoration: 'underline',
                                color: theme.palette.primary.dark
                            }
                            // },
                        }}
                        underline='none'
                    >
                        অবিচল ইন্টেলিজেন্ট টেকনোলজিস
                    </Link>, সর্বস্বত্ব সংরক্ষিত।
                </Typography>
            </Grid>
        </Grid >
    );
};

export default Footer;