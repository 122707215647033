import React, {
    useState
} from 'react';
import {
    Alert,
    Snackbar,
} from '@mui/material';
import {
    makeStyles
} from '@mui/styles';

function Alerts(props) {
    return <Alert
        elevation={1}
        {...props}
        style={{
            width: '100%',
            '& .MuiAlert-filledWarning': {
                backgroundColor: '#e8f5e9',
                color: '#1b5e20'
            },
            '& .MuiAlert-message': {
                color: '#1b5e20'
            }
        }}
    />;
};

const useStyles = makeStyles(
    theme => (
        {
            root: {
                width: '100%',
            },
            alert: {
                width: '100%',
                '& .MuiAlert-message': {
                    color: '#1b5e20'
                }
            },
        }
    )
);

export default function Notification({
    severity,
    message,
}) {
    const classes = useStyles();
    const [
        open,
        setOpen
    ] = useState(true);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <Snackbar
            anchorOrigin={
                {
                    vertical: 'bottom',
                    horizontal: 'center'
                }
            }
            open={open}
            onClose={handleClose}
            className={classes.root}
        >
            <Alerts
                variant='filled'
                onClose={handleClose}
                severity={severity}
                color='warning'
                className={classes.alert}
            >
                {message}
            </Alerts>
        </Snackbar>
    );
};