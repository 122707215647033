import {
    applyMiddleware,
    compose,
    createStore,
} from 'redux';
import logger from 'redux-logger';
import promise from 'redux-promise';
import thunk from 'redux-thunk';
import apiFakeMiddleware from '../middleware/ApiFake';
import reducers from '../reducers';
import {
    loadState
} from '../../utils/LocalStorage';

const middlewares = () => {
    let m = [promise, apiFakeMiddleware, thunk];

    if (process.env.NODE_ENV === 'development') m = [...m, logger];

    return m;
};

const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const configureStore = () => {
    const initialState = loadState();
    const store = createStore(
        reducers,
        initialState,
        composeEnhancers(applyMiddleware(...middlewares()))
    );

    return store;
}

export default configureStore;