import React from 'react';
import {
    Grid,
    Typography,
} from '@mui/material';
import {
    makeStyles
} from '@mui/styles';
import PropTypes from 'prop-types';
import {
    Flag
} from '../../index';

const useStyles = makeStyles(
    theme => (
        {
            typ: {
                width: '100%',
                height: '100%',
                padding: theme.spacing(1),
                margin: '0 auto',
                color: theme.palette.primary.dark,
                [theme.breakpoints.down('sm')]: {
                    padding: theme.spacing(0),
                },
            },
            div: {
                width: '60%',
                margin: '0px auto 10px',
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                },
            }
        }
    )
);

const Banner = ({
    t1,
    t2,
}) => {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.div}
        >
            <Grid
                item
                xs={5}
                align='right'
                className={classes.typ}
            >
                <Typography
                    // variant='h6'
                    color='inherit'
                    align='center'
                    sx={{
                        fontWeight: 900
                    }}
                >
                    <Flag
                        country={t1}
                    />
                    <br />
                    {t1}
                </Typography>
            </Grid>
            <Grid
                item
                xs={2}
                className={classes.typ}
            >
                <Typography
                    variant='h6'
                    color='inherit'
                    align='center'
                >
                    বনাম
                </Typography>
            </Grid>
            <Grid
                item
                xs={5}
                className={classes.typ}
            >
                <Typography
                    sx={{
                        fontWeight: 900
                    }}
                    color='inherit'
                    align='center'
                >
                    <Flag
                        country={t2}
                    />
                    <br />
                    {t2}
                </Typography>
            </Grid>
        </Grid>
    );
}

Banner.propTypes = {
    t1: PropTypes.string.isRequired,
    t2: PropTypes.string.isRequired,
};

export default Banner;