import React from 'react';
import {
    FacebookShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
    ViberIcon,
    ViberShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import {
    makeStyles
} from '@mui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    theme => (
        {
            share: {
                verticalAlign: 'middle',
                paddingLeft: 3,
                paddingRight: 3,
                paddingBottom: 4
            },
            shareSegment: {
                paddingLeft: 16,
                fontWeight: 600
            },
        }
    )
);

const SocialMediaShare = ({
    pathname,
    title,
    hashes,
    ...props
}) => {
    const classes = useStyles();

    return (
        <div
            className={classes.shareSegment}
            {...props}
        >
            শেয়ার করুন
            <FacebookShareButton
                url={pathname}
                quote={title}
                hashtag={
                    hashes[0]
                    &&
                    hashes[0]
                }
                description={title}
            // className="Demo__some-network__share-button"
            >
                <FacebookIcon
                    size={32}
                    round
                    className={classes.share}
                />
            </FacebookShareButton>
            <FacebookMessengerShareButton
                title={title}
                url={pathname}
                appId={682933902690427}
            >
                <FacebookMessengerIcon
                    size={32}
                    round
                    className={classes.share}
                />
            </FacebookMessengerShareButton>
            <TwitterShareButton
                title={title}
                url={pathname}
                hashtags={hashes}
            >
                <TwitterIcon
                    size={32}
                    round
                    className={classes.share}
                />
            </TwitterShareButton>
            <WhatsappShareButton
                title={title}
                url={pathname}
            >
                <WhatsappIcon
                    size={32}
                    round
                    className={classes.share}
                />
            </WhatsappShareButton>
            <ViberShareButton
                title={title}
                url={pathname}
            >
                <ViberIcon
                    size={32}
                    round
                    className={classes.share}
                />
            </ViberShareButton>
            <LinkedinShareButton
                title={title}
                url={pathname}
            >
                <LinkedinIcon
                    size={32}
                    round
                    className={classes.share}
                />
            </LinkedinShareButton>
        </div>
    );
}

SocialMediaShare.propTypes = {
    pathname: PropTypes.string.isRequired,
};

export default SocialMediaShare;