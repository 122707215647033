const data = [
    {
        link: 'scorecard',
        text: 'স্কোরবোর্ড'
    },
    {
        link: 'commentary',
        text: 'ধারাভাষ্য'
    },
    {
        link: 'summary',
        text: 'সারসংক্ষেপ'
    },
];

export default data;