import {
    getToken
} from '../reducers';
import {
    endPoints
} from '../../Api';
import {actions} from "../action-types/staticContent"

export const giveActions = () => {
    return actions ; 
}

export const fetchStaticContents = () => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_FETCH_STATIC_CONTENTS,
            actions.SUCCESS_FETCH_STATIC_CONTENTS,
            actions.FAILURE_FETCH_STATIC_CONTENTS
        ],
        method: endPoints.FETCH_STATIC_CONTENTS,
        race: () => false,
    }
});

export const createStaticContent = state => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_CREATE_STATIC_CONTENT,
            actions.SUCCESS_CREATE_STATIC_CONTENT,
            actions.FAILURE_CREATE_STATIC_CONTENT
        ],
        method: endPoints.CREATE_STATIC_CONTENT,
        race: () => false,
        data: {
            ...state,
            token: getToken(getState())
        }
    }
});

export const updateStaticContent = state => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_UPDATE_STATIC_CONTENT,
            actions.SUCCESS_UPDATE_STATIC_CONTENT,
            actions.FAILURE_UPDATE_STATIC_CONTENT
        ],
        method: endPoints.UPDATE_STATIC_CONTENT,
        race: () => false,
        data: {
            ...state,
            token: getToken(getState()),
            id: state.id
        }
    }
});

export const deleteStaticContent = id => (
    dispatch,
    getState
) => dispatch({
    RSAA: {
        types: [
            actions.REQUEST_DELETE_STATIC_CONTENT,
            actions.SUCCESS_DELETE_STATIC_CONTENT,
            actions.FAILURE_DELETE_STATIC_CONTENT
        ],
        method: endPoints.DELETE_STATIC_CONTENT,
        race: () => false,
        data: {
            id,
            token: getToken(getState()),
        }
    }
});

export const fetchStaticContentById = id => ({
    RSAA: {
        types: [
            actions.REQUEST_FETCH_STATIC_CONTENT_BY_ID,
            actions.SUCCESS_FETCH_STATIC_CONTENT_BY_ID,
            actions.FAILURE_FETCH_STATIC_CONTENT_BY_ID,
        ],
        method: endPoints.FETCH_STATIC_CONTENT_BY_ID,
        race: () => false,
        data: {
            id,
        },
    },
});

export const fetchStaticContentBySlug = slug => ({
    RSAA: {
        types: [
            actions.REQUEST_FETCH_STATIC_CONTENT_BY_SLUG,
            actions.SUCCESS_FETCH_STATIC_CONTENT_BY_SLUG,
            actions.FAILURE_FETCH_STATIC_CONTENT_BY_SLUG,
        ],
        method: endPoints.FETCH_STATIC_CONTENT_BY_SLUG,
        race: () => false,
        data: {
            slug,
        },
    },
});