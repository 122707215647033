export const actions = {
    REQUEST_SEND_TOKEN: 'REQUEST_SEND_TOKEN',
    SUCCESS_SEND_TOKEN: 'SUCCESS_SEND_TOKEN',
    FAILURE_SEND_TOKEN: 'FAILURE_SEND_TOKEN',

    REQUEST_FETCH_TOKENS: 'REQUEST_FETCH_TOKENS',
    SUCCESS_FETCH_TOKENS: 'SUCCESS_FETCH_TOKENS',
    FAILURE_FETCH_TOKENS: 'FAILURE_FETCH_TOKENS',

    REQUEST_FETCH_TOKEN_BY_ID: 'REQUEST_FETCH_TOKEN_BY_ID',
    SUCCESS_FETCH_TOKEN_BY_ID: 'SUCCESS_FETCH_TOKEN_BY_ID',
    FAILURE_FETCH_TOKEN_BY_ID: 'FAILURE_FETCH_TOKEN_BY_ID',

    REQUEST_CREATE_NOTIFICATION: 'REQUEST_CREATE_NOTIFICATION',
    SUCCESS_CREATE_NOTIFICATION: 'SUCCESS_CREATE_NOTIFICATION',
    FAILURE_CREATE_NOTIFICATION: 'FAILURE_CREATE_NOTIFICATION',
}