import React from 'react';
import withWidth, {
    isWidthUp
} from '@material-ui/core/withWidth';

import AppbarForBigger from './AppbarForBigger';
import AppbarForSmaller from './AppbarForSmaller';

const app = ({
    width
}) => {
    if (isWidthUp('md', width)) {
        return <AppbarForBigger />
    }
    return <AppbarForSmaller />;
}

export default withWidth()(app);