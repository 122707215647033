export const actions = {
    REQUEST_FETCH_TOURNAMENT: 'REQUEST_FETCH_TOURNAMENT',
    SUCCESS_FETCH_TOURNAMENT: 'SUCCESS_FETCH_TOURNAMENT',
    FAILURE_FETCH_TOURNAMENT: 'FAILURE_FETCH_TOURNAMENT',

    REQUEST_FETCH_TOURNAMENT_BY_ID: 'REQUEST_FETCH_TOURNAMENT_BY_ID',
    SUCCESS_FETCH_TOURNAMENT_BY_ID: 'SUCCESS_FETCH_TOURNAMENT_BY_ID',
    FAILURE_FETCH_TOURNAMENT_BY_ID: 'FAILURE_FETCH_TOURNAMENT_BY_ID',

    REQUEST_FETCH_TOURNAMENT_BY_SLUG: 'REQUEST_FETCH_TOURNAMENT_BY_SLUG',
    SUCCESS_FETCH_TOURNAMENT_BY_SLUG: 'SUCCESS_FETCH_TOURNAMENT_BY_SLUG',
    FAILURE_FETCH_TOURNAMENT_BY_SLUG: 'FAILURE_FETCH_TOURNAMENT_BY_SLUG',

    REQUEST_FETCH_ALL_TOURNAMENT: 'REQUEST_FETCH_ALL_TOURNAMENT',
    SUCCESS_FETCH_ALL_TOURNAMENT: 'SUCCESS_FETCH_ALL_TOURNAMENT',
    FAILURE_FETCH_ALL_TOURNAMENT: 'FAILURE_FETCH_ALL_TOURNAMENT',
}