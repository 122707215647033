export const actions = {
    REQUEST_FETCH_ON_THIS_DAY: 'REQUEST_FETCH_ON_THIS_DAY',
    SUCCESS_FETCH_ON_THIS_DAY: 'SUCCESS_FETCH_ON_THIS_DAY',
    FAILURE_FETCH_ON_THIS_DAY: 'FAILURE_FETCH_ON_THIS_DAY',

    REQUEST_FETCH_ON_THIS_DAY_BY_ID: 'REQUEST_FETCH_ON_THIS_DAY_BY_ID',
    SUCCESS_FETCH_ON_THIS_DAY_BY_ID: 'SUCCESS_FETCH_ON_THIS_DAY_BY_ID',
    FAILURE_FETCH_ON_THIS_DAY_BY_ID: 'FAILURE_FETCH_ON_THIS_DAY_BY_ID',

    REQUEST_FETCH_ON_THIS_DAY_BY_SLUG: 'REQUEST_FETCH_ON_THIS_DAY_BY_SLUG',
    SUCCESS_FETCH_ON_THIS_DAY_BY_SLUG: 'SUCCESS_FETCH_ON_THIS_DAY_BY_SLUG',
    FAILURE_FETCH_ON_THIS_DAY_BY_SLUG: 'FAILURE_FETCH_ON_THIS_DAY_BY_SLUG',

    REQUEST_CREATE_ON_THIS_DAY: 'REQUEST_CREATE_ON_THIS_DAY',
    SUCCESS_CREATE_ON_THIS_DAY: 'SUCCESS_CREATE_ON_THIS_DAY',
    FAILURE_CREATE_ON_THIS_DAY: 'FAILURE_CREATE_ON_THIS_DAY',

    REQUEST_UPDATE_ON_THIS_DAY: 'REQUEST_UPDATE_ON_THIS_DAY',
    SUCCESS_UPDATE_ON_THIS_DAY: 'SUCCESS_UPDATE_ON_THIS_DAY',
    FAILURE_UPDATE_ON_THIS_DAY: 'FAILURE_UPDATE_ON_THIS_DAY',

    REQUEST_DELETE_ON_THIS_DAY: 'REQUEST_DELETE_ON_THIS_DAY',
    SUCCESS_DELETE_ON_THIS_DAY: 'SUCCESS_DELETE_ON_THIS_DAY',
    FAILURE_DELETE_ON_THIS_DAY: 'FAILURE_DELETE_ON_THIS_DAY',

    REQUEST_FETCH_ALL_ON_THIS_DAY: 'REQUEST_FETCH_ALL_ON_THIS_DAY',
    SUCCESS_FETCH_ALL_ON_THIS_DAY: 'SUCCESS_FETCH_ALL_ON_THIS_DAY',
    FAILURE_FETCH_ALL_ON_THIS_DAY: 'FAILURE_FETCH_ALL_ON_THIS_DAY',
}